import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { deconstructEncodedUrl } from '../../utils/helpers';
import Loader from '../../components/loader';
import { CreateJob } from '../myJobs/createJob';
import { EditJob } from '../myJobs/editJob';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

const CreateEditJobPage = ({ userInfo }) => {
  const { id } = useParams();
  const decompressed = deconstructEncodedUrl(id);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const queryClient = useQueryClient();

  return (
    <>
      {loading && (
        <div className="loader-container d-flex align-items-center justify-content-center">
          <Loader />
        </div>
      )}
      <div className="create-job-modal mobileModal">
        <div className="modal-dialog">
          <div className="modal-content">
            {decompressed?.type == 'create' ? (
              <CreateJob
                cloneData={history.location?.state?.data}
                userInfo={userInfo}
                onClose={async (data) => {
                  await queryClient.invalidateQueries('get-jobs');
                  if (history.location?.state?.from && data !== undefined) {
                    history.push(history.location?.state?.from, {
                      newSelectedJob: {
                        id: data?.data?.id,
                        title: data?.data?.title,
                      },
                    });
                  } else {
                    history.go(-1);
                  }
                }}
                setLoading={setLoading}
              />
            ) : (
              <EditJob
                userInfo={userInfo}
                data={history.location?.state?.data}
                onClose={() => {
                  window.sessionStorage.setItem(
                    'refetch_id',
                    history.location?.state?.data?.id
                  );
                  history.go(-1);
                }}
                setLoading={setLoading}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

CreateEditJobPage.propTypes = {
  userInfo: PropTypes.any,
};

export default CreateEditJobPage;
