import React from 'react';
import { useQuery } from 'react-query';
import { getAppliedJobs } from '../pages/dashboard/service';
import { useLists } from '../context/lists-context';

export function useAppliedJobs(userType = null, id) {
  const { appliedJobs, setAppliedJobs, isAppliedJobsInvalidated } = useLists();
  const { data, isLoading, isError, error, refetch, isFetching, isStale } = useQuery(
    ['fetch-applied-jobs', { id }],
    getAppliedJobs,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled:
        (appliedJobs?.length == 0 || isAppliedJobsInvalidated) &&
        (userType === null || userType === 'candidate'),
      useErrorBoundary: (error) => {
        return error?.response?.status === 401;
      },
      retry: (count, error) => {
        if (error?.response?.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  React.useEffect(() => {
    data && setAppliedJobs(data);
  }, [data]);

  return {
    appliedJobs: data,
    isLoading,
    isError,
    error,
    refetch,
    isFetching,
  };
}
