import React from 'react';
import Filter from '../dashboard/filter';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const Filters = ({ userInfo }) => {
  const history = useHistory();
  const [skip, setSkip] = React.useState(history.location.state?.skip || 0);
  return (
    <div className="w-320px show filters-page" style={{ paddingTop: 0 }}>
      <div
        className="row sticky-header"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          className="col-xs-12 col-md-12 col-xl-12 main-col"
          style={{ minHeight: window?.innerHeight }}
        >
          <div className="card sticky-header">
            <Filter
              view={true}
              setSkip={(data) => console.log(data)}
              setShowFilters={(data) =>
                history.push(
                  `/${userInfo?.user_type == 'candidate' ? 'jobs' : 'candidates'}`,
                  { skip: data ? 0 : skip }
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Filters.propTypes = {
  userInfo: PropTypes.any,
};

export default Filters;
