import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PageNotFound = () => {
  return (
    <div className="card card-404 d-flex flex-column justify-content-center align-items-center w-100 py-5">
      <Helmet>
        <title>OOPS! - 404 ERROR</title>
      </Helmet>
      <h1
        className="fw-bolder"
        style={{
          fontSize: '8rem',
          color: '#f18343',
        }}
      >
        OOPS!
      </h1>
      <h3 className="fw-bold">
        We can&apos;t seem to find the page you are looking for.
      </h3>
      <p className="fw-normal">404 ERROR</p>
      <Link className="btn btn-primary rounded-pill text-white px-4 py-2" to="/">
        Home Page
      </Link>
    </div>
  );
};

export default PageNotFound;
