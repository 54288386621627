import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { constructEncodedUrl } from '../../utils/helpers';
import { useMessageThreads } from '../../context/message-threads-context';
import NoResultsFound from '../../components/NoResultsComponent';
import dotMenu from '../../images/Icons/dot-menu.svg';
import ReactMarkdown from 'react-markdown';
import { useDimensions } from '../../hooks/useDimensions';

export const Table = ({ data, onAction, view, recentUpdated }) => {
  const history = useHistory();

  const dimensions = useDimensions();

  const { openThreadModal, setSelectedJobFilter, setShowApplied } =
    useMessageThreads();

  const selectJob = (id, key, blank = false) => {
    const filters = {
      from: Math.floor(key / 5) * 5,
      size: 5,
    };
    if (blank) {
      window.open(
        constructEncodedUrl(
          { id, filters, view, recentUpdated, dontUpdate: true },
          'my-jobs'
        )
      );
    } else {
      history.push(
        constructEncodedUrl(
          { id, filters, view, recentUpdated, dontUpdate: true },
          'my-jobs'
        )
      );
    }
  };

  return (
    <>
      {data?.length == 0 && (
        <NoResultsFound height={dimensions.width < 920 ? '55vh' : '50vh'} />
      )}
      {data?.length > 0 && (
        <table>
          <thead className="card-border-bottom">
            <tr>
              <th>Position/Title</th>
              <th>Job description</th>
              <th style={{ width: '15rem' }}>Date</th>
            </tr>
          </thead>
          <tbody>
            {data
              .sort((a, b) =>
                moment.utc(b.updated_at).diff(moment.utc(a.updated_at))
              )
              .map((row, key) => (
                <tr
                  className={classNames({
                    'card-border-bottom': key !== data?.length - 1,
                    new: moment().diff(moment(row?.created_at), 'hours') < 24,
                  })}
                  key={row?.id}
                  onClick={(e) => {
                    if (!e.defaultPrevented) {
                      selectJob(row?.id, key);
                    }
                  }}
                >
                  <Link
                    to={constructEncodedUrl(
                      { id: row?.id },
                      dimensions.width < 920 ? 'job' : 'my-jobs'
                    )}
                    onClick={(e) => e.preventDefault() + selectJob(row?.id, key)}
                  >
                    <td className="position-relative">
                      {moment().diff(moment(row?.created_at), 'hours') < 24 && (
                        <span className="new-badge">New</span>
                      )}
                      <p className="smart-ellipse mb-0 py-1 title">{row?.title}</p>
                    </td>
                  </Link>
                  <Link
                    to={constructEncodedUrl(
                      { id: row?.id },
                      dimensions.width < 920 ? 'job' : 'my-jobs'
                    )}
                    onClick={(e) => e.preventDefault() + selectJob(row?.id, key)}
                  >
                    <td>
                      <p className="smart-ellipse mb-0 py-1">
                        <ReactMarkdown className="markdown-container">
                          {row?.description}
                        </ReactMarkdown>
                      </p>
                    </td>
                  </Link>
                  <Link
                    href={constructEncodedUrl(
                      { id: row?.id },
                      dimensions.width < 920 ? 'job' : 'my-jobs'
                    )}
                    onClick={(e) => {
                      if (!e.defaultPrevented) {
                        selectJob(row?.id, key);
                      }
                    }}
                  >
                    <td>
                      <div className="actions d-flex align-items-center justify-content-between">
                        {moment(row?.created_at).format('DD MMM,YYYY')}
                        <div
                          className="d-flex align-items-center"
                          onClick={(e) => e.preventDefault()}
                        >
                          <Dropdown alignRight={false}>
                            <Dropdown.Toggle
                              as={'div'}
                              className="row ml-3 mr-2 p-2"
                              id="dropdown-basic"
                            >
                              <img
                                className="cursor-pointer"
                                src={dotMenu}
                                alt="dot-menu"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="py-0">
                              <Dropdown.Item onClick={() => onAction('edit', row)}>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => onAction('clone', row)}>
                                Clone
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    `${window.location.origin}${constructEncodedUrl(
                                      { id: row?.id },
                                      'job'
                                    )}`
                                  )
                                }
                              >
                                Copy link
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => onAction('togglePublish', row)}
                              >
                                {row.status == 'published' ? 'Unpublish' : 'Publish'}
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => onAction('remove', row)}>
                                Delete
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                onClick={() => {
                                  setSelectedJobFilter([
                                    { id: row?.id, title: row?.title },
                                  ]);
                                  openThreadModal();
                                }}
                              >
                                Conversations
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setSelectedJobFilter([
                                    { id: row?.id, title: row?.title },
                                  ]);
                                  setShowApplied(true);
                                  openThreadModal();
                                }}
                              >
                                Applicants
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => onAction('see-candidates', row)}
                              >
                                More candidates
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </td>
                  </Link>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onAction: PropTypes.func,
  recentUpdated: PropTypes.any,
  view: PropTypes.string,
};
