import React from 'react';
import Select from 'react-select';
import slideCloseOrange from '../../images/Icons/slide-close-orange.svg';
import dotMenu from '../../images/Icons/dot-menu.svg';
import { Dropdown } from 'react-bootstrap';
import search from '../../images/Icons/search_lens.svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isCandidate, isRecruiter } from '../../utils/helpers';
import { useMessageThreads } from '../../context/message-threads-context';

const ThreadsForm = ({
  userInfo,
  myJobs,
  showUnread,
  setShowUnread,
  setResetThreads,
  searchFilterValue,
  setSearchFilterValue,
  setShowPlaceHolder,
  setLoading,
  showCandidateTag,
  setShowCandidateTag,
}) => {
  const {
    selectedJobFilter,
    setSelectedJobFilter,
    showApplied,
    setShowApplied,
    archivedOnly,
    setArchivedOnly,
    candidateData,
  } = useMessageThreads();
  const getFilterNumber = () => {
    let filterNumber = 0;
    if (showApplied) filterNumber += 1;
    if (showUnread) filterNumber += 1;
    if (archivedOnly) filterNumber += 1;
    return filterNumber;
  };
  const onShowApplied = () => {
    setShowPlaceHolder(true);
    setLoading(true);
    setTimeout(() => {
      setShowPlaceHolder(false);
      setLoading(false);
      setShowApplied(!showApplied);
    }, 300);
  };
  const onShowUnread = (e) => {
    setShowPlaceHolder(true);
    setLoading(true);
    setTimeout(() => {
      setShowPlaceHolder(false);
      setLoading(false);
      setShowUnread(!showUnread);
    }, 300);
  };

  const onClearAll = () => {
    if (!archivedOnly) {
      setShowPlaceHolder(true);
      setLoading(true);
      setTimeout(() => {
        setShowPlaceHolder(false);
        setLoading(false);
        if (showApplied) setShowApplied(false);
        if (showUnread) setShowUnread(false);
      }, 300);
    } else {
      if (archivedOnly) {
        setArchivedOnly(false);
        setResetThreads(true);
      }
      if (showApplied) setShowApplied(false);
      if (showUnread) setShowUnread(false);
    }
  };

  return (
    <div className="col-xs-12 col-md-12 col-lg-12 px-0">
      <form className="slide-search w-100">
        <div
          className={`col-sm-12 col-md-12 col-xl-12 px-0${
            isCandidate(userInfo.user_type) ? ' mb-0' : ' mb-2'
          }`}
        >
          <div
            className={`col${
              userInfo?.user_type == 'candidate' ? '' : '-12'
            } text-right p-0 d-flex flex-row justify-content-end align-items-start`}
          >
            <div
              className={`position-relative w-100${
                isCandidate(userInfo.user_type) ? ' pr-2' : ''
              }`}
            >
              {showCandidateTag ? (
                <span
                  className="d-flex align-items-center checkbox-select__multi-value position-absolute pl-2"
                  style={{ borderRadius: 2, top: 8, left: 30 }}
                >
                  <span className="d-block text-truncate" style={{ maxWidth: 250 }}>
                    {showCandidateTag?.designation}
                  </span>
                  <svg
                    height="14"
                    width="14"
                    viewBox="0 0 20 20"
                    style={{ fill: '#fff', transform: 'translateY(1px)' }}
                    className="mx-1 cursor-pointer"
                    onClick={() => setShowCandidateTag(false)}
                  >
                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                  </svg>
                </span>
              ) : (
                ''
              )}
              <div className="input-icon">
                <img className="absolute-left" src={search} />
              </div>
              <input
                onKeyPress={(e) => {
                  let charCode = e.which ? e.which : e.keyCode;
                  if (charCode === 13) {
                    e.preventDefault();
                  }
                }}
                style={{ color: '#000000' }}
                className="form-control mb-0 pl-30 border-3 border-grey f16"
                type="text"
                onChange={(e) => {
                  setSearchFilterValue(e.currentTarget.value);
                }}
                value={searchFilterValue}
                placeholder={showCandidateTag ? '' : 'Search Messages'}
              />
              {searchFilterValue.length > 0 && (
                <div
                  onClick={(e) => {
                    setSearchFilterValue('');
                  }}
                  className="dropdown-arrow r-15"
                >
                  <img
                    className="absolute-right cursor-pointer"
                    src={slideCloseOrange}
                  />
                </div>
              )}
            </div>
            {userInfo?.user_type == 'candidate' && (
              <div className="col-1 px-0 position-relative">
                {getFilterNumber() > 0 && (
                  <span className="filter-number">{getFilterNumber()}</span>
                )}
                <Dropdown
                  className="messages-filter-dd"
                  alignRight={false}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Dropdown.Toggle
                    as={'button'}
                    className="p-2 form-control w-100 mx-0 cursor-pointer d-flex align-items-center justify-content-center"
                    id="dropdown-basic"
                    style={{
                      height: 38,
                    }}
                  >
                    <img
                      style={{
                        height: 14,
                        width: 'max-content',
                        transform: 'rotate(90deg)',
                      }}
                      src={dotMenu}
                      alt="dot-menu"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="py-0"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown.Item>
                      <div
                        className="w-100 rec-checkbox"
                        onClick={(e) => e.stopPropagation() + onShowApplied()}
                      >
                        <input
                          className="form-check-input me-1 f16 border-3 cursor-pointer"
                          type="checkbox"
                          checked={showApplied}
                        />
                        <label className="f16 cursor-pointer ml-1 mb-0">
                          Applied
                        </label>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div
                        className="w-100 rec-checkbox"
                        onClick={(e) => {
                          e.stopPropagation();
                          setArchivedOnly(!archivedOnly);
                          setResetThreads(true);
                        }}
                      >
                        <input
                          className="form-check-input me-1 f16 border-3 cursor-pointer"
                          type="checkbox"
                          checked={archivedOnly}
                        />
                        <label className="f16 cursor-pointer ml-1 mb-0">
                          Archived
                        </label>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div
                        className="w-100 rec-checkbox"
                        onClick={(e) => {
                          e.stopPropagation();
                          onShowUnread();
                        }}
                      >
                        <input
                          className="form-check-input me-1 f16 border-3 cursor-pointer"
                          type="checkbox"
                          checked={showUnread}
                        />
                        <label className="f16 cursor-pointer ml-1 mb-0">
                          Unread
                        </label>
                      </div>
                    </Dropdown.Item>

                    <Dropdown.Item
                      disabled={!(showApplied || showUnread || archivedOnly)}
                    >
                      <div
                        className="clear-all"
                        onClick={(e) => e.stopPropagation() + onClearAll()}
                      >
                        <span>Clear all</span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
        {isRecruiter(userInfo?.user_type) && (
          <div className="recruiter-filters d-flex align-items-start mt-2">
            <div className="col-11 px-0 pr-2">
              <Select
                className="group-messages multi"
                options={myJobs?.sort((a, b) =>
                  moment.utc(b.created_at).diff(moment.utc(a.created_at))
                )}
                noOptionsMessage={() => 'You have no Jobs'}
                getOptionValue={(opt) => opt.id}
                getOptionLabel={(opt) => opt.title}
                isMulti
                placeholder="Filter by job"
                value={selectedJobFilter || []}
                onChange={(value) => setSelectedJobFilter(value)}
                classNamePrefix="checkbox-select"
              />
            </div>
            <div className="col-1 px-0 position-relative">
              {getFilterNumber() > 0 && (
                <span className="filter-number">{getFilterNumber()}</span>
              )}
              <Dropdown
                className="messages-filter-dd"
                alignRight={false}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Dropdown.Toggle
                  as={'button'}
                  className="p-2 form-control w-100 mx-0 cursor-pointer d-flex align-items-center justify-content-center"
                  id="dropdown-basic"
                  style={{
                    height: 38,
                  }}
                >
                  <img
                    style={{
                      height: 14,
                      width: 'max-content',
                      transform: 'rotate(90deg)',
                    }}
                    src={dotMenu}
                    alt="dot-menu"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-0" onClick={(e) => e.stopPropagation()}>
                  <Dropdown.Item>
                    <div
                      className="w-100 rec-checkbox"
                      onClick={(e) => e.stopPropagation() + onShowApplied()}
                    >
                      <input
                        className="form-check-input me-1 f16 border-3 cursor-pointer"
                        type="checkbox"
                        checked={showApplied}
                      />
                      <label className="f16 cursor-pointer ml-1 mb-0">Applied</label>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div
                      className="w-100 rec-checkbox"
                      onClick={(e) => {
                        e.stopPropagation();
                        setArchivedOnly(!archivedOnly);
                        setResetThreads(true);
                      }}
                    >
                      <input
                        className="form-check-input me-1 f16 border-3 cursor-pointer"
                        type="checkbox"
                        checked={archivedOnly}
                      />
                      <label className="f16 cursor-pointer ml-1 mb-0">
                        Archived
                      </label>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div
                      className="w-100 rec-checkbox"
                      onClick={(e) => {
                        e.stopPropagation();
                        onShowUnread();
                      }}
                    >
                      <input
                        className="form-check-input me-1 f16 border-3 cursor-pointer"
                        type="checkbox"
                        checked={showUnread}
                      />
                      <label className="f16 cursor-pointer ml-1 mb-0">Unread</label>
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item
                    disabled={!(showUnread || showApplied || archivedOnly)}
                  >
                    <div
                      className="clear-all"
                      onClick={(e) => e.stopPropagation() + onClearAll()}
                    >
                      <span>Clear all</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

ThreadsForm.propTypes = {
  userInfo: PropTypes.any,
  myJobs: PropTypes.any,
  archivedOnly: PropTypes.any,
  setArchivedOnly: PropTypes.any,
  showApplied: PropTypes.any,
  setShowApplied: PropTypes.any,
  showUnread: PropTypes.any,
  setShowUnread: PropTypes.any,
  setResetThreads: PropTypes.any,
  selectedJobFilter: PropTypes.any,
  setSelectedJobFilter: PropTypes.any,
  searchFilterValue: PropTypes.any,
  setSearchFilterValue: PropTypes.any,
  setShowPlaceHolder: PropTypes.any,
  setLoading: PropTypes.any,
  showCandidateTag: PropTypes.any,
  setShowCandidateTag: PropTypes.any,
};

export default ThreadsForm;
