import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getJobs } from '../myJobs/service';
import { Helmet } from 'react-helmet';
import Loader from '../../components/loader';
import calender from '../../images/Icons/calender.svg';
import { getRecruiterProfile } from './service';
import defaultAvatar from '../../images/default-avatar.svg';
import {
  deconstructEncodedUrl,
  constructEncodedUrl,
  isIOS,
} from '../../utils/helpers';
import ReactMarkdown from 'react-markdown';
import './index.scss';
import { useDimensions } from '../../hooks/useDimensions';

const RecruiterDetailPage = ({ userInfo }) => {
  const { id } = useParams();
  const dimensions = useDimensions();
  const decompressed = deconstructEncodedUrl(id);
  const regex = /^https?:\/\//i;

  const { data, isLoading } = useQuery(
    ['get-recruiter-profile', { id: decompressed?.id }],
    getRecruiterProfile,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      useErrorBoundary: (error) => {
        return error.response?.status === 401;
      },
      retry: (count, error) => {
        if (error.response?.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  const {
    data: jobs,
    isLoading: jobsLoading,
    isFetching,
  } = useQuery(['get-jobs', { id: decompressed?.id }], getJobs, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const [url, setUrl] = React.useState('');

  React.useEffect(() => {
    if (!regex.test(data?.company_website)) {
      setUrl('http://' + data?.company_website);
    } else {
      setUrl(data?.company_website);
    }
  }, [data?.company_website]);

  const showDetails = (jobId, key) => {
    if (userInfo.id == decompressed?.id && dimensions.width > 920) {
      const filters = {
        from: Math.floor(key / 5) * 5,
        size: 5,
      };
      return constructEncodedUrl({ id: jobId, filters, view: 'recent' }, 'my-jobs');
    }
    return constructEncodedUrl({ id: jobId }, 'job');
  };

  const pageTitle = `${data?.name || data?.designation} at ${data?.company_name}`;

  return (
    <>
      {!isLoading && data && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      <div className="w-100 cx-padding recruiter-detail">
        {isLoading && !data && (
          <div className="loader d-flex align-items-center justify-content-center">
            <Loader />
          </div>
        )}
        {!isLoading && data && (
          <div className="card">
            <div className="d-flex align-items-center">
              <img
                className="recruiter-avatar mr-3"
                src={data.avatar_url || defaultAvatar}
                alt="avatar"
              />
              <div className="name-designation">
                <h3 className="mb-0">{data.name}</h3>
                <h4 className="mb-0 my-1">{`${data.designation} at ${data.company_name}`}</h4>
                <p className="mb-0">
                  User since {moment(data.created_at).format('MMMM YYYY')}
                </p>
              </div>
            </div>
            <div className="dropdown-divider mt-3"></div>
            <h5 className="bb my-3">About {data.company_name}</h5>
            <p className="text-grey mb-0">{data.about_company}</p>
            <h5 className="bb my-3">Company&apos;s Website</h5>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={url}
              className="text-primary cursor-pointer"
              style={{ width: 'min-content' }}
            >
              {data.company_website}
            </a>
            <div className="dropdown-divider mt-2"></div>
            {jobs && !jobsLoading ? (
              <>
                <h5 className="bb my-3">
                  See {data.name?.trim()?.split(' ')[0]}&apos;s more job openings
                </h5>
                <div className="row w-100 px-3">
                  {jobs
                    ?.filter((j) => j?.status == 'published')
                    .sort((a, b) =>
                      moment.utc(b.updated_at).diff(moment.utc(a.updated_at))
                    )
                    .map((job, key) => (
                      <Link
                        key={job?.id}
                        className="card-link m-0 col-xl-2 col-md-3 col-sm-4 p-0 pr-2"
                        to={showDetails(job?.id, key)}
                        target="_blank"
                      >
                        <div className="card job my-1 active p-0" role="button">
                          <div className="card-body py-2 pl-2 pr-0">
                            <p className="font-weight-bold item-content mb-1">
                              {job?.title}
                            </p>
                            <p
                              className={`mb-0 text-grey item-content${
                                isIOS() ? ' in-ios' : ''
                              }`}
                            >
                              <div className="mt-2">
                                <div className="mb-1 d-flex recruiter-badges">
                                  {job?.skills?.map((s, i) => (
                                    <span
                                      key={i}
                                      className="badge badge-secondary fs-14 ml-0"
                                    >
                                      {s.name}
                                    </span>
                                  ))}
                                </div>
                                <div className="mb-1 d-flex font-weight-bold">
                                  ${job?.min_salary}-{job?.max_salary}
                                </div>
                                <div className="mb-1 d-flex recruiter-badges">
                                  {job?.languages?.map((s, i) => (
                                    <span
                                      key={i}
                                      className="badge badge-secondary fs-14 ml-0"
                                    >
                                      {s.name}
                                    </span>
                                  ))}
                                </div>
                                <div className="mb-1 d-flex recruiter-badges">
                                  {job?.location?.map((s, i) => (
                                    <span
                                      key={i}
                                      className="badge badge-secondary fs-14 ml-0"
                                    >
                                      {s.name}
                                    </span>
                                  ))}
                                </div>
                                <div className="mb-1 d-flex recruiter-badges">
                                  {job?.employment_options?.map((s, i) => (
                                    <span
                                      key={i}
                                      className="badge badge-secondary fs-14 ml-0"
                                    >
                                      {s.name}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </p>
                          </div>
                          <div className="px-2 d-flex align-items-center">
                            <img
                              style={{ padding: '5px' }}
                              className="ch-icons pl-0 mb-1"
                              src={calender}
                            />{' '}
                            <span>
                              {moment.utc(job.updated_at).format('DD MMMM, YYYY')}
                            </span>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>
        )}
      </div>
    </>
  );
};

RecruiterDetailPage.propTypes = {
  userInfo: PropTypes.any,
};

RecruiterDetailPage.defaultProps = {
  userInfo: null,
};

export default RecruiterDetailPage;
