import newHttpClient from '../../utils/newHttpClient';
import axios from 'axios';

export const updateUser = async (data) => {
  return await newHttpClient.put(`/user/${data.id}`, { ...data });
};

export const deleteUser = async ({ message, email, subject }) => {
  let reqBody = {
    subject: subject ? subject : 'Delete Account',
    message,
  };

  if (email) {
    reqBody = { ...reqBody, email };
  }

  return await axios.post(
    'https://qh61tifbca.execute-api.us-east-1.amazonaws.com/forwardEmail',
    reqBody
  );
};
