import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMessageThreads } from '../../context/message-threads-context';
import Card from '../../components/card';
import PropTypes from 'prop-types';
import { constructEncodedUrl } from '../../utils/helpers';
import { useDimensions } from '../../hooks/useDimensions';

const CandidateList = ({
  data,
  onSelect,
  savedCandidates,
  to,
  userInfo,
  saveCandidate,
  contactedCandidates,
  savingCandidateId,
  showRemoveText,
}) => {
  const { openThreadModal, setCandidateId, setCandidateData, contactedUsers } =
    useMessageThreads();
  const history = useHistory();
  const dimensions = useDimensions();

  const onCandidateMessage = (item, id) => {
    if (
      dimensions.width < 920 &&
      contactedUsers.filter((i) => i.candidate_id === item?.id)?.length > 1
    ) {
      history.push('/messages', {
        candidate_id: id,
        designation: item?.designation,
      });
      return;
    }
    setCandidateData(item);
    setCandidateId(id);
    openThreadModal();
    if (dimensions.width < 920) {
      const contacted = contactedUsers
        ?.map((i) => i?.candidate_id)
        ?.includes(item?.id);
      let thread_id = contactedUsers?.find((item) => item?.candidate_id == id)?.id;
      let job_id = contactedUsers?.find((item) => item?.candidate_id == id)?.job_id;
      history.push(
        constructEncodedUrl(
          {
            candidateData: item,
            candidate_id: id,
            ...(contacted
              ? { thread_id, job_id }
              : { from: history.location.pathname, state: history.location?.state }),
          },
          'thread'
        )
      );
    }
  };

  return (
    <div className="tab-content jobs-tab" id="pills-tabContent">
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        {data?.map((c, index) => (
          <Link
            className={`card-link m-0 m-0${
              c.profile_published ? '' : ' greyed-out'
            }${showRemoveText ? ' saved' : ''}`}
            to={() => to(c.id, index, true)}
            key={c.id}
          >
            <Card
              id={c.id}
              title={c.designation}
              className="my-2"
              contactedJobs={contactedCandidates}
              experience={c.work_experience}
              userInfo={userInfo}
              locations={c.locations ? c.locations.map((l) => l.name) : []}
              employmentOptions={
                c.employment_options ? c.employment_options.map((e) => e.name) : []
              }
              skills={c.skills ? c.skills.map((s) => s.name) : []}
              salary={c.salary_expectations}
              body={c.about_professional_experience}
              savingId={savingCandidateId}
              saved={savedCandidates && savedCandidates?.includes(c.id)}
              onMessage={(id) => onCandidateMessage(c, id)}
              onClick={(id) => onSelect(id, index)}
              onSave={saveCandidate}
              showSkills
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

CandidateList.propTypes = {
  data: PropTypes.any,
  onSelect: PropTypes.func,
  savedCandidates: PropTypes.any,
  userInfo: PropTypes.any,
  saveCandidate: PropTypes.any,
  savingCandidateId: PropTypes.any,
  contactedCandidates: PropTypes.any,
  showRemoveText: PropTypes.any,
  to: PropTypes.func,
};

export default CandidateList;
