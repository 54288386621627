import React from 'react';
import { useHistory } from 'react-router-dom';
import Auth from '../utils/auth0';
import { AuthContext } from './auth-context';
import PropTypes from 'prop-types';

export const AuthProvider = ({ children }) => {
  const history = useHistory();

  if (history === undefined) {
    throw new Error('history should be available to AuthProvider');
  }

  const auth = new Auth(history);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.any,
};
