import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const CustomHelmet = ({
  title = 'JobTerix: Anonymous job marketplace for tech professionals',
  description = 'Find a job anonymously',
  pageTitle = 'JobTerix',
  children,
}) => {
  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <title>{pageTitle}</title>
      {children}
    </Helmet>
  );
};

CustomHelmet.propTypes = {
  title: PropTypes.any,
  description: PropTypes.any,
  pageTitle: PropTypes.any,
  children: PropTypes.any,
};
