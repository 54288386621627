import React from 'react';
import PropTypes from 'prop-types';
import linkIcon from '../../images/Icons/link.svg';
import level from '../../images/Icons/level.svg';
import messageIcon from '../../images/Icons/comment.svg';
import messageIconActive from '../../images/Icons/commentActive.svg';
import saveIcon from '../../images/Icons/bookmark.svg';
import savedIcon from '../../images/Icons/bookmark-filled.svg';
import linkSaved from '../../images/Icons/linkSaved.svg';
import locationIcon from '../../images/Icons/location.svg';
import dotMenu from '../../images/Icons/dot-menu.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import homeIcon from '../../images/Icons/home.svg';
import handsIcon from '../../images/Icons/hands.svg';
import { Dropdown } from 'react-bootstrap';
import tagIcon from '../../images/Icons/tag.svg';
import primaryTick from '../../images/Icons/primary-tick.svg';
import { Spinner } from 'react-bootstrap';
import { postTypes } from '../../utils/constants';
import './index.scss';
import { constructEncodedUrl } from '../../utils/helpers';
import { useDimensions } from '../../hooks/useDimensions';

const CardHeader = (props) => {
  const {
    userInfo,
    onClick,
    showActions,
    type,
    id,
    show_tag,
    title,
    onSave,
    savingId,
    saved,
    onTogglePublish,
    experience,
    onMessage,
    companyName,
    status,
    published,
    inJobDetail,
    inDetail,
    showCLipboard,
    onOpenConverSation,
    seeApplicants,
    moreCandidates,
    onEdit,
    onClone,
    onDelete,
    locations,
    employmentOptions,
    salary,
    contactedJobs,
    appliedJobs,
  } = props;
  const dimensions = useDimensions();
  const [showTick, setShowTick] = React.useState(false);

  return (
    <div className="card-header bg-white d-flex flex-column custom">
      {appliedJobs?.includes(id) && show_tag && (
        <span className="applied-tag">Applied</span>
      )}
      <div className="row mx-0">
        <div
          className={`col-${
            dimensions.width < 920 && inDetail && !showActions ? '12' : '8'
          } col-md-${
            showActions ? '8' : '10'
          } p-0 d-flex flex-column justify-content-center`}
        >
          <h1
            onClick={onClick}
            className={`mb-0 card-header-title font-weight-bold${
              onClick ? ' cursor-pointer' : ''
            }${dimensions.width < 920 && !inDetail ? ' text-truncate' : ''}`}
          >
            {title}
          </h1>
        </div>
        <div
          className={`col-4${
            dimensions.width < 920 && inDetail ? ' d-none' : ''
          } col-md-${
            showActions ? '4 rec-job' : '2'
          } d-flex p-0 align-items-center justify-content-end`}
        >
          {/* Revisit this, we need to have better link copies */}
          {showActions && (
            <>
              {published && (
                <span
                  className="px-2"
                  style={{
                    color: '#fff',
                    borderRadius: 50,
                    backgroundColor: '#01ba3f',
                    marginRight: 25,
                  }}
                >
                  Active
                </span>
              )}
              {!published && (
                <span
                  className="px-3"
                  style={{
                    color: '#9b9b9b',
                    fontWeight: 500,
                    borderRadius: 50,
                    backgroundColor: '#e1e1e1',
                    marginRight: 25,
                  }}
                >
                  Draft
                </span>
              )}
              <Dropdown alignRight={false} className="actions">
                <Dropdown.Toggle
                  as={'div'}
                  className="row ml-1 p-2 cursor-pointer"
                  style={{ transform: 'translate(-15px,-1px) rotate(90deg)' }}
                  id="dropdown-basic"
                >
                  <img
                    style={{ height: 14, width: 'max-content' }}
                    src={dotMenu}
                    alt="dot-menu"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-0">
                  <Dropdown.Item onClick={onEdit}>Edit</Dropdown.Item>
                  <Dropdown.Item onClick={onClone}>Clone</Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `${window.location.origin}${constructEncodedUrl(
                          { id },
                          'job'
                        )}`
                      )
                    }
                  >
                    Copy link
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onTogglePublish}>
                    {published ? 'Unpublish' : 'Publish'}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onDelete}>Delete</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={onOpenConverSation}>
                    Conversations
                  </Dropdown.Item>
                  <Dropdown.Item onClick={seeApplicants}>Applicants</Dropdown.Item>
                  <Dropdown.Item onClick={moreCandidates}>
                    More candidates
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
          {showCLipboard && (
            <CopyToClipboard
              text={`${window.location.origin}${constructEncodedUrl(
                { id: id },
                inJobDetail
                  ? 'job'
                  : userInfo.user_type == 'candidate'
                  ? 'job'
                  : 'candidate'
              )}`}
              onCopy={() => {
                if (showTick) {
                  return;
                }
                setShowTick(true);
                setTimeout(() => setShowTick(false), 5000);
              }}
            >
              <button
                className="btn p-0"
                onClick={(e) => e.preventDefault() + e.stopPropagation()}
              >
                <img
                  className="ch-icons"
                  src={
                    showTick
                      ? dimensions.width < 920
                        ? linkSaved
                        : primaryTick
                      : linkIcon
                  }
                />
              </button>
            </CopyToClipboard>
          )}
          {onMessage && (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onMessage(id);
              }}
              className="btn p-0"
            >
              <img
                className="ch-icons"
                src={
                  contactedJobs?.includes(id) && status
                    ? messageIconActive
                    : messageIcon
                }
              />
            </button>
          )}
          {onSave && (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSave({ id, saved });
              }}
              className={`btn p-0`}
            >
              {savingId !== id ? (
                <img
                  className="ch-icons"
                  data-type="save-icon"
                  src={saved ? savedIcon : saveIcon}
                />
              ) : (
                <div style={{ paddingRight: '8px', paddingLeft: '8px' }}>
                  <Spinner size="sm" animation="border" role="status" />
                </div>
              )}
            </button>
          )}
        </div>
      </div>
      <div className="particulars-mobile">
        {`${companyName ? `${companyName}` : ''}${
          locations.length > 0 ? `${companyName ? ',' : ''} ${locations[0]}` : ''
        }${employmentOptions.length > 0 ? `, ${employmentOptions[0]}` : ''}${
          experience !== 0 && experience > 1
            ? `, ${experience} years of experience`
            : `, ${experience} year of experience`
        }`}
        {salary ? (
          <>
            , <span>${salary}</span>
          </>
        ) : (
          ''
        )}
      </div>
      <div className="d-flex particulars mt-2">
        {companyName && (
          <div className="d-flex align-items-center">
            <img className="ch-icons" src={homeIcon} />
            <p className="mb-0">{companyName}</p>
          </div>
        )}
        {locations.length > 0 && (
          <div className="d-flex align-items-center">
            <img className="ch-icons" src={locationIcon} />
            <p className="mb-0">{locations.join(', ')}</p>
          </div>
        )}
        <div className="d-flex align-items-center">
          <img className="ch-icons" src={level} />
          <p className="mb-0">
            {experience !== 0 && experience > 1
              ? `${experience} years of experience`
              : `${experience} year of experience`}
          </p>
        </div>
        {employmentOptions.length > 0 && (
          <div className="d-flex align-items-center">
            <img className="ch-icons" src={handsIcon} />
            <p className="mb-0">{employmentOptions.join(', ')}</p>
          </div>
        )}
        {salary && (
          <div className="d-flex justify-content-center">
            <img className="ch-icons" src={tagIcon} />
            <p className="mb-0 text-primary">${salary}</p>
          </div>
        )}
      </div>
    </div>
  );
};

CardHeader.propTypes = {
  type: PropTypes.string,
  userInfo: PropTypes.any,
  showActions: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  onSave: PropTypes.any,
  savingId: PropTypes.string,
  saved: PropTypes.bool,
  published: PropTypes.any,
  showCLipboard: PropTypes.bool,
  status: PropTypes.bool,
  show_tag: PropTypes.bool,
  onMessage: PropTypes.func,
  onClick: PropTypes.func,
  onOpenConverSation: PropTypes.func,
  onTogglePublish: PropTypes.func,
  moreCandidates: PropTypes.func,
  seeApplicants: PropTypes.func,
  onEdit: PropTypes.func,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  companyName: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.string),
  inJobDetail: PropTypes.bool,
  inDetail: PropTypes.bool,
  employmentOptions: PropTypes.arrayOf(PropTypes.string),
  savedJobs: PropTypes.arrayOf(PropTypes.string),
  appliedJobs: PropTypes.arrayOf(PropTypes.any),
  contactedJobs: PropTypes.arrayOf(PropTypes.string),
  salary: PropTypes.any,
  experience: PropTypes.number,
};

CardHeader.defaultProps = {
  type: postTypes.job,
  published: true,
  showActions: false,
  inJobDetail: false,
  inDetail: false,
  userInfo: null,
  id: null,
  title: null,
  onSave: null,
  savingId: null,
  saved: false,
  onMessage: null,
  showCLipboard: true,
  show_tag: false,
  onClick: null,
  moreCandidates: null,
  seeApplicants: null,
  onOpenConverSation: null,
  onEdit: null,
  onClone: null,
  status: true,
  onDelete: null,
  companyName: null,
  locations: [],
  employmentOptions: [],
  savedJobs: [],
  appliedJobs: [],
  contactedJobs: [],
  salary: null,
  experience: null,
};

export default CardHeader;
