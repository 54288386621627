import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import logo from '../../images/logo.png';
import './sign-up.scss';
import { Button } from 'react-bootstrap';

export const Terms = ({ continueFn }) => {
  const [termOnechecked, toggleTermOne] = useState(false);
  const [termTwochecked, toggleTermTwo] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    continueFn();
  };
  return (
    <div className="d-flex vh-100">
      <div className="w-50 jtx-signup-img terms p-3">
        <img src={logo} alt="JobTerix" />
      </div>
      <div className="d-flex flex-column align-items-xl-start justify-content-center w-100 p-5">
        <div className="d-flex flex-column ml-4">
          <h1>Review the Terms to sign up</h1>
          <p style={{ color: '#333333' }}>
            Please review and accept our Terms to continue
          </p>

          <Form className="w-100 mt-3" onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Check
                custom
                type="checkbox"
                id="consent-checkbox_1"
                checked={termOnechecked}
                onChange={() => {
                  toggleTermOne(!termOnechecked);
                }}
                label={
                  <>
                    I agree to the JobTerix&apos;s{' '}
                    <a
                      href="https://jobterix.com/legal/#privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-primary"
                    >
                      Privacy Statement
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://jobterix.com/legal/#terms"
                      className="link-primary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service.
                    </a>
                  </>
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                custom
                type="checkbox"
                id="consent-checkbox_2"
                checked={termTwochecked}
                onChange={() => {
                  toggleTermTwo(!termTwochecked);
                }}
                label={
                  <>I agree to receive news and product updates from JobTerix.</>
                }
              />
            </Form.Group>
            <div
              style={{
                marginBottom: '300px',
                justifyContent: 'flex-end',
                display: 'flex',
              }}
            >
              <Button
                type="submit"
                style={{ width: '30%', marginTop: '1rem', borderRadius: 20 }}
                disabled={!termOnechecked}
              >
                Continue
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

Terms.propTypes = {
  continueFn: PropTypes.any,
};
