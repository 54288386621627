import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FiInfo } from 'react-icons/fi';
import { useDimensions } from '../../../hooks/useDimensions';
function Summary(props) {
  const { data } = props;
  const dimensions = useDimensions();
  const {
    title,
    experience,
    skills,
    salaryExpectation,
    languages,
    geos,
    professionalExperience,
    employment,
  } = data;

  return (
    <>
      <div className="summary-data">
        <div className="row pb-2">
          <div className={dimensions.width < 576 ? 'col-10' : 'col-6'}>
            <h6 className="font-weight-bold mb-0">Your skills and experience</h6>
          </div>
        </div>
        <div className="row">
          <div className={dimensions.width < 576 ? 'col-6' : 'col-5'}>
            <p className="card-subtitle">Current Position/Title</p>
          </div>

          <div className="col">
            <p className="card-subtitle">{title}</p>
          </div>
        </div>
        <div className="row">
          <div className={dimensions.width < 576 ? 'col-6' : 'col-5'}>
            <p className="card-subtitle">Work experience (years)</p>
          </div>

          <div className="col">
            <p className="card-subtitle">{experience}</p>
          </div>
        </div>
        <div className="row">
          <div className={dimensions.width < 576 ? 'col-12' : 'col-5'}>
            <p className="card-subtitle">Skills</p>
          </div>

          <div className="col">
            <div className="b-0">
              {skills.map(({ id, name }) => (
                <span key={id} className="badge badge-medium">
                  {name}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className={dimensions.width < 576 ? 'col-6' : 'col-5'}>
            <p className="card-subtitle">
              Salary expectations{' '}
              <FiInfo
                className="mb-1 salary-tooltip"
                data-tip
                data-for="password-info"
              />
              <ReactTooltip
                id="password-info"
                type="light"
                effect="solid"
                place="top"
                className="tooltip-shadow"
              >
                <p className="p-1 pb-0 mb-0">Monthly gross salary in USD</p>
              </ReactTooltip>
            </p>
          </div>

          <div className="col">
            <p className="card-subtitle">${salaryExpectation}</p>
          </div>
        </div>
        <div className="row">
          <div className={dimensions.width < 576 ? 'col-12' : 'col-5'}>
            <p className="card-subtitle">Languages</p>
          </div>

          <div className="col">
            <div className="b-0">
              {languages.map(({ id, name, proficiency }) => (
                <span key={id} className="badge badge-medium">
                  {name + ' - ' + proficiency}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className={dimensions.width < 576 ? 'col-12' : 'col-5'}>
            <p className="card-subtitle">Employment options</p>
          </div>

          <div className="col">
            {employment.map(({ id, name }) => (
              <span key={id} className="badge badge-medium">
                {name}
              </span>
            ))}
          </div>
        </div>
        <div className="row">
          <div className={dimensions.width < 576 ? 'col-12' : 'col-5'}>
            <p className="card-subtitle">Locations</p>
          </div>

          <div className="col">
            <div className="b-0">
              {geos.map(({ id, name }) => (
                <span key={id} className="badge badge-medium">
                  {name}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="row pb-0 mt-1">
          <div className="col-6">
            <h6 className="font-weight-bold mb-0">Personal details</h6>
          </div>
        </div>
        <div className="row">
          <div className={dimensions.width < 576 ? 'col-6' : 'col-5'}>
            <p className="card-subtitle">Your professional experience</p>
          </div>
          <div className={dimensions.width < 576 ? 'col-6' : 'col-5'}>
            <div className="summary-body preserve-line">
              {professionalExperience}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Summary.propTypes = {
  data: PropTypes.object,
  redirectToStep: PropTypes.func,
};

export default Summary;
