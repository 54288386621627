import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../images/jobTerix-logo-white.svg';
import logoColored from '../../images/JobTerix-full.svg';
import board from '../../images/board.svg';
import './styles.scss';
import Stepper from './stepper';
/* eslint-disable */

// eslint-disable-next-line no-sparse-arrays

export const candidate = Object.freeze({
  USERTYPE: 0,
  ABOUT: 1,
  SUMMARY: 2,
});

const Page =
  (BaseComponent, steps = [], initialState) =>
  (props) => {
    const [currentStep, setStep] = React.useState(initialState ? initialState : 0);
    return (
      <div className="w-100 d-flex justify-content-center justify-content-xl-start overflowx-hidden onboarding">
        <div className="w-485px bg-primary vh-100">
          <div className="sw-container swt-padding swl-padding">
            <div className="row">
              <div className="col-xs-12 col-md-12 col-xl-12">
                <div className="logo-white">
                  <img src={logo} />
                </div>
                <Stepper steps={steps} currentStep={currentStep} />
              </div>
            </div>
          </div>
          <img className="sw-board" src={board} />
        </div>
        <div className="d-flex align-items-center justify-content-center d-md-none responsive-logo p-4">
          <img src={logoColored} alt="JobTerix" />
        </div>
        <BaseComponent setStep={setStep} currentStep={currentStep} {...props} />
      </div>
    );
  };

Page.propTypes = {
  children: PropTypes.any,
};

export default Page;
