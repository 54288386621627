/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  useHistory,
  useLocation,
} from 'react-router-dom';
import UserSelection from './pages/onboarding/userSelection/userSelection';
import RecruiterOnboardingForm from './pages/onboarding/form/onboarding-form-recruiter';
import CandidateOnboardingForm from './pages/onboarding/form/onboarding-form-candidate';
import Dashboard from './pages/dashboard';
import './App.scss';
import SignIn from './pages/sign-in';
import SignUp from './pages/sign-up';
import PropTypes from 'prop-types';
import Callback from './components/callback';
import Verify from './components/verify';
import ForgotPassword from './components/forgot-password';
import Home from './components/home';
import LoggedOutJobDetail from './components/LoggedOutJobDetail';
import { useDimensions } from './hooks/useDimensions';
import { PrivateRoute } from './components/common/private-route';
import { ErrorBoundary } from './hoc/errorboundary';
import { useAuth } from './context/auth-context';

function Router() {
  const auth = useAuth();
  const { width } = useDimensions();
  const location = useLocation();

  useEffect(() => {
    if (width < 576) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [location.pathname]);

  return (
    <Switch>
      <Route exact path="/callback" component={Callback} />
      <PrivateRoute exact path="/hometest" component={Home} />
      <PrivateRoute exact path="/verify" component={Verify} />
      {!auth.isAuthenticated() && (
        <Route path="/job/:id" component={LoggedOutJobDetail} />
      )}
      <Route path="/login" component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <ErrorBoundary>
        <PrivateRoute
          path="/onboarding/candidate"
          component={CandidateOnboardingForm}
        />
        <PrivateRoute
          path="/onboarding/recruiter"
          component={RecruiterOnboardingForm}
        />
        <PrivateRoute exact path="/onboarding" component={UserSelection} />
        <PrivateRoute path="/" component={Dashboard} />
      </ErrorBoundary>
    </Switch>
  );
}

Router.propTypes = {
  history: PropTypes.any,
};

export default withRouter(Router);
