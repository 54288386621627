import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import arrowLeft from '../../images/Icons/arrow-detail.svg';
import './index.scss';

const MessagesFilter = ({
  setShowFilters,
  myJobs,
  showApplied,
  setShowApplied,
  includeArchived,
  setIncludeArchived,
  selectedJobFilter,
  candidateView,
  setSelectedJobFilter,
  setResetThreads,
  showUnread,
  setShowUnread,
}) => {
  const [params, setParams] = React.useState({
    showApplied,
    selectedJobFilter,
    includeArchived,
    showUnread,
  });
  const [oldFilters, setOldFilters] = React.useState(null);
  const [minHeight, setMinHeight] = React.useState(window?.innerHeight);

  React.useEffect(() => {
    setOldFilters(params);
    if (document) {
      let element = document.querySelector('.main-container.no-header');
      element.style.minHeight = 'unset';
    }
    return () => {
      let element = document.querySelector('.main-container.no-header');
      element.style.minHeight = '';
    };
  }, []);

  React.useEffect(() => {
    setMinHeight(window?.innerHeight);
  }, [window?.innerHeight]);

  return (
    <div className="messages-filter" style={{ height: minHeight }}>
      <div>
        <div className="messages-filter__title">
          <div>
            <img
              src={arrowLeft}
              onClick={(e) => {
                setShowFilters(false);
              }}
              alt="arrow"
            />
            <span>Filter by</span>
          </div>
          <span
            className={
              params.selectedJobFilter?.length > 0 ||
              params.showApplied ||
              params.showUnread ||
              params.includeArchived
                ? 'active'
                : ''
            }
            onClick={(e) =>
              setParams({
                showUnread: false,
                showApplied: false,
                selectedJobFilter: [],
                includeArchived: false,
              })
            }
          >
            Clear all
          </span>
        </div>
        <div className="messages-filter__inputs">
          {!candidateView && (
            <div className="messages-filter__input">
              <Select
                className="group-messages multi my-2"
                options={myJobs?.sort((a, b) =>
                  moment.utc(b.created_at).diff(moment.utc(a.created_at))
                )}
                noOptionsMessage={() => 'You have no Jobs'}
                getOptionValue={(opt) => opt.id}
                getOptionLabel={(opt) => opt.title}
                isMulti
                placeholder="Filter by job"
                value={params.selectedJobFilter || []}
                onChange={(value) =>
                  setParams({ ...params, selectedJobFilter: value })
                }
                classNamePrefix="checkbox-select"
              />
            </div>
          )}
          <div className="rec-checkbox">
            <input
              className="form-check-input me-1 f16 border-3 cursor-pointer"
              type="checkbox"
              checked={params.showApplied}
              onChange={() =>
                setParams({ ...params, showApplied: !params.showApplied })
              }
            />
            <label
              className="f16 cursor-pointer ml-1"
              onClick={() =>
                setParams({ ...params, showApplied: !params.showApplied })
              }
            >
              Applied
            </label>
          </div>
          <div className="rec-checkbox">
            <input
              className="form-check-input me-1 f16 border-3 cursor-pointer"
              type="checkbox"
              checked={params.includeArchived}
              onChange={() =>
                setParams({ ...params, includeArchived: !params.includeArchived })
              }
            />
            <label
              className="f16 cursor-pointer ml-1"
              onClick={() =>
                setParams({ ...params, includeArchived: !params.includeArchived })
              }
            >
              Archived
            </label>
          </div>
          <div className="rec-checkbox">
            <input
              className="form-check-input me-1 f16 border-3 cursor-pointer"
              type="checkbox"
              checked={params.showUnread}
              onChange={() =>
                setParams({ ...params, showUnread: !params.showUnread })
              }
            />
            <label
              className="f16 cursor-pointer ml-1"
              onClick={() =>
                setParams({ ...params, showUnread: !params.showUnread })
              }
            >
              Unread
            </label>
          </div>
        </div>
        <div className="filter-buttons bg-white d-flex justify-content-end border-top-0">
          <button
            onClick={(e) => {
              setParams(oldFilters);
              setShowFilters(false);
            }}
            className="btn btn-outline btn-primary btn-rounded mr-2"
          >
            Cancel
          </button>
          <button
            disabled={JSON?.stringify(oldFilters) === JSON?.stringify(params)}
            className="btn btn-primary btn-rounded"
            onClick={() => {
              setIncludeArchived(params.includeArchived);
              setShowApplied(params.showApplied);
              setShowUnread(params.showUnread);
              setSelectedJobFilter(params.selectedJobFilter);
              setShowFilters(false);
              params.includeArchived !== oldFilters.includeArchived &&
                setResetThreads(true);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

MessagesFilter.propTypes = {
  setShowFilters: PropTypes.any,
  myJobs: PropTypes.any,
  setResetThreads: PropTypes.any,
  showApplied: PropTypes.any,
  setShowApplied: PropTypes.any,
  selectedJobFilter: PropTypes.any,
  showUnread: PropTypes.any,
  setShowUnread: PropTypes.any,
  includeArchived: PropTypes.any,
  setIncludeArchived: PropTypes.any,
  setSelectedJobFilter: PropTypes.any,
  candidateView: PropTypes.any,
};

export default MessagesFilter;
