import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import calender from '../../images/Icons/calender.svg';
import dotMenu from '../../images/Icons/dot-menu.svg';
import { constructEncodedUrl } from '../../utils/helpers';
import './styles.scss';

const Thread = ({
  data,
  onClick,
  jobId,
  onArchive,
  onUnarchive,
  onMark,
  candidateId,
  archived,
  unread,
  applied,
  onReport,
  title,
  lastMessage,
  jobTitle,
  status,
  showDetails,
  candidateView,
  updatedAt,
}) => {
  const getTimeString = (date) => {
    return moment.utc(date).local().format('DD MMM, YYYY');
  };

  const titleWidth = () => {
    if (applied && archived) {
      return 108;
    } else if (applied) {
      return 52;
    } else if (archived) {
      return 56;
    }
  };

  return (
    <div
      onClick={(e) => {
        if (e?.target?.alt == 'dot-menu' || e?.target?.id == 'dropdown-basic') {
          return;
        } else {
          onClick(e);
        }
      }}
      className={`col-xs-12 col-md-12 col-lg-12 p-0 my-2 ${
        candidateView && (status == 'unpublished' || status == 'deleted')
          ? ' greyed-out'
          : ''
      }`}
    >
      <div className="job-card">
        <div className="card">
          <div
            className={classNames('card-body job-detail big', {
              active: unread,
              'greyed-out':
                candidateView && (status == 'unpublished' || status == 'deleted'),
            })}
          >
            <div className="row">
              <div className="col-9 d-flex alig-items-center">
                <h5
                  className={`card-title pb-1 mb-0 thread-name m-card-title`}
                  {...(applied || archived
                    ? { style: { maxWidth: `calc(100% - ${titleWidth()}px)` } }
                    : {})}
                >
                  {title}
                  {candidateView &&
                    (status == 'unpublished' || status == 'deleted') && (
                      <i style={{ fontWeight: 400 }}>
                        (this job is no longer available)
                      </i>
                    )}
                </h5>
                {applied && (
                  <span
                    className="applied-indicator"
                    onClick={(e) => e.preventDefault() + e.stopPropagation()}
                  >
                    Applied
                  </span>
                )}
                {archived && (
                  <span
                    className="applied-indicator"
                    onClick={(e) => e.preventDefault() + e.stopPropagation()}
                    style={{ backgroundColor: '#91919f' }}
                  >
                    Archived
                  </span>
                )}
              </div>
              <div
                className={`d-flex align-items-center align-self-start col-3`}
                style={{ transform: 'translateY(-3px)' }}
              >
                {updatedAt && (
                  <div className={`d-flex flex-row ml-auto`}>
                    <a className="m-detail d-flex">
                      <img
                        style={{ padding: '5px' }}
                        className="ch-icons pl-0"
                        src={calender}
                      />
                      <span style={{ fontWeight: 500, minWidth: 73 }}>
                        {getTimeString(updatedAt)}
                      </span>
                    </a>
                  </div>
                )}
              </div>
            </div>
            {!candidateView && (
              <div
                className="d-flex align-items-center"
                style={{ fontSize: '13px' }}
              >
                <span className="text-grey">Position:</span>
                <span
                  className="text-truncate cursor-pointer d-block ml-2"
                  style={{
                    color: 'black',
                    maxWidth: '75%',
                  }}
                >
                  {jobTitle}
                </span>
              </div>
            )}
            <p className="my-1 text-grey m-content preserve-line">
              <span>{lastMessage.sender_name}</span>
              <span>
                {lastMessage.content
                  ?.replaceAll('&#39;', "'")
                  ?.replaceAll('&quot;', '"')}
              </span>
            </p>
            <Dropdown
              alignRight={false}
              className="messages-dd"
              onClick={(e) => {
                e.target.id == 'fromDD';
              }}
            >
              <Dropdown.Toggle
                as={'div'}
                className="row ml-1 p-2 cursor-pointer"
                style={{ transform: 'translate(-15px,-1px) rotate(90deg)' }}
                id="dropdown-basic"
              >
                <img
                  style={{ height: 14, width: 'max-content' }}
                  src={dotMenu}
                  alt="dot-menu"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-0" onClick={(e) => e.stopPropagation()}>
                {!archived && (
                  <Dropdown.Item onClick={onArchive}>Archive</Dropdown.Item>
                )}
                {archived && (
                  <Dropdown.Item onClick={onUnarchive}>Unarchive</Dropdown.Item>
                )}
                {/* {(status == 'published' || !candidateView) && ( */}
                <>
                  <Dropdown.Item onClick={onMark}>
                    {unread ? 'Mark as read' : 'Mark as unread'}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onReport}>Report</Dropdown.Item>
                </>
                {/* )} */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

Thread.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  showDetails: PropTypes.func,
  onArchive: PropTypes.func,
  onUnarchive: PropTypes.func,
  onMark: PropTypes.func,
  onReport: PropTypes.func,
  jobId: PropTypes.string,
  candidateId: PropTypes.string,
  archived: PropTypes.any,
  unread: PropTypes.bool,
  applied: PropTypes.bool,
  candidateView: PropTypes.bool,
  title: PropTypes.string,
  jobTitle: PropTypes.string,
  lastMessage: PropTypes.any,
  status: PropTypes.string,
  companyName: PropTypes.string,
  locations: PropTypes.any,
  experience: PropTypes.any,
  employmentOptions: PropTypes.any,
  salary: PropTypes.number,
  updatedAt: PropTypes.string,
};

export default Thread;
