const initialState = {
  show: false,
  message: '',
  type: '',
};

export default function notification(state = initialState, action) {
  switch (action.type) {
    case 'SET_NOTIFICATION':
      return {
        ...state,
        show: true,
        message: action.payload.message,
        type: action.payload.type,
      };
    case 'CLEAR_NOTIFICATION':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
