import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import CardHeader from '../card-header';
import { useHistory, Link } from 'react-router-dom';
import { useMessageThreads } from '../../context/message-threads-context';
import { Modal } from 'react-bootstrap';
import { useApplyJob } from '../../context/apply-job-context';
import { constructEncodedUrl } from '../../utils/helpers';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiInfo } from 'react-icons/fi';
import { Spinner } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import './index.scss';
import arrowLeft from '../../images/Icons/arrow-detail.svg';
import { useDimensions } from '../../hooks/useDimensions';
import messageIcon from '../../images/Icons/comment.svg';
import messageIconActive from '../../images/Icons/commentActive.svg';
import linkIcon from '../../images/Icons/link.svg';
import linkSaved from '../../images/Icons/linkSaved.svg';
import saveIcon from '../../images/Icons/bookmark.svg';
import savedIcon from '../../images/Icons/bookmark-filled.svg';

const JobDetailCard = ({
  job,
  mutate,
  onAction,
  savingJobId,
  onGoBack,
  loggedOut,
  savedJobs,
  applied,
  appliedJobs,
  showClipBoard,
  showActions,
  userInfo,
}) => {
  const { toggleApplyJob } = useApplyJob();
  const {
    openThreadModal,
    setSelectedJobFilter,
    setShowApplied,
    setJobData,
    contactedUsers,
  } = useMessageThreads();
  const [showTick, setShowTick] = React.useState(false);
  const dimensions = useDimensions();
  const contacted = contactedUsers?.map((i) => i?.job_id)?.includes(job?.id);
  const [url, setUrl] = React.useState(job?.company_website);
  const history = useHistory();

  const onMoreCandidates = () => {
    window.open(
      constructEncodedUrl(
        {
          filters: {
            skills: job?.skills,
            employmentOptions: job?.employment_options,
            languages: job?.languages,
            experience: job?.experience,
            salary: job?.max_salary,
            geos: job?.locations,
          },
        },
        'candidates',
        'new'
      )
    );
  };

  const getPostingDay = () => {
    if (moment().diff(moment(job?.created_at), 'days') < 3) {
      return `Posted ${moment(job?.created_at).fromNow()}`;
    } else {
      return `Posted on ${moment(job?.created_at).format('DD MMMM, YYYY')}`;
    }
  };

  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [allOpenAccordion, setOpenAccordion] = React.useState([]);

  const toggleAccordian = (e) => {
    const { id } = e.currentTarget;
    allOpenAccordion.includes(id)
      ? setOpenAccordion(allOpenAccordion.filter((i) => i !== id))
      : setOpenAccordion([...allOpenAccordion, id]);
  };

  React.useEffect(() => {
    if (!url?.match(/^https?:\/\//i)) {
      setUrl('http://' + url);
    }
    dimensions.width > 920 && window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={`row job-detail-card ${dimensions.width > 920 ? 'mb-2' : 'mobile'}`}
    >
      {userInfo?.user_type === 'recruiter' && (
        <Modal
          centered
          show={deleteModalShow}
          animation="false"
          onHide={() => setDeleteModalShow(false)}
        >
          <Modal.Body>
            <div className="text-left">
              <h3 className="mb-4 fw-700 text-truncate">{job?.title}</h3>
              <p className="mb-4">Are you sure you want to delete this job?</p>
              {job?.status == 'published' && (
                <p className="text-grey mb-4">
                  Note: You can always{' '}
                  <a
                    style={{ color: '#454fb4', textDecoration: 'underline' }}
                    role="button"
                    onClick={() => {
                      onAction('togglePublish', job?.id, job?.status);
                      setDeleteModalShow(false);
                    }}
                  >
                    Unpublish
                  </a>{' '}
                  it which will hide it from candidates
                </p>
              )}
              <div className="d-flex align-items-center justify-content-between">
                <button
                  onClick={() => setDeleteModalShow(false)}
                  className="btn btn-outline btn-primary btn-rounded px-4"
                >
                  Cancel
                </button>
                <button
                  onClick={() =>
                    onAction('delete', job?.id) + setDeleteModalShow(false)
                  }
                  className="btn btn-primary btn-rounded text-white px-4"
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <div className="col-xs-12 col-md-12 col-xl-12 jobs-list">
        <div
          className="card card-container"
          {...(dimensions.width < 920
            ? {
                className: 'card card-container d-flex flex-column',
                style: { minHeight: '100vh' },
              }
            : {})}
        >
          {dimensions.width < 920 && !loggedOut && (
            <div className="job-detail-card-mobile-nav d-flex align-items-center justify-content-between">
              <img
                src={arrowLeft}
                onClick={(e) => {
                  onGoBack && onGoBack();
                }}
                alt="arrow"
              />
              {job?.author_id !== userInfo.id && (
                <div className="d-flex align-items-center">
                  {showClipBoard && (
                    <CopyToClipboard
                      text={`${window.location.origin}${constructEncodedUrl(
                        { id: job?.id },
                        'job'
                      )}`}
                      onCopy={() => {
                        if (showTick) {
                          return;
                        }
                        setShowTick(true);
                        setTimeout(() => setShowTick(false), 5000);
                      }}
                    >
                      <button
                        className="btn p-0"
                        onClick={(e) => e.preventDefault() + e.stopPropagation()}
                      >
                        <img
                          className="ch-icons"
                          src={showTick ? linkSaved : linkIcon}
                        />
                      </button>
                    </CopyToClipboard>
                  )}
                  {!loggedOut && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (userInfo?.user_type == 'candidate' && !loggedOut) {
                          setJobData(job);
                          if (dimensions.width < 920) {
                            let thread_id = contactedUsers?.find(
                              (item) => item?.job_id == job?.id
                            )?.id;
                            history.push(
                              constructEncodedUrl(
                                {
                                  jobData: job,
                                  job_id: job.id,
                                  ...(contacted ? { thread_id } : {}),
                                },
                                'thread'
                              )
                            );
                          }
                        }
                      }}
                      className="btn p-0"
                    >
                      <img
                        className="ch-icons"
                        src={
                          contactedUsers?.map((i) => i?.job_id)?.includes(job?.id) &&
                          job?.status
                            ? messageIconActive
                            : messageIcon
                        }
                      />
                    </button>
                  )}
                  {!loggedOut && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        mutate({ id: job?.id, saved: savedJobs?.includes(job?.id) });
                      }}
                      className={`btn p-0`}
                    >
                      {savingJobId !== job?.id ? (
                        <img
                          className="ch-icons"
                          src={
                            savedJobs?.includes(job?.id) && job?.status
                              ? savedIcon
                              : saveIcon
                          }
                        />
                      ) : (
                        <div style={{ paddingRight: '8px', paddingLeft: '8px' }}>
                          <Spinner size="sm" animation="border" role="status" />
                        </div>
                      )}
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
          <CardHeader
            id={job?.id}
            userInfo={userInfo}
            contactedJobs={contactedUsers?.map((i) => i?.job_id)}
            inDetail={true}
            inJobDetail={true}
            title={job?.title}
            onSave={mutate}
            appliedJobs={appliedJobs}
            showActions={showActions}
            onOpenConverSation={() => {
              setSelectedJobFilter([{ id: job?.id, title: job?.title }]);
              setShowApplied(false);
              dimensions.width > 920 ? openThreadModal() : history.push('/messages');
            }}
            moreCandidates={onMoreCandidates}
            seeApplicants={() => {
              setSelectedJobFilter([{ id: job?.id, title: job?.title }]);
              setShowApplied(true);

              dimensions.width > 920 ? openThreadModal() : history.push('/messages');
            }}
            showCLipboard={showClipBoard}
            saved={savedJobs?.includes(job?.id)}
            onDelete={() => setDeleteModalShow(true)}
            onEdit={() => onAction('edit', job?.id)}
            onClone={() => onAction('clone', job?.id)}
            savingId={savingJobId}
            onTogglePublish={() => onAction('togglePublish', job?.id, job?.status)}
            published={job?.status == 'published'}
            onMessage={
              userInfo?.user_type == 'candidate' && !loggedOut
                ? (id) => {
                    openThreadModal(job?.id);
                    setJobData(job);
                  }
                : null
            }
            companyName={job?.company_name}
            experience={job?.experience}
            locations={job?.locations?.map((l) => l.name)}
            employmentOptions={job?.employment_options?.map((e) => e.name)}
            salary={
              job?.min_salary || job?.max_salary
                ? `${job?.min_salary}-${job?.max_salary}`
                : null
            }
          />
          <Accordion
            id="accordion"
            className="accordion-controller no-sidebar py-0 px-3 border-bottom"
            defaultActiveKey="0"
          >
            <div className="card">
              <div className="card-header" id="headingOne">
                <p className="mb-0">
                  <Accordion.Toggle
                    id="0"
                    eventKey="0"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm ${
                      allOpenAccordion.includes('0') && 'collapsed'
                    } right-0 font-weight-bold`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    About the Job
                  </Accordion.Toggle>
                </p>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="card-body">
                  <p className="text-grey">
                    <ReactMarkdown className={`markdown-container in-detail`}>
                      {job?.description}
                    </ReactMarkdown>
                  </p>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
          <div className="w-100 px-3 border-bottom additional-info mt-2">
            {job?.skills && (
              <div className="w-100 d-flex row align-items-center my-1 highlight-row">
                <div
                  className="pl-3 pr-0 mb-0"
                  style={{ minWidth: '12em', alignSelf: 'flex-start' }}
                >
                  Skills
                </div>
                <div
                  className="px-0 badges"
                  {...(dimensions.width < 920
                    ? {
                        className:
                          'px-0 badges card-body d-flex align-items-center skills mx-0 px-0 py-2',
                      }
                    : {})}
                >
                  {job?.skills?.map((s, i) => (
                    <span key={i} className="badge badge-secondary p-1 fs-14">
                      {s.name}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {(job?.min_salary || job?.max_salary) && (
              <div className="w-100 d-flex row align-items-center my-1 highlight-row">
                <div
                  className="pl-3 mb-0 pr-0"
                  style={{ minWidth: '12em', alignSelf: 'flex-start' }}
                >
                  Salary{' '}
                  <FiInfo
                    className="salary-tooltip"
                    data-tip
                    data-for="password-info"
                  />
                  <ReactTooltip
                    id="password-info"
                    type="light"
                    effect="solid"
                    place="top"
                    className="tooltip-shadow"
                  >
                    <p className="p-1 pb-0 mb-0">Monthly gross salary in USD</p>
                  </ReactTooltip>
                </div>
                <div className="pl-1 badges font-weight-bold">
                  ${`${job?.min_salary}-${job?.max_salary}`}{' '}
                  {userInfo.user_type == 'recruiter' ? (
                    <span>
                      {job?.author_id == userInfo?.id &&
                        (job?.show_salary ? '( Public )' : '( Private )')}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
            {job?.languages && (
              <div className="w-100 d-flex row align-items-center my-1 highlight-row">
                <div
                  className="pl-3 mb-0 pr-0"
                  style={{ minWidth: '12em', alignSelf: 'flex-start' }}
                >
                  Languages
                </div>
                <div
                  className="px-0 badges"
                  {...(dimensions.width < 920
                    ? {
                        className:
                          'px-0 badges card-body d-flex align-items-center skills mx-0 px-0 py-2',
                      }
                    : {})}
                >
                  {job?.languages?.map((l, i) => (
                    <span key={i} className="badge badge-secondary p-1 fs-14">
                      {l.name}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {job?.locations && (
              <div className="w-100 d-flex row align-items-center my-1 highlight-row">
                <div
                  className="pl-3 mb-0 pr-0"
                  style={{ minWidth: '12em', alignSelf: 'flex-start' }}
                >
                  Locations
                </div>
                <div
                  className="px-0 badges"
                  {...(dimensions.width < 920
                    ? {
                        className:
                          'px-0 badges card-body d-flex align-items-center skills mx-0 px-0 py-2',
                      }
                    : {})}
                >
                  {job?.locations?.map((s, i) => (
                    <span key={i} className="badge badge-secondary p-1 fs-14">
                      {s.name}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {job?.employment_options && (
              <div className="w-100 d-flex row align-items-center my-1 highlight-row">
                <div
                  className="pl-3 mb-0 pr-0"
                  style={{
                    wordBreak: 'keep-all',
                    minWidth: '12em',
                    alignSelf: 'flex-start',
                  }}
                >
                  Employment option
                </div>
                <div
                  className="px-0 badges"
                  {...(dimensions.width < 920
                    ? {
                        className:
                          'px-0 badges card-body d-flex align-items-center skills mx-0 px-0 py-2',
                      }
                    : {})}
                >
                  {job?.employment_options?.map((s, i) => (
                    <span key={i} className="badge badge-secondary p-1 fs-14">
                      {s.name}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
          <Accordion
            id="accordion2"
            className="accordion-controller no-sidebar py-0 px-3 border-bottom"
            defaultActiveKey="0"
          >
            <div className="card">
              <div className="card-header" id="headingOne">
                <p className="mb-0">
                  <Accordion.Toggle
                    id="1"
                    eventKey="0"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm ${
                      allOpenAccordion.includes('1') && 'collapsed'
                    } right-0 font-weight-bold`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    About the company
                  </Accordion.Toggle>
                </p>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="card-body">
                  <p className={`text-grey preserve-line in-detail`}>
                    {job?.about_company}
                  </p>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
          {job?.company_website && (
            <div className="px-3 mt-3">
              <h5 className="bb">Company Website</h5>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={url}
                style={{ fontWeight: 700 }}
                className="text-primary cursor-pointer"
              >
                {job?.company_website}
              </a>
            </div>
          )}
          <div className={`px-3 date mt-3${dimensions.width < 920 ? ' mb-2' : ''}`}>
            <div className="p-3 d-flex align-items-center border-rounded">
              <div className="mr-4 item">
                <h5 className="bb mb-2">Date</h5>
                <p className="mb-0">{getPostingDay()}</p>
              </div>
              <div className="mx-4 item">
                <h5 className="bb mb-2">Posted By</h5>
                <Link
                  to={constructEncodedUrl({ id: job?.author_id }, 'recruiter')}
                  className="mb-0 author"
                  target="_blank"
                >
                  {job?.author_name}
                </Link>
              </div>
              {loggedOut && dimensions.width > 920 && (
                <div className="card-footer d-flex justify-content-end border-top-0 ml-auto p-0">
                  <button
                    className="btn btn-outline btn-primary btn-rounded mr-2"
                    onClick={(id) => {
                      window.localStorage.setItem('action', 'questions');
                      history.push('/login');
                    }}
                  >
                    Ask Questions
                  </button>
                  <button
                    className="btn btn-primary btn-rounded text-white"
                    onClick={() => {
                      window.localStorage.setItem('action', 'apply');
                      history.push('/login');
                    }}
                  >
                    Apply
                  </button>
                </div>
              )}
            </div>
          </div>
          {loggedOut && dimensions.width < 920 && (
            <div
              className="w-100 bg-white card-footer d-flex justify-content-end ml-auto mb-3"
              style={{
                borderColor: 'transparent',
                boxShadow: 'none',
                padding: '0 20px',
              }}
            >
              <button
                className="btn btn-outline btn-primary btn-rounded mr-2"
                onClick={(id) => {
                  window.localStorage.setItem('action', 'questions');
                  history.push('/login');
                }}
              >
                Ask Questions
              </button>
              <button
                className="btn btn-primary btn-rounded text-white"
                onClick={() => {
                  window.localStorage.setItem('action', 'apply');
                  history.push('/login');
                }}
              >
                Apply
              </button>
            </div>
          )}
          {!loggedOut && (
            <div
              className={`card-footer bg-white d-flex justify-content-end border-top-0${
                dimensions.width < 920 ? ' mt-auto' : ''
              }`}
            >
              {userInfo?.user_type == 'candidate' ? (
                <>
                  <button
                    className="btn btn-outline btn-primary btn-rounded mr-2"
                    onClick={(id) => {
                      if (!loggedOut) {
                        openThreadModal(job?.id);
                        setJobData(job);
                        if (dimensions.width < 920) {
                          let thread_id = contactedUsers?.find(
                            (item) => item?.job_id == job?.id
                          )?.id;
                          history.push(
                            constructEncodedUrl(
                              {
                                jobData: job,
                                job_id: job.id,
                                ...(contacted ? { thread_id } : {}),
                              },
                              'thread'
                            ),
                            { from: history.location.pathname }
                          );
                        }
                      } else if (loggedOut) {
                        history.push('/login');
                      }
                    }}
                  >
                    {contactedUsers?.map((i) => i?.job_id)?.includes(job?.id)
                      ? 'Messages'
                      : 'Ask Questions'}
                  </button>
                  <button
                    className="btn btn-primary btn-rounded text-white"
                    disabled={applied}
                    {...(applied && {
                      style: { backgroundColor: '#666', borderColor: '#666' },
                    })}
                    onClick={() => {
                      if (!loggedOut) {
                        if (!applied) {
                          toggleApplyJob(
                            { id: job?.id, title: job?.title },
                            userInfo
                          );
                          dimensions.width < 920 &&
                            history.push(
                              constructEncodedUrl(
                                { id: job?.id, title: job?.title },
                                'apply'
                              ),
                              { from: history.location.pathname }
                            );
                        }
                      } else if (loggedOut) {
                        history.push('/login');
                      }
                    }}
                  >
                    {applied ? 'Applied' : 'Apply'}
                  </button>
                </>
              ) : job?.author_id == userInfo?.id ? (
                <>
                  <button
                    className="btn btn-outline btn-primary btn-rounded mr-2"
                    onClick={() => {
                      setSelectedJobFilter([{ id: job?.id, title: job?.title }]);
                      setShowApplied(true);
                      dimensions.width > 920
                        ? openThreadModal()
                        : history.push('/messages');
                    }}
                  >
                    Applicants
                  </button>
                  <button
                    className="btn btn-primary btn-rounded text-white"
                    onClick={onMoreCandidates}
                  >
                    More Candidates
                  </button>
                </>
              ) : (
                ''
              )}
            </div>
          )}
          {loggedOut && (
            <div className="d-flex align-items-center justify-content-center card-footer bg-white my-3 border-top-0 mt-auto py-3">
              <a className="more-jobs mt-2" href="https://jobterix.com/careers">
                Show more Jobs
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

JobDetailCard.propTypes = {
  job: PropTypes.object,
  mutate: PropTypes.any,
  onAction: PropTypes.func,
  onGoBack: PropTypes.any,
  savingJobId: PropTypes.string,
  appliedJobs: PropTypes.array,
  savedJobs: PropTypes.array,
  applied: PropTypes.bool,
  showClipBoard: PropTypes.bool,
  loggedOut: PropTypes.bool,
  showActions: PropTypes.bool,
  userInfo: PropTypes.any,
};

JobDetailCard.defaultProps = {
  job: null,
  mutate: null,
  onAction: null,
  onGoBack: null,
  savingJobId: '',
  appliedJobs: [],
  savedJobs: [],
  applied: false,
  loggedOut: false,
  showClipBoard: true,
  showActions: false,
  userInfo: null,
};

export default JobDetailCard;
