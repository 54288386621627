import { createContext, useContext } from "react";

export const JobSortContext = createContext(undefined)

export const useJobSort = () => {
  const context = useContext(JobSortContext)

  if (context === undefined) {
    throw new Error('useJobSort can only be used inside JobSortProvider');
  }

  return context;
}