import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { FiInfo } from 'react-icons/fi';
import '../styles.scss';
import { Controller } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import Classnames from 'classnames';
import _ from 'lodash';
import Select, { components } from 'react-select';
import { useDimensions } from '../../../hooks/useDimensions';
/* eslint-disable react/prop-types */

function About(props) {
  const {
    errors,
    control,
    skills,
    languages,
    geos,
    employments,
    setValue,
    submittedLanguages,
    submitted,
    setSubmitted,
  } = props;
  const [errorState, setErrorState] = React.useState(errors);
  const [modalShow, setModalShow] = React.useState(false);
  const [currentLang, setCurrentLang] = React.useState(null);
  const dimensions = useDimensions();
  const [selectedLanguages, setSelectedLanguages] = React.useState(
    submittedLanguages || []
  );

  React.useEffect(() => {
    setErrorState(errors);
  }, [
    errors?.title,
    errors?.skills,
    errors?.salaryExpectation,
    errors?.languages,
    errors?.geos,
    errors?.experience,
    errors?.employment,
  ]);

  const onCancelProficiency = () => {
    let newLangugaes = selectedLanguages;
    newLangugaes.length = newLangugaes.length - 1;
    setValue('languages', newLangugaes);
    setModalShow(false);
    setCurrentLang(false);
  };

  const onSaveProficiency = () => {
    let savedLanguageIndex = selectedLanguages.findIndex(
      (i) => i.id == currentLang?.id
    );
    let newLangugaes = selectedLanguages;
    newLangugaes[savedLanguageIndex] = currentLang;
    setValue('languages', newLangugaes);
    setModalShow(false);
    setCurrentLang(false);
  };

  const preventSubmitOnEnter = (e) => {
    let charCode = e.which ? e.which : e.keyCode;

    if (charCode == 13) {
      e.preventDefault();
    }
  };

  const preventCharacters = (e) => {
    let charCode = e.which ? e.which : e.keyCode;
    if (
      charCode == 69 ||
      charCode == 40 ||
      charCode == 38 ||
      charCode == 110 ||
      charCode == 107 ||
      charCode == 109 ||
      charCode == 190 ||
      charCode == 189 ||
      charCode == 187 ||
      charCode == 13
    ) {
      e.preventDefault();
    }
  };

  const scrollToError = (el, name) => {
    if (el && Object.keys(errors)[0] == name && submitted) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setSubmitted(false);
    } else {
      return;
    }
  };

  const MultiValueLabel = (props) => {
    return (
      <components.MultiValueLabel {...props}>
        {props.data?.proficiency
          ? props.data?.name + ' - ' + props.data?.proficiency
          : props.data?.name}
      </components.MultiValueLabel>
    );
  };

  const Option = (props) => {
    return (
      <components.Option {...props} className="custom-option">
        <input
          className="form-check-input me-1 f16 border-3 cursor-pointer"
          type="checkbox"
          checked={props.isSelected}
          readOnly
        />
        <span>{props.label}</span>
      </components.Option>
    );
  };

  return (
    <>
      <Modal
        backdropClassName="language-proficiency-modal"
        className="language-modal"
        show={modalShow}
        centered
        size="lg"
        onHide={onCancelProficiency}
      >
        <Modal.Body>
          <div className="text-left">
            <h3 className="mb-2">Specify proficiency</h3>
            <p className="mb-4">Choose your level of {currentLang?.name}</p>
            <div className="d-flex flex-column flex-sm-row align-items-center row mb-4">
              <div className="col-12 col-sm-6">
                <label className="form-label">Language</label>
                <input
                  className="form-control proficiency-language"
                  value={currentLang?.name || ''}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-6 pl-sm-0">
                <label className="form-label">Proficiency</label>
                <select
                  className="form-control proficiency"
                  value={currentLang?.proficiency}
                  onChange={(e) => {
                    setCurrentLang({ ...currentLang, proficiency: e.target.value });
                  }}
                >
                  <option value="Basic">Basic</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                  <option value="Fluent">Fluent</option>
                </select>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <button
                onClick={onCancelProficiency}
                className="btn btn-outline btn-primary btn-rounded px-4"
              >
                Cancel
              </button>
              <button
                onClick={onSaveProficiency}
                className="btn btn-primary btn-rounded text-white px-4"
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'title')}
          >
            <Form.Label>Current Position/Title</Form.Label>
            <Controller
              control={control}
              id={`title`}
              name={`title`}
              defaultValue={''}
              rules={{ required: true }}
              render={(props) => (
                <>
                  <input
                    type="text"
                    className={Classnames(
                      'form-control',
                      errorState?.title && 'is-invalid'
                    )}
                    onKeyDown={preventSubmitOnEnter}
                    placeholder={
                      dimensions.width < 920
                        ? 'Current Position/Title'
                        : `Enter title here`
                    }
                    onChange={(e) => {
                      props.field.onChange(e.target.value);
                      setErrorState({ ...errorState, title: false });
                    }}
                    defaultValue={props.field.value}
                  />
                  <label
                    className={`custom-label position-absolute${
                      props.field.value !== '' || errorState?.title ? ' show' : ''
                    }`}
                  >
                    {errorState?.title ? 'Required' : 'Current Position/Title'}
                  </label>
                </>
              )}
            />

            {Boolean(errorState?.title) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'skills')}
          >
            <Form.Label>Skills</Form.Label>
            <Controller
              control={control}
              id="skills"
              name="skills"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <Select
                    components={{ Option }}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    hideSelectedOptions={false}
                    options={skills}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    isMulti
                    placeholder={dimensions.width < 920 ? 'Skills' : 'Select skills'}
                    onChange={(e) => {
                      onChange(e) + setErrorState({ ...errorState, skills: false });
                    }}
                    defaultValue={value}
                    className={Classnames(
                      'mb-3',
                      errorState?.skills && 'is-invalid'
                    )}
                    classNamePrefix="checkbox-select"
                  />
                  <label
                    className={`custom-label select position-absolute${
                      value?.length > 0 || errorState?.skills ? ' show' : ''
                    }`}
                  >
                    {errorState?.skills ? 'Required' : 'Skills'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.skills) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'experience')}
          >
            <Form.Label>Work experience (years)</Form.Label>
            <Controller
              control={control}
              id={`experience`}
              name={`experience`}
              defaultValue={''}
              rules={{ required: true }}
              render={(props) => (
                <>
                  <input
                    type="number"
                    pattern="\d*"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={preventCharacters}
                    className={Classnames(
                      'form-control',
                      errorState?.experience && 'is-invalid'
                    )}
                    placeholder={
                      dimensions.width < 920
                        ? 'Work Experience (years)'
                        : `Number of years of work experience`
                    }
                    onChange={(e) => {
                      props.field.onChange(e.target.value);
                      setErrorState({ ...errorState, experience: false });
                    }}
                    defaultValue={props.field.value}
                  />
                  <label
                    className={`custom-label position-absolute${
                      props.field.value !== '' || errorState?.experience
                        ? ' show'
                        : ''
                    }`}
                  >
                    {errorState?.experience ? 'Required' : 'Work experience (years)'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.experience) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'salaryExpectation')}
          >
            <Form.Label>
              Salary expectations{' '}
              <FiInfo
                className="mb-1 salary-tooltip"
                data-tip
                data-for="password-info"
              />
              <ReactTooltip
                id="password-info"
                type="light"
                effect="solid"
                place="top"
                className="tooltip-shadow"
              >
                <p className="p-1 pb-0 mb-0">Monthly gross salary in USD</p>
              </ReactTooltip>
            </Form.Label>
            <Controller
              control={control}
              id={`salaryExpectation`}
              name={`salaryExpectation`}
              defaultValue={''}
              rules={{ required: true, min: 3 }}
              render={(props) => (
                <>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className={Classnames(
                          'input-group-text',
                          errorState?.salaryExpectation && 'is-invalid'
                        )}
                      >
                        $
                      </span>
                    </div>
                    <input
                      onKeyDown={preventCharacters}
                      pattern="\d*"
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className={Classnames(
                        'form-control mb-0',
                        errorState?.salaryExpectation && 'is-invalid'
                      )}
                      placeholder={
                        dimensions.width < 920
                          ? 'Salary Expectations USD/month'
                          : `Expected monthly salary`
                      }
                      onChange={(e) =>
                        props.field.onChange(e.target.value) +
                        setErrorState({ ...errorState, salaryExpectation: false })
                      }
                      defaultValue={props.field.value}
                    />
                    <label
                      style={{ borderRadius: '4px' }}
                      className={`custom-label position-absolute${
                        props.field.value > 0 || errorState?.salaryExpectation
                          ? ' show'
                          : ''
                      }`}
                    >
                      {errorState?.salaryExpectation
                        ? 'Required'
                        : 'Salary expectations'}
                    </label>
                  </div>
                </>
              )}
            />
            {Boolean(errorState?.salaryExpectation) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'languages')}
          >
            <Form.Label>Language</Form.Label>
            <Controller
              control={control}
              id="languages"
              name="languages"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <Select
                    options={languages}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    isMulti
                    placeholder={
                      dimensions.width < 920 ? 'Languages' : 'Select languages'
                    }
                    components={{
                      MultiValueLabel,
                    }}
                    key={currentLang?.id || '12321'}
                    onChange={(e) => {
                      onChange(e);
                      setErrorState({ ...errorState, languages: false });
                      setCurrentLang({ ...e[e?.length - 1], proficiency: 'Basic' });
                      if (e.length > selectedLanguages.length) setModalShow(true);
                      setSelectedLanguages(e);
                    }}
                    defaultValue={value}
                    className={Classnames(
                      'mb-3',
                      errorState?.languages && 'is-invalid'
                    )}
                    classNamePrefix="checkbox-select"
                  />
                  <label
                    className={`custom-label select position-absolute${
                      value?.length > 0 || errorState?.languages ? ' show' : ''
                    }`}
                  >
                    {errorState?.languages ? 'Required' : 'Language'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.languages) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'employment')}
          >
            <Form.Label>Employment options</Form.Label>
            <Controller
              control={control}
              id="employment"
              name="employment"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <Select
                    components={{ Option }}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    hideSelectedOptions={false}
                    options={employments}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    isMulti
                    placeholder={
                      dimensions.width < 920
                        ? 'Employment options'
                        : 'Select employment options'
                    }
                    onChange={(e) => {
                      onChange(e);
                      setErrorState({ ...errorState, employment: false });
                    }}
                    defaultValue={value}
                    className={Classnames(
                      'mb-3',
                      errorState?.employment && 'is-invalid'
                    )}
                    classNamePrefix="checkbox-select"
                  />
                  <label
                    className={`custom-label select position-absolute${
                      value?.length > 0 || errorState?.employment ? ' show' : ''
                    }`}
                  >
                    {errorState?.employment ? 'Required' : 'Employment options'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.employment) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'geos')}
          >
            <Form.Label>Preferred geographies</Form.Label>
            <Controller
              control={control}
              id="geos"
              name="geos"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <Select
                    components={{ Option }}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    hideSelectedOptions={false}
                    options={geos}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    isMulti
                    placeholder={
                      dimensions.width < 920
                        ? 'Preferred geographies'
                        : 'Select geographies'
                    }
                    onChange={(e) => {
                      onChange(e);
                      setErrorState({ ...errorState, geos: false });
                    }}
                    defaultValue={value}
                    className={Classnames('mb-3', errorState?.geos && 'is-invalid')}
                    classNamePrefix="checkbox-select"
                  />
                  <label
                    className={`custom-label select position-absolute${
                      value?.length > 0 || errorState?.geos ? ' show' : ''
                    }`}
                  >
                    {errorState?.geos ? 'Required' : 'Preferred geographies'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.geos) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
    </>
  );
}

About.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  trigger: PropTypes.any,
  submittedLanguages: PropTypes.any,
  control: PropTypes.any,
  handleSubmit: PropTypes.func,
  setValue: PropTypes.any,
  clearErrors: PropTypes.any,
  data: PropTypes.any,
  submitted: PropTypes.any,
  setSubmitted: PropTypes.any,
};

export default About;
