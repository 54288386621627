import React from 'react';

export const useDimensions = () => {
  const [dimension, setDimension] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // const onResize = () => {
  //   if (
  //     (dimension.width > 920 && window.innerWidth < 920) ||
  //     (dimension.width < 920 && window.innerWidth > 920)
  //   ) {
  //     setDimension({ width: window.innerWidth });
  //   }
  // };

  // React.useEffect(() => {
  //   window.addEventListener('resize', onResize);
  //   return () => window.removeEventListener('resize', onResize);
  // }, []);

  return dimension;
};
