import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

const AdaptiveTextArea = React.forwardRef((props, ref) => {
  const [row, setRow] = useState(props.rows);
  const [minHeight, setMinHeight] = useState(24);
  const minRows = props.rows;
  const textAreaRef = React.useRef();

  React.useEffect(() => {
    if (textAreaRef.current) {
      !props.noref && ref(textAreaRef.current);
      setMinHeight(textAreaRef.current.scrollHeight + 2);
    }
  }, [textAreaRef?.current]);

  React.useEffect(() => {
    if (textAreaRef.current && props.onboarding) {
      !props.noref && ref(textAreaRef.current);
      setMinHeight(textAreaRef.current.scrollHeight + 2);
    }
  }, [textAreaRef?.current?.value]);

  const handleChange = (event) => {
    minHeight > 24 && setMinHeight(24);
    const textareaLineHeight = 24;

    const previousRows = event.target.rows;
    event.target.rows = minRows;

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    setRow(currentRows);
    props.onChange(event);
  };

  React.useEffect(() => {
    props.rows && setRow(props.rows);
  }, [props.rows]);

  return (
    <textarea
      ref={textAreaRef}
      style={{ ...props.style, minHeight }}
      {...props}
      rows={row}
      onChange={handleChange}
    />
  );
});

AdaptiveTextArea.displayName = 'TextArea';

AdaptiveTextArea.propTypes = {
  rows: PropTypes.number,
  style: PropTypes.any,
  onChange: PropTypes.func,
  onboarding: PropTypes.any,
  noref: PropTypes.any,
};
export default AdaptiveTextArea;
