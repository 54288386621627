import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Redirect } from 'react-router';
import { toggleSaveCandidate } from '../search-candidates/service';
import { useMutation, useQuery } from 'react-query';
import { fetchCandidate } from './service';
import { CustomHelmet } from '../../components/common/SEO';
import Loader from '../../components/loader';
import DetailSidebar from '../../components/detail-sidebar/detail-sidebar';
import { constructEncodedUrl, deconstructEncodedUrl } from '../../utils/helpers';
import { useSavedCandidates } from '../../hooks/useSavedCandidates';
import CandidateDetailCard from '../../components/candidate-detail-card/candidate-detail-card';
import PageNotFound from '../../components/404Card';

const SavedCandidateDetail = (props) => {
  const { userInfo } = props;

  if (userInfo?.user_type == 'candidate') {
    return <Redirect to="/jobs" />;
  }

  const history = useHistory();
  const { id } = useParams();
  const decompressed = deconstructEncodedUrl(id);
  const [skip, setSkip] = useState(
    history.location.state?.from || decompressed?.filters?.from || 0
  );
  if (decompressed?.id == undefined) {
    return <PageNotFound />;
  }
  const limit = decompressed?.filters?.size || 5;
  const [savingCandidateId, setSavingCandidateId] = useState(null);
  const [unAvailablePost, setUnAvailablePost] = useState(false);

  const {
    candidates: savedCandidates,
    refetch: refetchSavedCandidates,
    isFetching: isFetchingSavedCandidates,
  } = useSavedCandidates(true);

  const { data: candidate, isLoading: candidateLoading } = useQuery(
    ['fetch-candidate-details', { id: decompressed?.id }],
    fetchCandidate,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        setUnAvailablePost(true);
      },
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  const data = {
    candidates: savedCandidates?.slice(skip, skip + limit),
    total: savedCandidates?.length,
  };

  const { mutate } = useMutation(toggleSaveCandidate, {
    onSettled: () => {
      refetchSavedCandidates();
    },
    onMutate: ({ id }) => setSavingCandidateId(id),
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const selectCandidate = (id) => {
    return constructEncodedUrl(
      {
        id,
        filters: { ...decompressed?.filters, from: skip, size: limit },
        view: decompressed?.view,
      },
      'candidate/saved'
    );
  };

  const onPaginate = (type) => {
    if (type === 'next' && !(data.candidates?.length < limit)) {
      history.push(history.location.pathname, { from: skip + limit });
    } else if (type === 'prev' && skip) {
      history.push(history.location.pathname, { from: skip - limit });
    } else {
      return;
    }
  };

  useEffect(() => {
    if (!isFetchingSavedCandidates) {
      if (savingCandidateId !== null) {
        setSavingCandidateId(null);
      }
    }
  }, [isFetchingSavedCandidates]);

  useEffect(() => {
    if (history.location.state?.from === 0) {
      setSkip(0);
    }
  }, [history.location.state?.from]);

  if (candidateLoading) {
    return (
      <div className="detail-loader">
        <Loader />
      </div>
    );
  }

  if (unAvailablePost || (!candidateLoading && candidate == undefined)) {
    return (
      <div className="card w-100 text-left p-4">
        <h4 className="bb">About Candidate</h4>
        <p className="mb-0 text-grey">This candidate is no longer available</p>
      </div>
    );
  }

  return (
    <>
      {candidate && (
        <CustomHelmet
          pageTitle={candidate?.designation || 'JobTerix'}
          title={candidate?.designation || 'JobTerix'}
          description={candidate?.about_professional_experience?.slice(0, 155)}
        >
          <meta
            name="keywords"
            content={candidate?.skills?.map((skill) => skill?.name)?.join(', ')}
          />
        </CustomHelmet>
      )}
      {decompressed?.filters !== undefined && (
        <div className="w-320px">
          <div className="row sticky-header">
            <div className="col-xs-12 col-md-12 col-xl-12">
              <div className="sticky-header">
                <DetailSidebar
                  userInfo={userInfo}
                  header="Saved Candidates"
                  onPaginate={onPaginate}
                  data={{
                    ...data,
                    skip,
                    limit,
                  }}
                  dataKey="candidates"
                  titleKey="designation"
                  bodyKey="about_professional_experience"
                  noDataText="No Candidates"
                  totalCountKey="total"
                  selectedItemId={decompressed?.id}
                  onSelectItem={selectCandidate}
                  onNavigateBack={() =>
                    history.push('/candidates/saved', {
                      skip: decompressed?.filters?.from,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`w-${
          decompressed?.filters !== undefined ? '75' : '100'
        } cx-padding pb-2`}
      >
        {candidateLoading && (
          <div className="d-flex flex-column h-100">
            <Loader className="h-100" />
          </div>
        )}
        {!candidateLoading && (
          <CandidateDetailCard
            candidate={candidate}
            mutate={mutate}
            savedCandidates={savedCandidates?.map((i) => i.id)}
            savingCandidateId={savingCandidateId}
          />
        )}
      </div>
    </>
  );
};

SavedCandidateDetail.propTypes = {
  userInfo: PropTypes.any,
};

SavedCandidateDetail.defaultProps = {
  userInfo: null,
};

export default SavedCandidateDetail;
