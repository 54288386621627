import auth0 from 'auth0-js';
import { toast } from 'react-toastify';
export default class Auth {
  constructor(history) {
    this.history = history;
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENTID,
      redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      responseType: 'token id_token',
      scope: 'openid profile email',
    });

    this.logout = this.logout.bind(this);
  }

  login(username, password) {
    return new Promise((resolve, reject) => {
      this.auth0.client.login(
        {
          realm: 'Username-Password-Authentication',
          username,
          password,
        },
        (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve();
            if (data) {
              this.setSession(data);

              // check if user profile is available
              this.history.push('/');
            }
          }
        }
      );
    });
  }

  signup(email, password) {
    return new Promise((resolve, reject) => {
      this.auth0.signup(
        {
          connection: 'Username-Password-Authentication',
          email,
          password,
        },
        (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
            // TODO: Show verify email popup before allowing sign in?
            this.login(email, password);
          }
        }
      );
    });
  }

  authorize(options) {
    this.auth0.authorize(options);
  }

  handleAuth() {
    this.auth0.parseHash((err, res) => {
      if (res && res.accessToken && res.idToken) {
        this.setSession(res);

        // check if user profile is available
        this.history.push('/');
      } else if (err) {
        console.log(err);
      }
    });
  }

  setSession(authRes) {
    const expiresAt = JSON.stringify(
      authRes.expiresIn * 1000 + new Date().getTime()
    );

    localStorage.setItem('access_token', authRes.accessToken);
    localStorage.setItem('id_token', authRes.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  isAuthenticated() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token');

    if (!accessToken) {
      throw new Error('Access token not found');
    }
    return accessToken;
  }

  getProfile(callback) {
    this.auth0.client.userInfo(this.getAccessToken(), (err, profile) => {
      callback(profile);
      if (err) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        this.history.push('/');
      }
    });
  }

  async forgotPassword(email) {
    const reqOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: process.env.REACT_APP_AUTH0_CLIENTID,
        connection: 'Username-Password-Authentication',
        email,
      }),
    };

    const res = await fetch(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
      reqOptions
    );

    if (res.ok) {
      toast.success('Password reset instructions have been sent to your email address');
    }
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');

    this.auth0.logout({
      clientID: process.env.REACT_APP_AUTH0_CLIENTID,
      returnTo: `${window.location.protocol}//${window.location.host}`,
    });
  }
}
