import { createContext, useContext } from 'react';

export const ListsContext = createContext(undefined);

export const useLists = () => {
  const context = useContext(ListsContext);

  if (context === undefined) {
    throw new Error('useLists can only be used inside ListsProvider');
  }

  return context;
};
