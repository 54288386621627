import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Redirect } from 'react-router';
import {
  fetchCandidateSearchResults,
  toggleSaveCandidate,
} from '../search-candidates/service';
import { useMutation, useQuery } from 'react-query';
import { fetchCandidate } from './service';
import Loader from '../../components/loader';
import { CustomHelmet } from '../../components/common/SEO';
import DetailSidebar from '../../components/detail-sidebar/detail-sidebar';
import { constructEncodedUrl, deconstructEncodedUrl } from '../../utils/helpers';
import { useSavedCandidates } from '../../hooks/useSavedCandidates';
import CandidateDetailCard from '../../components/candidate-detail-card/candidate-detail-card';
import { useDimensions } from '../../hooks/useDimensions';
import PageNotFound from '../../components/404Card';

const CandidateDetail = (props) => {
  const { userInfo } = props;

  if (userInfo?.user_type == 'candidate') {
    return <Redirect to="/jobs" />;
  }
  const history = useHistory();
  const dimensions = useDimensions();
  const { id } = useParams();
  const decompressed = deconstructEncodedUrl(id);
  const [skip, setSkip] = useState(
    history.location.state?.from || decompressed?.filters?.from || 0
  );
  const limit = decompressed?.filters?.size || 5;
  const [savingCandidateId, setSavingCandidateId] = useState(null);
  const [unAvailablePost, setUnAvailablePost] = useState(false);

  if (decompressed?.id == undefined) {
    return <PageNotFound />;
  }

  const {
    candidates: savedCandidates,
    refetch: refetchSavedCandidates,
    isFetching: isFetchingSavedCandidates,
  } = useSavedCandidates(true);

  const { data, isLoading } = useQuery(
    ['search-candidates', { ...decompressed?.filters, from: skip, size: limit }],
    fetchCandidateSearchResults,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: decompressed?.filters !== undefined && dimensions.width > 920,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  const {
    data: candidate,
    isLoading: candidateLoading,
    isError,
  } = useQuery(
    ['fetch-candidate-details', { id: decompressed?.id }],
    fetchCandidate,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: () => {
        setUnAvailablePost(true);
      },
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  const { mutate } = useMutation(toggleSaveCandidate, {
    onSettled: () => {
      refetchSavedCandidates();
    },
    onMutate: ({ id }) => setSavingCandidateId(id),
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const selectCandidate = (id) => {
    return constructEncodedUrl(
      { id, filters: { ...decompressed?.filters, from: skip, size: limit } },
      'candidate'
    );
  };

  const onPaginate = (type) => {
    if (type === 'next' && !(data.candidates?.length < limit)) {
      history.push(history.location.pathname, { from: skip + limit });
    } else if (type === 'prev' && skip) {
      history.push(history.location.pathname, { from: skip - limit });
    } else {
      return;
    }
  };

  useEffect(() => {
    if (window?.localStorage.getItem('visit-candidate')) {
      window?.localStorage.removeItem('visit-candidate');
    }
  }, []);

  useEffect(() => {
    if (!isFetchingSavedCandidates) {
      if (savingCandidateId !== null) {
        setSavingCandidateId(null);
      }
    }
  }, [isFetchingSavedCandidates]);

  useEffect(() => {
    if (history.location.state?.from === 0) {
      setSkip(0);
    }
  }, [history.location.state?.from]);

  if (isLoading) {
    return (
      <div className="detail-loader">
        <Loader />
      </div>
    );
  }

  if (unAvailablePost || isError) {
    return (
      <div className="card w-100 text-left p-4">
        <h4 className="bb">About Candidate</h4>
        <p className="mb-0 text-grey">This candidate is no longer available</p>
      </div>
    );
  }

  return (
    <>
      {candidate && (
        <CustomHelmet
          pageTitle={candidate?.designation || 'JobTerix'}
          title={candidate?.designation || 'JobTerix'}
          description={candidate?.about_professional_experience?.slice(0, 155)}
        >
          <meta
            name="keywords"
            content={candidate?.skills?.map((skill) => skill?.name)?.join(', ')}
          />
        </CustomHelmet>
      )}
      {decompressed?.filters !== undefined && dimensions.width > 920 && (
        <div className="w-320px">
          <div className="row sticky-header">
            <div className="col-xs-12 mb-2 col-md-12 col-xl-12">
              <div className="sticky-header">
                <DetailSidebar
                  userInfo={userInfo}
                  header="Edit Filters"
                  onPaginate={onPaginate}
                  data={{ ...data, skip, limit }}
                  dataKey="candidates"
                  titleKey="designation"
                  bodyKey="about_professional_experience"
                  noDataText="No Candidates"
                  totalCountKey="total"
                  selectedItemId={decompressed?.id}
                  onSelectItem={selectCandidate}
                  onNavigateBack={() =>
                    history.push('/candidates', {
                      skip: decompressed?.filters?.from,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`w-${
          decompressed?.filters !== undefined && dimensions.width > 920
            ? '75'
            : '100'
        } ${dimensions.width > 920 ? 'cx-padding pb-2' : ''} `}
      >
        {candidateLoading && (
          <div className="d-flex flex-column h-100">
            <Loader className="my-job-detail-loader" />
          </div>
        )}
        {!candidateLoading && (
          <CandidateDetailCard
            candidate={candidate}
            onGoBack={() => {
              if (decompressed?.cameFrom == 'messages') {
                setTimeout(() => {
                  window.close();
                });
                return;
              }
              history.push(
                decompressed?.view
                  ? `/candidates/${decompressed?.view}`
                  : '/candidates',
                {
                  view: decompressed?.view,
                  skip: decompressed?.filters?.from,
                }
              );
            }}
            mutate={mutate}
            savedCandidates={savedCandidates?.map((i) => i.id)}
            savingCandidateId={savingCandidateId}
          />
        )}
      </div>
    </>
  );
};

CandidateDetail.propTypes = {
  userInfo: PropTypes.any,
};

CandidateDetail.defaultProps = {
  userInfo: null,
};

export default CandidateDetail;
