import React, { useEffect } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import logo from '../images/JobTerix-full.svg';
import defaultAvatar from '../images/default-avatar.svg';
import { useMessageThreads } from '../context/message-threads-context';
import burgerIcon from '../images/burger-button.svg';
import './header.scss';
import { Dropdown } from 'react-bootstrap';
import { useAuth } from '../context/auth-context';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useDimensions } from '../hooks/useDimensions';
import { getAllUnreadThreads, getMinifiedThreads } from '../api';

const Header = ({
  userDetails,
  showHeader,
  sideBarOpened,
  setSideBarOpened,
  setSubHeaderIsOpened,
}) => {
  const auth = useAuth();
  const [userType] = React.useState(userDetails.user_type);
  const history = useHistory();
  const dimensions = useDimensions();
  const { openThreadModal, contactedUsers, setContactedUsers } = useMessageThreads();

  const { data: gqlData } = useQuery(
    ['getAllUnreadThreads', { limit: 100 }],
    getAllUnreadThreads,
    {
      fetchPolicy: 'no-cache',
      refetchOnMount: false,
      refetchInterval: 60000,
      refetchOnWindowFocus: false,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );
  const { data, isLoading, refetch } = useQuery(
    ['minified-threads'],
    getMinifiedThreads,
    {
      fetchPolicy: 'no-cache',
      refetchOnWindowFocus: false,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  const logout = () => {
    auth.logout();
  };

  const showUnreadMessages = () => {
    let unreadMessages;
    if (history.location.pathname == '/messages') {
      unreadMessages = gqlData?.getAllThreads?.items?.filter(
        (message) => message.unread
      )?.length;
    } else {
      unreadMessages = contactedUsers?.filter((message) => message.unread)?.length;
    }
    if (unreadMessages > 0) {
      return <span>1</span>;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (data && !isLoading && history.location.pathname !== '/messages') {
      setContactedUsers(data.data);
    }
  }, [data]);

  const generateLinkLable = (userType) =>
    userType === 'candidate' ? 'Jobs' : 'Candidates';

  const generateRoute = (userType) =>
    userType === 'candidate' ? '/jobs' : '/candidates';

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-light bg-white${
          showHeader ? '' : ' d-none'
        }`}
      >
        <div className="container-lg">
          <img
            src={burgerIcon}
            onClick={() => setSideBarOpened(!sideBarOpened)}
            alt="burger-btn"
          />
          <a className="navbar-brand" href="/">
            <img src={logo} />
          </a>
          <img
            className="user-avatar mobile"
            onClick={() => setSubHeaderIsOpened(true)}
            src={userDetails?.avatar_url || defaultAvatar}
          />
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav my-auto mb-2 sep-left mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className={`nav-link${
                    (history.location.pathname?.includes('/candidate') &&
                      userDetails?.user_type == 'recruiter') ||
                    (history.location.pathname?.includes('/job') &&
                      userDetails?.user_type == 'candidate')
                      ? ' active'
                      : ''
                  }`}
                  to={generateRoute(userDetails.user_type)}
                >
                  {generateLinkLable(userDetails.user_type)}
                </NavLink>
              </li>
              <li className="nav-item">
                {userType == 'recruiter' && (
                  <NavLink className="nav-link" to="/my-jobs">
                    My Jobs
                  </NavLink>
                )}
              </li>
              <li className="nav-item" onClick={() => openThreadModal()}>
                <p className="nav-link mb-0 messages-button" role="button">
                  {showUnreadMessages()}
                  Messages
                </p>
              </li>
            </ul>
            <Dropdown className="header-dropdown">
              <Dropdown.Toggle
                as={'div'}
                className="row cursor-pointer"
                id="dropdown-basic"
              >
                <div className="col pr-0 px-2">
                  <img
                    className="user-avatar"
                    src={userDetails?.avatar_url || defaultAvatar}
                  />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="e-mail text-truncate">{userDetails?.email}</div>
                <Dropdown.Divider></Dropdown.Divider>
                {userType === 'recruiter' &&
                  process.env.REACT_APP_HEAP_ID === '673468839' && (
                    <>
                      <Dropdown.Item>
                        <Link to="/profile/payment">Contacts left: 5</Link>
                      </Dropdown.Item>
                      <Dropdown.Divider></Dropdown.Divider>
                    </>
                  )}

                <Dropdown.Item>
                  <Link to="/profile">Profile</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    type="button"
                    className="btn p-0"
                    data-dismiss="modal"
                    onClick={() => {
                      setTimeout(() => {
                        window.open(
                          'mailto:info@jobterix.com?subject="Support request"',
                          '_self'
                        );
                      }, 100);
                    }}
                  >
                    Help
                  </button>
                </Dropdown.Item>
                <Dropdown.Divider></Dropdown.Divider>
                <Dropdown.Item>
                  <button
                    onClick={logout}
                    type="button"
                    className="btn p-0"
                    data-dismiss="modal"
                  >
                    {'Sign out'}
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </nav>
      {dimensions.width < 920 && (
        <div
          className={`gauge-mobile ${showHeader ? '' : ' d-none'}${
            history?.location?.pathname?.includes('/recruiter') ? ' no-border' : ''
          }`}
          style={{ position: 'relative', zIndex: 5 }}
        >
          <div
            className={`gauge-mobile__tabs ${
              userDetails.user_type == 'recruiter' ? 'rec-tabs' : ''
            } ${
              history.location.pathname == '/jobs' ||
              history.location.pathname == '/candidates'
                ? 'jobs-view'
                : history.location.pathname == '/my-jobs'
                ? 'my-jobs-view'
                : 'messages-view'
            }`}
          >
            <div className="d-flex align-items-center justify-content-around">
              <span
                onClick={() =>
                  history.push(
                    userDetails.user_type == 'candidate' ? '/jobs' : '/candidates'
                  )
                }
                className={
                  history.location.pathname == '/jobs' ||
                  history.location.pathname == '/candidates'
                    ? 'active'
                    : ''
                }
              >
                {userDetails.user_type == 'candidate' ? 'Jobs' : 'Candidates'}
              </span>
              {userDetails.user_type == 'recruiter' && (
                <span
                  onClick={() => history.push('/my-jobs')}
                  className={history.location.pathname == '/my-jobs' ? 'active' : ''}
                >
                  My Jobs
                </span>
              )}
              <span
                onClick={() => history.push('/messages')}
                className={
                  history.location.pathname == '/messages'
                    ? `active messages-button${
                        userDetails.user_type == 'recruiter' ? ' rec-view' : ''
                      }`
                    : `messages-button${
                        userDetails.user_type == 'recruiter' ? ' rec-view' : ''
                      }`
                }
              >
                {showUnreadMessages()}
                Messages
              </span>
            </div>
            <div className="tab-indicator"></div>
          </div>
        </div>
      )}
    </>
  );
};

Header.propTypes = {
  userDetails: PropTypes.any,
  setSideBarOpened: PropTypes.any,
  sideBarOpened: PropTypes.any,
  setSubHeaderIsOpened: PropTypes.any,
  showHeader: PropTypes.any,
};

export default Header;
