import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const Loader = (props) => {
  const { className, noFlex } = props;
  return (
    <div
      className={`${
        !noFlex ? 'd-flex flex-column justify-content-center align-items-center' : ''
      } ${className}`}
    >
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  noFlex: PropTypes.any,
};

Loader.defaultProps = {
  className: '',
  noFlex: false,
};

export default Loader;
