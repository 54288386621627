import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import CardHeader from '../card-header';
import ReactTooltip from 'react-tooltip';
import { FiInfo } from 'react-icons/fi';
import { useMessageThreads } from '../../context/message-threads-context';
import arrowLeft from '../../images/Icons/arrow-detail.svg';
import { useDimensions } from '../../hooks/useDimensions';
import messageIcon from '../../images/Icons/comment.svg';
import messageIconActive from '../../images/Icons/commentActive.svg';
import linkIcon from '../../images/Icons/link.svg';
import linkSaved from '../../images/Icons/linkSaved.svg';
import saveIcon from '../../images/Icons/bookmark.svg';
import savedIcon from '../../images/Icons/bookmark-filled.svg';
import { Spinner } from 'react-bootstrap';
import { constructEncodedUrl } from '../../utils/helpers';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';

const CandidateDetailCard = ({
  candidate,
  mutate,
  savingCandidateId,
  savedCandidates,
  onGoBack,
}) => {
  const { openThreadModal, setCandidateId, setCandidateData, contactedUsers } =
    useMessageThreads();
  const history = useHistory();
  const [allOpenAccordion, setOpenAccordion] = React.useState([]);
  const [showTick, setShowTick] = React.useState(false);
  const dimensions = useDimensions();

  const contacted = contactedUsers
    ?.map((i) => i?.candidate_id)
    ?.includes(candidate?.id);

  const onCandidateMessage = (item, id) => {
    if (
      dimensions.width < 920 &&
      contactedUsers.filter((i) => i.candidate_id === item?.id)?.length > 1
    ) {
      history.push('/messages', {
        candidate_id: id,
        designation: item?.designation,
      });
      return;
    }
    setCandidateData(item);
    setCandidateId(id);
    openThreadModal();
    if (dimensions.width < 920) {
      let thread_id = contactedUsers?.find(
        (item) => item?.candidate_id == id
      )?.thread_id;
      let job_id = contactedUsers?.find((item) => item?.candidate_id == id)?.job_id;
      history.push(
        constructEncodedUrl(
          {
            candidateData: item,
            candidate_id: id,
            ...(contacted && !item?.showInvitation
              ? { thread_id, job_id }
              : {
                  from: history.location.pathname,
                }),
          },
          'thread'
        )
      );
    }
  };

  const toggleAccordian = (e) => {
    const { id } = e.currentTarget;
    allOpenAccordion.includes(id)
      ? setOpenAccordion(allOpenAccordion.filter((i) => i !== id))
      : setOpenAccordion([...allOpenAccordion, id]);
  };

  React.useEffect(() => {
    dimensions.width > 920 && window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={`row job-detail-card ${dimensions.width > 920 ? 'mb-2' : 'mobile'}`}
    >
      <div className="col-xs-12 col-md-12 col-xl-12 jobs-list">
        <div
          className="card card-container"
          {...(dimensions.width < 920
            ? {
                className: 'card card-container d-flex flex-column',
                style: { minHeight: '100vh' },
              }
            : {})}
        >
          {dimensions.width < 920 && (
            <div className="job-detail-card-mobile-nav d-flex align-items-center justify-content-between">
              <img
                src={arrowLeft}
                onClick={(e) => {
                  onGoBack && onGoBack();
                }}
                alt="arrow"
              />
              <div className="d-flex align-items-center">
                <CopyToClipboard
                  text={`${window.location.origin}${constructEncodedUrl(
                    { id: candidate?.id },
                    'candidate'
                  )}`}
                  onCopy={() => {
                    if (showTick) {
                      return;
                    }
                    setShowTick(true);
                    setTimeout(() => setShowTick(false), 5000);
                  }}
                >
                  <button
                    className="btn p-0"
                    onClick={(e) => e.preventDefault() + e.stopPropagation()}
                  >
                    <img
                      className="ch-icons"
                      src={showTick ? linkSaved : linkIcon}
                    />
                  </button>
                </CopyToClipboard>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onCandidateMessage(candidate, candidate?.id);
                  }}
                  className="btn p-0"
                >
                  <img
                    className="ch-icons"
                    src={
                      contactedUsers
                        ?.map((i) => i?.candidate_id)
                        ?.includes(candidate?.id)
                        ? messageIconActive
                        : messageIcon
                    }
                  />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    mutate({
                      id: candidate?.id,
                      saved:
                        savedCandidates && savedCandidates?.includes(candidate?.id),
                    });
                  }}
                  className={`btn p-0`}
                >
                  {savingCandidateId !== candidate?.id ? (
                    <img
                      className="ch-icons"
                      src={
                        savedCandidates?.includes(candidate?.id)
                          ? savedIcon
                          : saveIcon
                      }
                    />
                  ) : (
                    <div style={{ paddingRight: '8px', paddingLeft: '8px' }}>
                      <Spinner size="sm" animation="border" role="status" />
                    </div>
                  )}
                </button>
              </div>
            </div>
          )}
          <CardHeader
            id={candidate?.id}
            inDetail={true}
            userInfo={{ user_type: 'recruiter' }}
            title={candidate?.designation}
            onSave={mutate}
            savingId={savingCandidateId}
            contactedJobs={contactedUsers?.map((i) => i?.candidate_id)}
            saved={savedCandidates && savedCandidates?.includes(candidate?.id)}
            onMessage={(id) => onCandidateMessage(candidate, candidate?.id)}
            experience={candidate?.work_experience}
            locations={candidate?.locations?.map((l) => l.name)}
            employmentOptions={candidate?.employment_options?.map((e) => e.name)}
            salary={candidate?.salary_expectations}
          />
          <Accordion
            id="accordion"
            className="accordion-controller no-sidebar px-3 border-bottom"
            defaultActiveKey="0"
          >
            <div className="card">
              <div className="card-header" id="headingOne">
                <p className="mb-0">
                  <Accordion.Toggle
                    id="0"
                    eventKey="0"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm ${
                      allOpenAccordion.includes('0') && 'collapsed'
                    } right-0 font-weight-bold`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    Experience
                  </Accordion.Toggle>
                </p>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="card-body">
                  <p className={`text-grey preserve-line in-detail`}>
                    {candidate?.about_professional_experience}
                  </p>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
          <div className="w-100 px-3 border-bottom additional-info mt-2">
            <div className="d-flex flex-row my-1 highlight-row">
              <div style={{ minWidth: '12em' }}>Skills</div>
              <div
                {...(dimensions.width < 920
                  ? {
                      className:
                        'card-body d-flex align-items-center skills mx-0 px-0 py-2',
                    }
                  : {})}
              >
                {candidate?.skills?.map((s, i) => (
                  <span key={i} className="badge badge-secondary p-1 fs-14">
                    {s.name}
                  </span>
                ))}
              </div>
            </div>
            <div className="d-flex flex-row my-1">
              <div style={{ minWidth: '12em' }}>
                Salary Expectations{' '}
                <FiInfo
                  className="salary-tooltip"
                  data-tip
                  data-for="password-info"
                />
                <ReactTooltip
                  id="password-info"
                  type="light"
                  effect="solid"
                  place="top"
                  className="tooltip-shadow"
                >
                  <p className="p-1 pb-0 mb-0">Monthly gross salary in USD</p>
                </ReactTooltip>
              </div>
              <div className="px-1 font-weight-bold">
                ${candidate?.salary_expectations}
              </div>
            </div>
            <div className="d-flex flex-row my-1 highlight-row">
              <div style={{ minWidth: '12em' }}>Languages</div>
              <div
                className="font-weight-bold"
                {...(dimensions.width < 920
                  ? {
                      className:
                        'font-weight-bold card-body d-flex align-items-center skills mx-0 px-0 py-2',
                    }
                  : {})}
              >
                {candidate?.languages?.map((s, i) => (
                  <span key={i} className="badge badge-secondary p-1 fs-14">
                    {s.name + ' - ' + s.proficiency}
                  </span>
                ))}
              </div>
            </div>
            <div className="d-flex flex-row my-1 highlight-row">
              <div style={{ minWidth: '12em' }}>Employment Options</div>
              <div
                className="font-weight-bold"
                {...(dimensions.width < 920
                  ? {
                      className:
                        'font-weight-bold card-body d-flex align-items-center skills mx-0 px-0 py-2',
                    }
                  : {})}
              >
                {candidate?.employment_options?.map((s, i) => (
                  <span key={i} className="badge badge-secondary p-1 fs-14">
                    {s.name}
                  </span>
                ))}
              </div>
            </div>
            <div className="d-flex flex-row my-1 highlight-row">
              <div style={{ minWidth: '12em' }}>Locations</div>
              <div
                className="font-weight-bold"
                {...(dimensions.width < 920
                  ? {
                      className:
                        'font-weight-bold card-body d-flex align-items-center skills mx-0 px-0 py-2',
                    }
                  : {})}
              >
                {candidate?.locations?.map((s, i) => (
                  <span key={i} className="badge badge-secondary p-1 fs-14">
                    {s.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div
            className={`card-footer recruiter-buttons bg-white d-flex justify-content-end border-top-0${
              dimensions.width < 920 ? ' mt-auto' : ''
            }`}
          >
            {contacted && (
              <>
                {' '}
                <button
                  className="btn btn-outline btn-primary btn-rounded mr-2"
                  onClick={() =>
                    onCandidateMessage(
                      { ...candidate, showInvitation: true },
                      candidate?.id
                    )
                  }
                  data-tip
                  data-for="offer-job-info"
                >
                  Offer a job
                </button>
                <ReactTooltip
                  id="offer-job-info"
                  type="light"
                  effect="solid"
                  place="top"
                  className="tooltip-shadow"
                >
                  <p className="p-1 pb-0 mb-0">
                    Contact the candidate about a new job opportunity
                  </p>
                </ReactTooltip>
              </>
            )}
            {contacted && (
              <ReactTooltip
                id="messages-candidate-info"
                type="light"
                effect="solid"
                place="top"
                className="tooltip-shadow"
              >
                <p className="p-1 pb-0 mb-0">
                  View historical conversations with the candidate
                </p>
              </ReactTooltip>
            )}
            <button
              className="btn btn-primary btn-rounded text-white"
              onClick={() => onCandidateMessage(candidate, candidate?.id)}
              data-tip
              data-for="messages-candidate-info"
            >
              {contacted ? 'Messages' : 'Contact'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

CandidateDetailCard.propTypes = {
  candidate: PropTypes.object,
  mutate: PropTypes.func,
  onGoBack: PropTypes.func,
  savingCandidateId: PropTypes.string,
  savedCandidates: PropTypes.array,
};

CandidateDetailCard.defaultProps = {
  candidate: null,
  mutate: null,
  onGoBack: null,
  savingCandidateId: '',
  savedCandidates: [],
};

export default CandidateDetailCard;
