import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import calender from '../../images/Icons/calender.svg';
import { Dropdown } from 'react-bootstrap';
import dotMenu from '../../images/Icons/dot-menu.svg';
import appliedIcon from '../../images/Icons/applied.svg';
import './styles.scss';

const getTimeString = (date) => {
  return moment.utc(date).local().format('DD MMM, YYYY');
};

const Thread = ({
  applied,
  updatedAt,
  title,
  archived,
  jobTitle,
  credentials,
  candidateView,
  lastMessage,
  unread,
  onArchive,
  onUnarchive,
  onReport,
  onMark,
  onClick,
  scrollID,
  setScrollID,
}) => {
  return (
    <div
      className="card mobile-thread my-4"
      onClick={(e) => {
        if (e?.target?.alt == 'dot-menu' || e?.target?.id == 'dropdown-basic') {
          return;
        } else {
          onClick(e);
        }
      }}
      ref={(el) => {
        if (el && scrollID) {
          el?.scrollIntoView();
          setScrollID && setScrollID(null);
        }
      }}
    >
      <div
        className="card-header position-relative"
        {...(unread ? { style: { borderLeft: '5px solid #F18343' } } : {})}
      >
        <h5 className={`card-header-title${!candidateView ? ' mb-0' : ''}`}>
          {title}
        </h5>
        <Dropdown alignRight={false} className="messages-dd">
          <Dropdown.Toggle
            as={'div'}
            className="row ml-1 p-2 cursor-pointer"
            style={{ transform: 'translate(-15px,-1px) rotate(90deg)' }}
            id="dropdown-basic"
          >
            <img
              style={{ height: 14, width: 'max-content' }}
              src={dotMenu}
              alt="dot-menu"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="py-0" onClick={(e) => e.stopPropagation()}>
            {!archived && <Dropdown.Item onClick={onArchive}>Archive</Dropdown.Item>}
            {archived && (
              <Dropdown.Item onClick={onUnarchive}>Unarchive</Dropdown.Item>
            )}
            <Dropdown.Item onClick={onMark}>
              {unread ? 'Mark as read' : 'Mark as unread'}
            </Dropdown.Item>
            <Dropdown.Item onClick={onReport}>Report</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="card-body">
        <div className="card-body-description">
          <span>{lastMessage?.sender_name}</span>
          <span className="preserve-line">
            {lastMessage?.content
              ?.replaceAll('&#39;', "'")
              ?.replaceAll('&quot;', '"')}
          </span>
        </div>
        <div
          className={`d-flex thread-info align-items-center align-self-start ${
            applied ? 'three-item' : 'two-item'
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {jobTitle && (
            <div className="d-flex">
              <span>Position: </span>
              <span
                className="ml-1 mb-0 text-grey text-truncate"
                style={{
                  fontSize: '12px',
                  color: '#f3a070',
                  fontWeight: 500,
                }}
              >
                {jobTitle}
              </span>
            </div>
          )}
          {applied && (
            <div className="d-flex">
              <img
                style={{ transform: 'rotateY(180deg)' }}
                className="ch-icons px-0"
                src={appliedIcon}
              />
              <span
                className="ml-1 mb-0 text-grey"
                style={{
                  fontSize: '12px',
                  color: '#01ba3f',
                  fontWeight: 500,
                }}
              >
                Applied
              </span>
            </div>
          )}
          {updatedAt && (
            <div className="d-flex flex-row">
              <img
                className="ch-icons pl-0"
                src={calender}
                style={{
                  transform: 'translateY(-1px)',
                }}
              />
              <span
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#666666',
                }}
              >
                {getTimeString(updatedAt)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Thread.propTypes = {
  userInfo: PropTypes.any,
  candidateView: PropTypes.any,
  applied: PropTypes.any,
  updatedAt: PropTypes.any,
  title: PropTypes.any,
  jobTitle: PropTypes.any,
  onArchive: PropTypes.any,
  onUnarchive: PropTypes.any,
  onMark: PropTypes.any,
  onReport: PropTypes.any,
  credentials: PropTypes.any,
  archived: PropTypes.any,
  lastMessage: PropTypes.any,
  unread: PropTypes.any,
  onClick: PropTypes.any,
  scrollID: PropTypes.any,
  setScrollID: PropTypes.any,
};

export default Thread;
