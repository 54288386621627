import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { getJobs, saveJob } from '../dashboard/service';
import JobDetailCard from '../../components/job-detail-card';
import DetailSidebar from '../../components/detail-sidebar/detail-sidebar';
import Loader from '../../components/loader';
import { fetchJob } from '../../hoc/services/get-job';
import { toast } from 'react-toastify';
import { constructEncodedUrl, deconstructEncodedUrl } from '../../utils/helpers';
import { useSavedJobs } from '../../hooks/useSavedJobs';
import PropTypes from 'prop-types';
import { deleteJob, togglePublishState } from '../myJobs/service';
import { useAppliedJobs } from '../../hooks/useAppliedJobs';
import { useMessageThreads } from '../../context/message-threads-context';
import { useDimensions } from '../../hooks/useDimensions';
import { useApplyJob } from '../../context/apply-job-context';
import PageNotFound from '../../components/404Card';
import { CustomHelmet } from '../../components/common/SEO';

const JobDetailPage = (props) => {
  const history = useHistory();
  const { userInfo } = props;
  const { id } = useParams();
  const decompressed = deconstructEncodedUrl(id);
  const { toggleApplyJob } = useApplyJob();
  const { openThreadModal, setJobData, contactedUsers } = useMessageThreads();
  const [skip, setSkip] = useState(
    history.location.state?.from || decompressed?.filters?.from || 0
  );
  if (decompressed?.id == undefined) {
    return <PageNotFound />;
  }
  const [jobUpdateLoading, setJobUpdateLoading] = React.useState(false);
  const [limit] = useState(decompressed?.filters?.size || 5);
  const [savingJobId, setSavingJobId] = useState(null);
  const { appliedJobs } = useAppliedJobs(userInfo.user_type, userInfo?.id);
  const [unAvailablePost, setUnAvailablePost] = useState(
    decompressed?.filters?.unavilablePost || false
  );

  const dimensions = useDimensions();

  const {
    jobs: savedJobs,
    refetch: refetchSavedJobs,
    isFetching: SavedJobsFetching,
  } = useSavedJobs(userInfo.user_type);

  const { mutate } = useMutation(saveJob, {
    onSettled: () => refetchSavedJobs(),
    onMutate: ({ id }) => setSavingJobId(id),
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { data, isLoading } = useQuery(
    ['search-jobs', { ...decompressed?.filters, from: skip, size: limit }],
    getJobs,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: decompressed?.filters != undefined && dimensions.width > 920,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  const {
    data: job,
    isLoading: jobLoading,
    isFetching,
  } = useQuery(['fetch-job', { id: decompressed?.id }], fetchJob, {
    refetchOnMount:
      dimensions.width < 920 || userInfo?.user_type == 'recruiter' ? true : false,
    onSuccess: (data) => {
      if (data?.status == 'unpublished' || data?.status == 'deleted') {
        setUnAvailablePost(true);
      }
    },
    onError: () => {
      setUnAvailablePost(true);
    },
    refetchOnWindowFocus: false,
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const queryClient = useQueryClient();

  const onDeleteJob = useMutation(deleteJob, {
    onMutate: () => {
      setJobUpdateLoading(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('get-jobs');
      history.push('/my-jobs');
      toast.success('Successfully deleted');
    },
    onError: () => {
      setJobUpdateLoading(false);
      toast.error("Couldn't delete");
    },
  });

  const { mutate: togglePublish } = useMutation(togglePublishState, {
    onSuccess: async (response, variables) => {
      await queryClient.invalidateQueries('fetch-job');
      toast.success(
        variables.status == 'unpublished'
          ? 'Published succesfully'
          : 'Unpublished succesfully'
      );
      setJobUpdateLoading(false);
    },
    onError: () => {
      setJobUpdateLoading(false);
      toast.error('Something went wrong');
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const inArray = (id, array) => {
    return array?.find((key) => key === id) ? true : false;
  };

  const onPaginate = (type) => {
    if (type === 'next' && !(data?.jobs?.length < limit)) {
      history.push(history.location.pathname, { from: skip + limit });
    } else if (type === 'prev' && skip) {
      history.push(history.location.pathname, { from: skip - limit });
    } else {
      return;
    }
  };

  const onJobSelected = (jobId, params) => {
    return constructEncodedUrl(
      {
        id: jobId,
        filters: { ...decompressed?.filters, from: skip, size: limit, ...params },
      },
      'job'
    );
  };

  const onAction = (type, id, status) => {
    switch (type) {
      case 'delete':
        onDeleteJob.mutate({ id });
        break;
      case 'edit':
        history.push(constructEncodedUrl({ type: 'edit' }, 'create-edit-job'), {
          data: job,
        });
        break;
      case 'clone':
        history.push(constructEncodedUrl({ type: 'create' }, 'create-edit-job'), {
          data: job,
        });
        break;
      case 'togglePublish':
        setJobUpdateLoading(true);
        togglePublish({ id, status });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (window?.localStorage.getItem('visit')) {
      window?.localStorage.removeItem('visit');
    }
  }, []);

  React.useEffect(() => {
    if (job && window?.localStorage.getItem('action') && !jobLoading) {
      if (
        window?.localStorage.getItem('action') == 'apply' &&
        !inArray(
          decompressed?.id,
          appliedJobs?.map((i) => i?.id)
        )
      ) {
        toggleApplyJob({ id: job?.id, title: job?.title }, userInfo);
        dimensions.width < 920 &&
          history.push(
            constructEncodedUrl({ id: job?.id, title: job?.title }, 'apply'),
            { from: history.location.pathname }
          );
      }
      if (window?.localStorage.getItem('action') == 'questions') {
        openThreadModal(job?.id);
        setJobData(job);
        if (dimensions.width < 920) {
          const contacted = contactedUsers?.map((i) => i?.job_id)?.includes(job?.id);
          let thread_id = contactedUsers?.find(
            (item) => item?.job_id == job?.id
          )?.id;
          history.push(
            constructEncodedUrl(
              {
                jobData: job,
                job_id: job.id,
                ...(contacted ? { thread_id } : {}),
              },
              'thread'
            ),
            { from: history.location.pathname }
          );
        }
      }
      window?.localStorage.removeItem('action');
    }
  }, [job, appliedJobs, jobLoading, contactedUsers]);

  useEffect(() => {
    if (!SavedJobsFetching) {
      if (savingJobId !== null) {
        setSavingJobId(null);
      }
    }
  }, [SavedJobsFetching]);

  useEffect(() => {
    if (history.location.state?.from === 0) {
      setSkip(0);
    }
  }, [history.location.state?.from]);

  if (isLoading) {
    return (
      <div className="detail-loader">
        <Loader />
      </div>
    );
  }

  if (
    (unAvailablePost || (decompressed?.filters == undefined && unAvailablePost)) &&
    !jobLoading &&
    job?.author_id !== userInfo?.id
  ) {
    return (
      <div className="card w-100 text-left p-4">
        <h4 className="bb">About Job</h4>
        <p className="mb-0 text-grey">This position is no longer available</p>
      </div>
    );
  }

  return (
    <>
      {job && (
        <CustomHelmet
          pageTitle={job?.title || 'JobTerix'}
          title={job?.title || 'JobTerix'}
          description={job?.description?.slice(0, 155)}
        >
          <meta
            name="keywords"
            content={job?.skills?.map((skill) => skill?.name)?.join(', ')}
          />
        </CustomHelmet>
      )}
      {decompressed?.filters != undefined && dimensions.width > 920 && (
        <div className="w-320px">
          <div className="row sticky-header">
            <div className="col-xs-12 mb-2 col-md-12 col-xl-12">
              <div className="sticky-header">
                <DetailSidebar
                  userInfo={userInfo}
                  greyOut={true}
                  header="Edit Filters"
                  onPaginate={onPaginate}
                  data={{ ...data, skip, limit }}
                  bodyKey="description"
                  selectedItemId={decompressed?.id}
                  onSelectItem={onJobSelected}
                  onNavigateBack={() =>
                    history.push('/jobs', {
                      view: decompressed?.view,
                      skip: decompressed?.filters.from,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`w-${
          decompressed?.filters != undefined && dimensions.width > 920 ? '75' : '100'
        } ${dimensions.width > 920 ? 'cx-padding' : ''}`}
      >
        {jobLoading && (
          <div className="detail-loader">
            <Loader />
          </div>
        )}
        {jobUpdateLoading && <Loader className="my-job-detail-loader" />}
        {!jobLoading && (
          <JobDetailCard
            userInfo={userInfo}
            job={job}
            onGoBack={() => {
              if (decompressed?.cameFrom == 'messages') {
                setTimeout(() => {
                  window.close();
                });
                return;
              }
              history.push(
                decompressed?.view ? `/jobs/${decompressed?.view}` : '/jobs',
                {
                  view: decompressed?.view,
                  skip: decompressed?.filters?.from,
                }
              );
            }}
            mutate={userInfo?.user_type == 'candidate' && mutate}
            savedJobs={savedJobs?.map((i) => i.id)}
            appliedJobs={appliedJobs}
            onAction={onAction}
            showClipBoard={job?.author_id !== userInfo?.id}
            showActions={job?.author_id == userInfo?.id}
            applied={inArray(
              decompressed?.id,
              appliedJobs?.map((i) => i?.id)
            )}
            savingJobId={savingJobId}
          />
        )}
      </div>
    </>
  );
};

JobDetailPage.propTypes = {
  userInfo: PropTypes.any,
};

JobDetailPage.defaultProps = {
  userInfo: null,
};

export default JobDetailPage;
