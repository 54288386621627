import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

export const Gauge = ({ view, setCreateModalShow, recentUpdated }) => {
  const history = useHistory();

  const handleViewChange = (type) => {
    if (type == view) {
      return;
    } else {
      history.push('/my-jobs', { view: type });
    }
  };

  return (
    <div className="card">
      <div className="card-body pb-0">
        <div className="row card-border-bottom">
          <div className="col-md-12 col-xl-12">
            <h5 className="card-title f20 bb">My Jobs</h5>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <span
            className={`position-relative mr-3 pb-3 d-block cursor-pointer${
              view === 'recent' ? ' text-primary' : ''
            }`}
            style={{
              borderBottom:
                view === 'recent' ? ' 2px solid #f18343' : '2px solid transparent',
            }}
            onClick={() => handleViewChange('recent')}
          >
            {view == 'draft' && recentUpdated > 0 && (
              <span className="position-absolute recent">{recentUpdated}</span>
            )}
            Published
          </span>
          <span
            className={`position-relative mr-3 pb-3 d-block cursor-pointer${
              view === 'draft' ? ' text-primary' : ''
            }`}
            style={{
              borderBottom:
                view === 'draft' ? ' 2px solid #f18343' : '2px solid transparent',
            }}
            onClick={() => handleViewChange('draft')}
          >
            {view == 'recent' && recentUpdated > 0 && (
              <span className="position-absolute draft">{recentUpdated}</span>
            )}
            Drafts
          </span>
          <button
            className="btn btn-primary btn-rounded text-white ml-auto mb-2"
            style={{ transform: 'translateY(-5px)' }}
            onClick={() => setCreateModalShow(true)}
          >
            Create New Job
          </button>
        </div>
      </div>
    </div>
  );
};

Gauge.propTypes = {
  view: PropTypes.string,
  recentUpdated: PropTypes.any,
  setCreateModalShow: PropTypes.func,
};
