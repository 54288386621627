import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { InputButton } from '../../components/input-button';
import './sign-in.scss';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Button, Spinner } from 'react-bootstrap';
import { validateEmail } from '../../utils/helpers';
import logoColored from '../../images/JobTerix-full.svg';
import { SocialSignIn } from '../../components/social';
import logo from '../../images/logo.png';
import PropTypes from 'prop-types';
import StyledInput from '../../components/input';
import { useAuth } from '../../context/auth-context';
import { useHistory, Redirect } from 'react-router-dom';
import { useDimensions } from '../../hooks/useDimensions';

const SignIn = () => {
  const auth = useAuth();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [toValidate, setToValidate] = useState(false);
  const [email, setEmail] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const dimensions = useDimensions();

  if (auth.isAuthenticated()) {
    return <Redirect to="/" />;
  }

  useEffect(() => {
    if (toValidate) {
      login();
    }
  }, [toValidate]);

  useEffect(() => {
    if (email.length > 0 && toValidate) {
      if (!validateEmail(email)) {
        !errors.includes('email') && setErrors([...errors, 'email']);
      } else {
        setErrors(errors.filter((err) => err !== 'email'));
      }
    }
  }, [email]);

  useEffect(() => {
    if (
      history?.location?.search?.replace('?', '')?.includes('candidate=') &&
      window
    ) {
      window.localStorage.setItem(
        'visit-candidate',
        history?.location?.search?.replace('?candidate=', '')
      );
    }
  }, []);

  const validate = () => {
    if (toValidate) {
      let flag = true;
      let tempErrors = [...errors];

      if (!validateEmail(email)) {
        tempErrors.push('email');
        flag = false;
      } else {
        tempErrors = tempErrors.filter((x) => x !== 'email');
      }

      if (password.length === 0) {
        tempErrors.push('password');
        flag = false;
      } else {
        tempErrors = tempErrors.filter((x) => x !== 'password');
      }

      setErrors([...tempErrors]);
      return flag;
    }
  };

  const hasError = (field) => {
    return errors.some((x) => x === field);
  };

  const preLogin = (e) => {
    e.preventDefault();
    if (toValidate) {
      login();
    } else {
      setToValidate(true);
    }
  };

  const login = async () => {
    setLoading(true);
    if (!validate()) {
      setLoading(false);
      return;
    }
    try {
      await auth.login(email, password);
    } catch (err) {
      setErrorText(err.description);
      setLoginError(true);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row vh-100">
        <div className="w-50 jtx-signin-img p-3 d-none d-md-block">
          <img
            src={logo}
            alt="JobTerix"
            className="cursor-pointer"
            onClick={(e) => window.open('https://jobterix.com/', '_self')}
          />
        </div>
        <div
          className="d-flex align-items-center justify-content-center d-md-none responsive-logo p-4"
          style={{ boxShadow: '0px 4px 2px rgb(0 0 0 / 5%)' }}
        >
          <img src={logoColored} alt="JobTerix" />
        </div>
        <div className="d-flex flex-column align-items-xl-start justify-content-center w-100 p-3 p-sm-5">
          <div className="d-flex flex-column ml-0 ml-sm-4 sign-in-content">
            <h1 className="position-relative text-center text-md-left sign-in-border-bottom">
              Sign in<span className="d-none d-sm-inline"> to JobTerix</span>
            </h1>
            <p
              className="text-center text-md-left d-none d-sm-block"
              style={{ color: '#333333' }}
            >
              Login with your social network account.
            </p>
            <SocialSignIn />
            <div className="d-flex w-100 justify-content-center align-items-center mt-3 mt-sm-0">
              <div className="w-100 line"></div>
              <p
                className="mb-0 ml-3 mr-3"
                style={{ color: '#91919F', whiteSpace: 'nowrap' }}
              >
                Or with
              </p>
              <div className="w-100 line"></div>
            </div>
            <Form className="w-100 sign-in mt-3" onSubmit={preLogin}>
              <Form.Group className="position-relative">
                <Form.Label className="d-none d-sm-block">Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={dimensions.width > 920 ? 'Enter your email' : 'Email'}
                  className={`form-control ${loginError ? 'border-danger' : ''} ${
                    hasError('email') ? 'is-invalid' : ''
                  }`}
                  value={email}
                  autoComplete="off"
                  onChange={(e) => {
                    if (loginError) {
                      setLoginError(false);
                    }
                    setEmail(e.target.value.trim());
                  }}
                />
                <label
                  className={`custom-label position-absolute${
                    email !== '' || hasError('email') ? ' show' : ''
                  }`}
                >
                  {hasError('email')
                    ? 'Please enter a valid email address'
                    : 'Email'}
                </label>
                {hasError('email') && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="position-relative">
                <Form.Label className="d-none d-sm-block">Password</Form.Label>
                <StyledInput>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder={
                        dimensions.width > 920 ? 'Enter your password' : 'Password'
                      }
                      style={{ borderRight: 'none' }}
                      autoComplete="off"
                      className={`form-control ${
                        loginError ? 'mb-1 border-danger' : ''
                      } ${hasError('password') ? 'is-invalid' : ''}`}
                      value={password}
                      onChange={(e) => {
                        if (loginError) {
                          setLoginError(false);
                        }
                        if (e.target.valuer !== '') {
                          errors.includes('password') &&
                            setErrors(errors.filter((err) => err !== 'password'));
                        }
                        setPassword(e.target.value);
                      }}
                    />
                    <label
                      className={`custom-label position-absolute${
                        password !== '' || hasError('password') ? ' show' : ''
                      }`}
                    >
                      {hasError('password')
                        ? 'Password cannot be empty'
                        : 'Password'}
                    </label>
                    <InputGroup.Append className="d-flex">
                      <InputButton
                        className={`${loginError ? 'border-danger' : ''}`}
                        position="end"
                        isInvalid={hasError('password')}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FiEyeOff /> : <FiEye />}
                      </InputButton>
                    </InputGroup.Append>
                    {hasError('password') && (
                      <Form.Control.Feedback type="invalid">
                        Password cannot be empty
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </StyledInput>
                {errorText && (
                  <div className="custom-error-sign-in text-center text-sm-left">
                    {errorText}
                  </div>
                )}
                {dimensions.width > 920 && (
                  <Form.Text>
                    <a
                      className="link-primary"
                      onClick={() => history.push('/forgot-password')}
                    >
                      Forgot Password?
                    </a>
                  </Form.Text>
                )}
              </Form.Group>
              <Button
                type="submit"
                disabled={!(email && password && !errors.length)}
                {...(dimensions?.width < 576 ? { disabled: errors.length > 0 } : {})}
                style={{
                  width: '100%',
                  color: '#fff',
                  marginTop:
                    dimensions?.width < 576
                      ? 'clamp(1rem, calc( 100vh - 725px ), calc( 100vh - 725px ))'
                      : '1rem',
                  borderRadius: dimensions?.width < 576 ? 5 : 20,
                  height: dimensions?.width < 576 ? 56 : 'auto',
                }}
              >
                {loading ? (
                  <Spinner animation="border" role="status" size="sm">
                    <span className="sr-only">Loading...</span>{' '}
                  </Spinner>
                ) : (
                  'Login'
                )}
              </Button>
              {dimensions.width < 920 && (
                <Form.Text>
                  <a
                    className="link-primary"
                    onClick={() => history.push('/forgot-password')}
                  >
                    Forgot Password?
                  </a>
                </Form.Text>
              )}
            </Form>
            <div className="d-flex align-items-center justify-content-center w-100 mt-3">
              <p className="create-account-button">
                {dimensions.width > 920
                  ? 'New to JobTerix?'
                  : 'Don’t have an account yet? '}{' '}
                <a className="link-primary" href="/signup">
                  {dimensions.width > 920 ? 'Join now' : ' Sign up'}
                </a>
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-center w-100 mt-5"></div>
          </div>
        </div>
      </div>
    </>
  );
};

SignIn.propTypes = {
  auth: PropTypes.any,
};

export default SignIn;
