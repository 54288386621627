import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useHistory, Redirect } from 'react-router';
import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useDimensions } from '../../hooks/useDimensions';
import { constructEncodedUrl, isIOS } from '../../utils/helpers';
import { deleteJob } from './service';
import { CreateJob } from './createJob';
import { EditJob } from './editJob';
import { getJobs, togglePublishState } from './service';
import { Gauge } from './gauge';
import { Table } from './table';
import { Helmet } from 'react-helmet';
import { MyJobsList } from './myJobsList';
import arrowLeft from '../../images/Icons/arrow-detail.svg';
import Loader from '../../components/loader';
import './index.scss';

const MyJobs = ({ userInfo }) => {
  if (userInfo?.user_type == 'candidate') {
    return <Redirect to="/jobs" />;
  }

  const history = useHistory();
  const dimensions = useDimensions();
  const [loading, setLoading] = React.useState(false);
  const [recentJobsUpdated, setRecentJobsUpdated] = React.useState(
    history.location.state?.recentUpdated || 0
  );
  const [minHeight, setMinHeight] = React.useState(window?.innerHeight);
  const [selectedData, setSelectedData] = React.useState(null);
  const [editModalShow, setEditModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [createModalShow, setCreateModalShow] = React.useState(false);
  const [view] = React.useState(
    dimensions.width < 920
      ? history.location.state?.view || ''
      : history.location.state?.view || 'recent'
  );

  const { data, isLoading, isFetching } = useQuery(
    ['get-jobs', { id: userInfo?.id }],
    getJobs,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  const queryClient = useQueryClient();

  const { mutate } = useMutation(deleteJob, {
    onSuccess: () => {
      queryClient.invalidateQueries('get-jobs');
      setLoading(false);
      setDeleteModalShow(false);
      toast.success('Successfully deleted');
    },
    onError: () => {
      setLoading(false);
      toast.error("Couldn't delete");
    },
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate: togglePublish } = useMutation(togglePublishState, {
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries('get-jobs');
      setRecentJobsUpdated(recentJobsUpdated + 1);
      setLoading(false);
      setDeleteModalShow(false);
      toast.success(
        variables?.status == 'unpublished'
          ? 'Published succesfully'
          : 'Unpublished succesfully'
      );
    },
    onError: () => {
      setLoading(false);
      toast.error('Something went wrong');
    },
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  React.useEffect(() => {
    setMinHeight(window?.innerHeight);
  }, [window?.innerHeight]);

  const onDelete = (id) => {
    setLoading(true);
    mutate({ id });
  };

  const onAction = (type, item) => {
    switch (type) {
      case 'edit':
        history.push(constructEncodedUrl({ type: 'edit' }, 'create-edit-job'), {
          data: item,
        });
        break;
      case 'clone':
        history.push(constructEncodedUrl({ type: 'create' }, 'create-edit-job'), {
          data: item,
        });
        break;
      case 'see-candidates':
        setTimeout(() => {
          window.open(
            constructEncodedUrl(
              {
                filters: {
                  skills: item?.skills,
                  employmentOptions: item?.employment_options,
                  experience: item?.experience,
                  salary: item?.max_salary,
                  geos: item?.locations,
                  languages: item?.languages,
                },
              },
              'candidates',
              'new'
            ),
            // isIOS() ? '_self' : '_blank'
            '_self'
          );
        });
        break;
      case 'togglePublish':
        setLoading(true);
        togglePublish({ id: item.id, status: item?.status });
        break;
      case 'remove':
        setSelectedData(item);
        setDeleteModalShow(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className="my-jobs">
      <Helmet>
        <title>My jobs - {view == 'recent' ? 'Recent' : 'Drafts'}</title>
      </Helmet>
      {(loading || isLoading) && (
        <div className="loader-container d-flex align-items-center justify-content-center">
          <Loader />
        </div>
      )}
      <Modal
        className="create-job-modal"
        show={createModalShow}
        animation="false"
        onHide={() => {
          setSelectedData(null);
          setCreateModalShow(false);
        }}
        style={{ height: minHeight }}
      >
        <CreateJob
          cloneData={selectedData}
          setRecentJobsUpdated={view == 'draft' ? setRecentJobsUpdated : null}
          recentUpdated={recentJobsUpdated}
          userInfo={userInfo}
          onClose={() => {
            setSelectedData(null);
            setCreateModalShow(false);
          }}
          setLoading={setLoading}
        />
      </Modal>
      <Modal
        className="create-job-modal"
        show={editModalShow}
        animation="false"
        onHide={() => {
          setSelectedData(null);
          setEditModalShow(false);
        }}
        style={{ height: minHeight }}
      >
        <EditJob
          userInfo={userInfo}
          data={selectedData}
          onClose={() => {
            window.sessionStorage.setItem('refetch_id', selectedData?.id);
            setSelectedData(null);
            setEditModalShow(false);
          }}
          setLoading={setLoading}
        />
      </Modal>
      <Modal
        centered
        show={deleteModalShow}
        animation="false"
        className="rounded"
        onHide={() => setDeleteModalShow(false)}
      >
        <Modal.Body>
          <div className="text-left">
            <h3 className="mb-4 fw-700 text-truncate">{selectedData?.title}</h3>
            <p className="mb-4">Are you sure you want to delete this job?</p>
            {selectedData?.status == 'published' && (
              <p className="text-grey mb-4">
                Note: You can always{' '}
                <a
                  style={{ color: '#454fb4', textDecoration: 'underline' }}
                  role="button"
                  onClick={() => onAction('togglePublish', selectedData)}
                >
                  Unpublish
                </a>{' '}
                it which will hide it from candidates
              </p>
            )}
            <div className="d-flex align-items-center justify-content-between">
              <button
                onClick={() => setDeleteModalShow(false) + setSelectedData(null)}
                className="btn btn-outline btn-primary btn-rounded px-4"
              >
                Cancel
              </button>
              <button
                onClick={() => onDelete(selectedData?.id)}
                className="btn btn-primary btn-rounded text-white px-4"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {data?.length == 0 && (
        <div className="my-jobs-placeholder d-flex flex-column align-items-center justify-content-center">
          <h1 className="mt-3 bb">Create your first job and start hiring</h1>
          <button
            className="btn btn-primary btn-rounded text-white mt-4"
            onClick={() => {
              // if (dimensions.width > 920) {
              //   setCreateModalShow(true);
              // } else {
              history.push(
                constructEncodedUrl({ type: 'create' }, 'create-edit-job'),
                { data: null }
              );
              // }
            }}
          >
            Create Job
          </button>
        </div>
      )}
      {data?.length > 0 && dimensions.width > 920 && (
        <div className="cx-padding pb-3">
          <Gauge
            recentUpdated={recentJobsUpdated}
            setRecentUpdated={setRecentJobsUpdated}
            view={view}
            setCreateModalShow={(data) =>
              history.push(
                constructEncodedUrl(
                  {
                    type: 'create',
                  },
                  'create-edit-job'
                ),
                { data: null, from: history.location.pathname }
              )
            }
          />
          <div className="posted-jobs mt-2">
            <Table
              view={view}
              recentUpdated={recentJobsUpdated}
              data={data?.filter((item) => {
                if (view == 'recent') {
                  return item?.status == 'published';
                } else {
                  return item?.status == 'unpublished';
                }
              })}
              onAction={onAction}
            />
          </div>
        </div>
      )}
      {dimensions.width < 920 && view && (
        <div
          className="jobs-applied-mobile-nav d-flex align-items-center"
          style={{ paddingLeft: 20 }}
        >
          <img
            src={arrowLeft}
            onClick={(e) => {
              history.push('/candidates');
            }}
            alt="arrow"
          />
          <h5 className="mb-0 ml-4">
            {view == 'recent' ? 'Published jobs' : 'Drafts'}
          </h5>
        </div>
      )}
      {data?.length > 0 && dimensions.width < 920 && (
        <>
          <MyJobsList
            key={isFetching}
            view={view}
            recentUpdated={recentJobsUpdated}
            data={data?.filter((item) => {
              if (view == 'recent') {
                return item?.status == 'published';
              } else if (view == 'draft') {
                return item?.status == 'unpublished';
              } else {
                return true;
              }
            })}
            onAction={onAction}
          />
          <div className="create-job-mobile-btn">
            <button
              className="btn btn-primary btn-rounded text-white"
              onClick={() =>
                history.push(
                  constructEncodedUrl({ type: 'create' }, 'create-edit-job'),
                  { data: null }
                )
              }
            >
              Create Job
            </button>
          </div>
        </>
      )}
    </div>
  );
};

MyJobs.propTypes = {
  userInfo: PropTypes.any,
  location: PropTypes.any,
};

export default MyJobs;
