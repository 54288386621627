import React from 'react';
import search from '../../images/Icons/search_lens.svg';
import PropTypes from 'prop-types';

const NoResultsFound = ({ height }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column"
      style={{ height: height ? height : '90%' }}
    >
      <p className="text-center mb-0" style={{ fontSize: 20 }}>
        No results found
      </p>
    </div>
  );
};

NoResultsFound.propTypes = {
  height: PropTypes.string,
};

export default NoResultsFound;
