import newHttpClient from '../../../utils/newHttpClient';

export const createCandidateUser = (data) => {
  const {
    title,
    experience,
    salaryExpectation,
    professionalExperience,
    dreamJob,
    skills,
    geos,
    languages,
    employment,
  } = data;
  return newHttpClient.post('/user', {
    user_type: 'candidate',
    designation: title,
    work_experience: parseInt(experience),
    salary_expectations: Math.round(parseInt(salaryExpectation)),
    about_professional_experience: professionalExperience,
    about_dream_job: dreamJob,
    employment_options: employment,
    locations: geos,
    skills: skills,
    languages: languages,
    profile_published: true,
  });
};

export const createRecruiterUser = (data) => {
  const {
    companyName,
    companyWebsite,
    aboutCompany,
    name,
    designation,
    linkedInProfile,
  } = data;
  return newHttpClient.post('/user', {
    user_type: 'recruiter',
    designation,
    name,
    company_name: companyName,
    company_website: companyWebsite,
    about_company: aboutCompany,
    linkedin_profile: linkedInProfile,
  });
};
