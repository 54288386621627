import React, { createRef } from 'react';
import ReactDOM, { render } from 'react-dom';
import PropTypes from 'prop-types';

export const overlay = {
  height: window?.innerWidth < 920 ? '' : '100%',
  width: '100%',
  position: window?.innerWidth < 920 ? '' : 'fixed',
  StayInPlaceZIndex: '1',
  SitOnTopLeft: '0',
  top: window?.innerWidth < 920 ? '' : '0',
  zIndex: window?.innerWidth < 920 ? '' : 111,
  backgroundColor: window?.innerWidth < 920 ? '' : 'rgba(0,0,0, 0.5)',
  onpagehide,
};

export const overlayContent = {
  position: '',
  width: '100%',
};

class Overlay extends React.Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    // Create container DOM element and append to DOM.
    this.overlayContainer = document.createElement('span');
    this.overlayContainer.setAttribute('id', 'modal');
    document.body.appendChild(this.overlayContainer);
    this.listener = (event) => {
      if (event.srcElement.id !== 'overlaycomponent') {
        return;
      }
      if (this.props.close) {
        event.cancelBubble = true;
        this.props.close();
      }
    };
  }
  componentDidMount() {
    if (window.innerWidth > 920) {
      document.addEventListener('mousedown', this.listener);
      document.addEventListener('touchstart', this.listener);
      document.getElementById('root').style.height = '100vh';
      document.getElementById('root').style.overflow = 'hidden';
    }
  }
  componentWillUnmount() {
    if (window.innerWidth > 920) {
      document.getElementById('root').style.height = null;
      document.getElementById('root').style.overflow = null;
      document.body.removeChild(this.overlayContainer);
      document.removeEventListener('mousedown', this.listener);
      document.removeEventListener('touchstart', this.listener);
    }
  }

  render() {
    return window?.innerWidth < 920 ? (
      <div style={overlay} id="overlaycomponent">
        <div style={overlayContent}>
          <div className="modal-animate" ref={this.ref}>
            {this.props.children}
          </div>
        </div>
      </div>
    ) : (
      ReactDOM.createPortal(
        <div style={overlay} id="overlaycomponent">
          <div style={overlayContent}>
            <div className="modal-animate" ref={this.ref}>
              {this.props.children}
            </div>
          </div>
        </div>,
        this.overlayContainer
      )
    );
  }
}

Overlay.propTypes = {
  children: PropTypes.any,
  close: PropTypes.func,
};

export default Overlay;
