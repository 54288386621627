import { createContext, useContext } from "react";

export const FiltersContext = createContext(undefined)

export const useFilters = () => {
  const context = useContext(FiltersContext)

  if (context === undefined) {
    throw new Error('useFilters can only be used inside FiltersProvider');
  }

  return context;
}