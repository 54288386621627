import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { updateUser } from './service';
import {
  useEmploymentOptions,
  useGeographies,
  useLanguages,
  useSkills,
} from '../../hooks/useEntities';
import About from '../onboarding/form/about';
import Personal from '../onboarding/form/personal';
import { useDimensions } from '../../hooks/useDimensions';

const Info = ({ userInfo, setLoading, published, deleteProfile }) => {
  const { data: skills } = useSkills();
  const { data: languages } = useLanguages();
  const { data: empOpts } = useEmploymentOptions();
  const { data: geographies } = useGeographies();
  const [submitted, setSubmitted] = React.useState(false);

  const {
    register,
    formState: { errors },
    trigger,
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      title: userInfo.designation,
      experience: userInfo.work_experience,
      salaryExpectation: userInfo.salary_expectations,
      employment: userInfo.employment_options,
      dreamJob: userInfo.about_dream_job,
      professionalExperience: userInfo.about_professional_experience,
      skills: userInfo.skills,
      languages: userInfo.languages,
      geos: userInfo.locations,
    },
  });

  const dimensions = useDimensions();

  const queryClient = useQueryClient();

  const { mutate } = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('get-auth0-profile');
      toast.success('Updated Succesfully');
      setLoading(false);
    },
    onError: () => {
      toast.error("Couldn't Update");
      setLoading(false);
    },
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    await trigger([
      'title',
      'experience',
      'salaryExpectation',
      'skills',
      'employment',
      'languages',
      'geos',
      'professionalExperience',
      'dreamJob',
    ]).then(async (noError) => {
      if (noError) {
        setLoading(true);
        let {
          dreamJob,
          title,
          employment,
          experience,
          geos,
          languages,
          professionalExperience,
          salaryExpectation,
          skills,
        } = getValues();

        let params = {
          ...userInfo,
          designation: title,
          work_experience: parseInt(experience),
          salary_expectations: parseInt(salaryExpectation),
          about_professional_experience: professionalExperience,
          about_dream_job: dreamJob,
          employment_options: employment,
          locations: geos,
          languages,
          skills,
          profile_published: published,
        };

        mutate({ ...params });
      }
    });
  };

  return (
    <div className="info-page">
      <h3 className="mb-3 fw-700">Your Anonymous Profile</h3>
      <h5 className="mb-3 text-grey fw-400">
        Update your skills and recent expereince
      </h5>
      <Form className="w-75" noValidate id="profile" onSubmit={onSubmit}>
        <About
          submitted={submitted}
          setSubmitted={setSubmitted}
          errors={errors}
          control={control}
          setValue={setValue}
          submittedLanguages={userInfo?.languages}
          skills={skills ?? []}
          geos={geographies ?? []}
          languages={languages ?? []}
          employments={empOpts ?? []}
        />
        <Personal
          submitted={submitted}
          setSubmitted={setSubmitted}
          register={register}
          errors={errors}
          values={getValues}
        />
      </Form>
      <div className={`w-${dimensions.width < 920 ? 100 : 75} d-flex`}>
        <button
          className="btn delete-btn pl-0"
          onClick={(e) => {
            e.stopPropagation();
            deleteProfile();
          }}
        >
          Delete profile
        </button>
        <button
          form="profile"
          type="submit"
          className="btn btn-primary btn-rounded text-white ml-auto"
        >
          Save Profile
        </button>
      </div>
    </div>
  );
};

Info.propTypes = {
  published: PropTypes.bool,
  setLoading: PropTypes.func,
  deleteProfile: PropTypes.func,
  userInfo: PropTypes.any,
};

export default Info;
