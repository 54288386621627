import React, { useState } from 'react';
import PropTypes from 'prop-types';
import btnArrow from '../../../images/Icons/btn-arrow.svg';
import { Form } from 'react-bootstrap';
import '../styles.scss';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDimensions } from '../../../hooks/useDimensions';
import About from './about';
import Personal from './personal';
import Summary from './summary';
import Page from '../page';
import { Spinner } from 'react-bootstrap';

import {
  useEmploymentOptions,
  useGeographies,
  useLanguages,
  useSkills,
} from '../../../hooks/useEntities';
import { useMutation, useQueryClient } from 'react-query';
import { createCandidateUser } from './service';

export const candidate = Object.freeze({
  ABOUT: 1,
  SUMMARY: 2,
});
function CandidateOnboardingForm(props) {
  const { setStep, currentStep } = props;
  const { push } = useHistory();
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const dimensions = useDimensions();

  const { data: skills } = useSkills();
  const { data: languages } = useLanguages();
  const { data: empOpts } = useEmploymentOptions();
  const { data: geographies } = useGeographies();

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    register,
    getValues,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      title: '',
      experience: '',
      salaryExpectation: '',
      employment: [],
      skills: [],
      languages: [],
      professionalExperience: '',
      geos: [],
    },
  });
  const queryClient = useQueryClient();

  const { isLoading, isError, isSuccess, mutate } = useMutation(
    (data) => createCandidateUser(data),
    {
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries('get-user-onboarding-status');
        queryClient.invalidateQueries('get-auth0-profile');
        push({ pathname: '/', state: 'user-details-submitted' });
      },
    }
  );

  const next = async (e) => {
    setSubmitted(true);
    e.preventDefault();
    e.stopPropagation();

    await trigger([
      'title',
      'experience',
      'salaryExpectation',
      'skills',
      'employment',
      'languages',
      'geos',
      'professionalExperience',
    ]).then((noError) => {
      noError && handleSubmit(mutate)();
    });
  };

  const previous = () => {
    if (currentStep === 1) {
      push('/onboarding');
    } else {
      setStep(currentStep - 1);
    }
  };

  const updateInitialValues = (fieldName, value) => {
    reset(
      {
        ...control.defaultValuesRef.current,
        [fieldName]: value,
      },
      { keepValues: true }
    );
  };

  const getTitleBasedOnStep = (step) => {
    let title = 'title';
    switch (step) {
      case candidate.PERSONAL:
        title = 'Tell us more about yourself';
        break;
      case candidate.ABOUT:
        title = 'Tell us more about yourself';
        break;
      case candidate.SUMMARY:
        title = 'Summary';
        break;
      default:
        break;
    }
    return title;
  };
  const getSubTitleBasedOnStep = (step) => {
    let subTitle = 'title';
    switch (step) {
      case candidate.PERSONAL:
        subTitle = 'Personal details';
        break;
      case candidate.ABOUT:
        subTitle = 'Describe your skills and experience';
        break;
      case candidate.SUMMARY:
        subTitle = '';
        break;
      default:
        break;
    }
    return subTitle;
  };
  return (
    <div className="w-100by485 sw-padding vh-100 onboarding bg-white">
      <div className="row">
        <div className="col-xs-12 col-md-12 col-xl-12">
          <div className="sw-headings">
            <h1>{getTitleBasedOnStep(currentStep)}</h1>
            <h3 className={getSubTitleBasedOnStep(currentStep) == '' ? 'mb-0' : ''}>
              {getSubTitleBasedOnStep(currentStep)}
            </h3>
          </div>
          <Form
            className={'w-100 about-candidate'}
            noValidate
            validated={validated}
            onSubmit={(e) => {
              e.preventDefault();
              !submitted && setSubmitted(true);
              handleSubmit(mutate)();
            }}
          >
            <div
              className={`sw-form${currentStep == 2 ? ' step-2' : ''}`}
              style={{ paddingTop: '15px' }}
            >
              {currentStep === candidate.ABOUT && (
                <>
                  <About
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    submittedLanguages={watch('languages')}
                    skills={skills ?? []}
                    geos={geographies ?? []}
                    languages={languages ?? []}
                    employments={empOpts ?? []}
                  />
                  <Personal
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    register={register}
                    values={getValues}
                    errors={errors}
                    onboarding={true}
                  />
                </>
              )}
              {currentStep === candidate.SUMMARY && <Summary data={getValues()} />}
              <div className={`sw-action${dimensions.width < 920 ? ' mt-4' : ''}`}>
                <div className="row align-items-center">
                  <div className="confirmation-buttons">
                    {currentStep > 0 ? (
                      <button
                        type="button"
                        onClick={previous}
                        className="btn sw-btn-default btn-rounded "
                      >
                        {dimensions.width < 576 ? (
                          <span className="chevron left"></span>
                        ) : (
                          'PREVIOUS STEP'
                        )}
                      </button>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div className="col text-right">
                    <div className="confirmation-buttons">
                      <button
                        type="button"
                        onClick={next}
                        className="btn btn-rounded sw-btn-next"
                      >
                        {isLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>{' '}
                          </Spinner>
                        ) : (
                          'Create profile'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

CandidateOnboardingForm.propTypes = {
  currentStep: PropTypes.number,
  setStep: PropTypes.func,
};

const steps = ['Pick your role', 'Tell us more about yourself'];

export default Page(CandidateOnboardingForm, steps, 1);
