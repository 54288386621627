/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import '../styles.scss';
import Page from '../page';
import { Form, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import btnArrow from '../../../images/Icons/btn-arrow.svg';
import Classnames from 'classnames';
import TextArea from '../../../components/descriptions-text-area';
import { useAuth } from '../../../context/auth-context';
import { validateEmail } from '../../../utils/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { createRecruiterUser } from './service';
import { useDimensions } from '../../../hooks/useDimensions';

export const AboutCompany = ({
  control,
  errors,
  page = null,
  values,
  register,
  submitted,
  setSubmitted,
}) => {
  const dimensions = useDimensions();
  const [firstField, setFirstField] = React.useState('');
  const [errorState, setErrorState] = React.useState(errors);

  const preventSubmitOnEnter = (e) => {
    let charCode = e.which ? e.which : e.keyCode;

    if (charCode == 13) {
      e.preventDefault();
    }
  };

  React.useEffect(() => {
    setErrorState(errors);
  }, [
    errors?.companyName,
    errors?.companyWebsite,
    errors?.name,
    errors?.aboutCompany,
    errors.designation,
    errors?.linkedInProfile,
  ]);

  const scrollToError = (el, name) => {
    if (el && Object.keys(errors)[0] == name && submitted) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setSubmitted(false);
    } else {
      return;
    }
  };

  React.useEffect(() => {
    const fieldsValue = values();
    fieldsValue?.aboutCompany && setFirstField(fieldsValue?.aboutCompany);
  }, []);

  const aboutCompany = register('aboutCompany', {
    required: true,
  });

  return (
    <>
      <div className="col px-0">
        <h4 className="mb-4 mb-md-2">Company Details</h4>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-xl-6">
            <Form.Group
              className="form-group position-relative"
              ref={(el) => scrollToError(el, 'companyName')}
            >
              <Form.Label>Whats your company name?</Form.Label>
              <Controller
                control={control}
                id="companyName"
                name="companyName"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      onKeyDown={preventSubmitOnEnter}
                      type="text"
                      className={Classnames(
                        'form-control',
                        errorState?.companyName && 'is-invalid'
                      )}
                      placeholder="Enter company name"
                      onChange={(e) => {
                        onChange(e.target.value);
                        setErrorState({
                          ...errorState,
                          companyName: false,
                        });
                      }}
                      defaultValue={value}
                    />
                    <label
                      className={`custom-label position-absolute${
                        value !== '' || errorState?.companyName ? ' show' : ''
                      }`}
                    >
                      {errorState?.companyName
                        ? 'Required'
                        : 'Whats your company name?'}
                    </label>
                  </>
                )}
              />
              {Boolean(errorState?.companyName) && (
                <Form.Control.Feedback className="d-flex" type="invalid">
                  Required
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
          <div className="col-sm-12 col-md-6 col-xl-6">
            <Form.Group
              className="form-group position-relative"
              ref={(el) => scrollToError(el, 'companyWebsite')}
            >
              <Form.Label>Company Website</Form.Label>
              <Controller
                control={control}
                id="companyWebsite"
                name="companyWebsite"
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      onKeyDown={preventSubmitOnEnter}
                      type="text"
                      className={Classnames(
                        'form-control',
                        errorState?.companyWebsite && 'is-invalid'
                      )}
                      placeholder="Enter company website"
                      onChange={(e) => {
                        onChange(e.target.value);
                        setErrorState({
                          ...errorState,
                          companyWebsite: false,
                        });
                      }}
                      defaultValue={value}
                    />
                    <label
                      className={`custom-label position-absolute${
                        value !== '' || errorState?.companyWebsite ? ' show' : ''
                      }`}
                    >
                      {errorState?.companyWebsite
                        ? 'Company Website (Invalid Link)'
                        : 'Company Website'}
                    </label>
                  </>
                )}
              />
              {Boolean(errorState?.companyWebsite) && (
                <Form.Control.Feedback className="d-flex" type="invalid">
                  Invalid Link
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-xl-12">
            <Form.Group
              className="form-group position-relative"
              ref={(el) => scrollToError(el, 'aboutCompany')}
            >
              <Form.Label>About company</Form.Label>
              <TextArea
                id="aboutCompany"
                name="aboutCompany"
                {...aboutCompany}
                required
                value={values().aboutCompany}
                rows={4}
                onChange={(e) => {
                  page &&
                    dimensions.width > 920 &&
                    e.target?.scrollIntoView({ block: 'end' });
                  setFirstField(e.target.value);
                  setErrorState({
                    ...errorState,
                    aboutCompany: false,
                  });
                  aboutCompany.onChange(e);
                }}
                placeholder="General overview of your company"
                className={Classnames('form-control mb-0', {
                  'is-invalid': errorState?.aboutCompany,
                })}
              />
              <label
                className={`custom-label position-absolute${
                  values().aboutCompany !== '' || errorState?.aboutCompany
                    ? ' show'
                    : ''
                }`}
              >
                {errorState?.aboutCompany ? 'Required' : 'About company'}
              </label>
              <Form.Control.Feedback
                className="d-flex text-grey mt-1"
                type="invalid"
              >
                {Boolean(errorState?.aboutCompany) && (
                  <span className="d-flex text-danger" type="invalid">
                    Required
                  </span>
                )}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="col px-0" style={{ marginTop: 10 }}>
        <h4 className="mb-4 mb-md-2">Recruiter Details</h4>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-xl-6">
            <Form.Group
              className="form-group position-relative"
              ref={(el) => scrollToError(el, 'name')}
            >
              <Form.Label>Name</Form.Label>
              <Controller
                control={control}
                id="name"
                name="name"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      onKeyDown={preventSubmitOnEnter}
                      type="text"
                      className={Classnames(
                        'form-control',
                        errorState?.name && 'is-invalid'
                      )}
                      placeholder="Enter your name"
                      onChange={(e) => {
                        onChange(e.target.value);
                        setErrorState({
                          ...errorState,
                          name: false,
                        });
                      }}
                      defaultValue={value}
                    />
                    <label
                      className={`custom-label position-absolute${
                        value !== '' || errorState?.name ? ' show' : ''
                      }`}
                    >
                      {errorState?.name ? 'Required' : 'Name'}
                    </label>
                  </>
                )}
              />
              {Boolean(errorState?.name) && (
                <Form.Control.Feedback className="d-flex" type="invalid">
                  Required
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
          <div className="col-sm-12 col-md-6 col-xl-6">
            <Form.Group
              className="form-group position-relative"
              ref={(el) => scrollToError(el, 'designation')}
            >
              <Form.Label>Title</Form.Label>
              <Controller
                control={control}
                id="designation"
                name="designation"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      onKeyDown={preventSubmitOnEnter}
                      type="text"
                      className={Classnames(
                        'form-control',
                        errorState?.designation && 'is-invalid'
                      )}
                      placeholder="Enter your job title"
                      onChange={(e) => {
                        onChange(e.target.value);
                        setErrorState({
                          ...errorState,
                          designation: false,
                        });
                      }}
                      defaultValue={value}
                    />
                    <label
                      className={`custom-label position-absolute${
                        value !== '' || errorState?.designation ? ' show' : ''
                      }`}
                    >
                      {errorState?.designation ? 'Required' : 'Title'}
                    </label>
                  </>
                )}
              />
              {Boolean(errorState?.designation) && (
                <Form.Control.Feedback className="d-flex" type="invalid">
                  Required
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        </div>
        {page && (
          <div className="row">
            <div className="col-sm-12 col-md-12 col-xl-12">
              <Form.Group
                className="form-group position-relative"
                ref={(el) => scrollToError(el, 'emailId')}
              >
                <Form.Label>Email ID</Form.Label>
                <Controller
                  control={control}
                  id="emailId"
                  name="emailId"
                  rules={{ required: !page, validate: validateEmail }}
                  render={({ field: { onChange, value } }) => (
                    <input
                      onKeyDown={preventSubmitOnEnter}
                      type="text"
                      className={Classnames(
                        'form-control',
                        errorState?.emailId && 'is-invalid'
                      )}
                      disabled={page}
                      placeholder="Enter your work email id"
                      onChange={(e) => {
                        onChange(e.target.value);
                        setErrorState({
                          ...errorState,
                          emailId: false,
                        });
                      }}
                      defaultValue={value}
                    />
                  )}
                />
                {Boolean(errorState?.emailId) &&
                  errorState?.emailId?.type !== 'validate' && (
                    <Form.Control.Feedback className="d-flex" type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                {Boolean(errorState?.emailId) &&
                  errorState?.emailId?.type === 'validate' && (
                    <Form.Control.Feedback className="d-flex" type="invalid">
                      Invalid email
                    </Form.Control.Feedback>
                  )}
              </Form.Group>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-sm-12 col-md-12 col-xl-12">
            <Form.Group
              className="form-group position-relative"
              ref={(el) => scrollToError(el, 'linkedInProfile')}
            >
              <Form.Label>LinkedIn profile link</Form.Label>
              <Controller
                control={control}
                id="linkedInProfile"
                name="linkedInProfile"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      onKeyDown={preventSubmitOnEnter}
                      type="text"
                      className={Classnames(
                        'form-control',
                        errorState?.linkedInProfile && 'is-invalid'
                      )}
                      placeholder="Enter your LinkedIn profile link"
                      onChange={(e) => {
                        onChange(e.target.value);
                        setErrorState({
                          ...errorState,
                          linkedInProfile: false,
                        });
                      }}
                      defaultValue={value}
                    />{' '}
                    <label
                      className={`custom-label position-absolute${
                        value !== '' || errorState?.linkedInProfile ? ' show' : ''
                      }`}
                    >
                      {errorState?.linkedInProfile
                        ? `(${
                            errorState?.linkedInProfile?.type == 'validate'
                              ? 'Invalid link'
                              : 'Required'
                          })`
                        : 'LinkedIn profile link'}
                    </label>
                  </>
                )}
              />
              {Boolean(errorState?.linkedInProfile) &&
                errorState?.linkedInProfile?.type !== 'validate' && (
                  <Form.Control.Feedback className="d-flex" type="invalid">
                    Required
                  </Form.Control.Feedback>
                )}
              {Boolean(errorState?.linkedInProfile) &&
                errorState?.linkedInProfile?.type === 'validate' && (
                  <Form.Control.Feedback className="d-flex" type="invalid">
                    Invalid link
                  </Form.Control.Feedback>
                )}
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  );
};

const Summary = ({ data }) => {
  const {
    companyName,
    companyWebsite,
    aboutCompany,
    name,
    designation,
    emailId,
    linkedInProfile,
  } = data;

  return (
    <>
      <div className="summary-data">
        <div className="row pb-0">
          <div className="col-6">
            {' '}
            <h6 className="font-weight-bold">Company Details</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p className="card-subtitle">Whats your company name?</p>
          </div>

          <div className="col">
            <p className="card-subtitle">{companyName}</p>
          </div>
        </div>
        {companyWebsite && (
          <div className="row">
            <div className="col-5">
              <p className="card-subtitle">Company Website</p>
            </div>

            <div className="col">
              <p className="card-subtitle">{companyWebsite}</p>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-5">
            <p className="card-subtitle">About company</p>
          </div>
          <div className="col-7">
            <p className="card-subtitle preserve-line">{aboutCompany}</p>
          </div>
        </div>
        <div className="row pb-0 mt-3">
          <div className="col-6">
            <h6 className="font-weight-bold">Recruiter Details</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p className="card-subtitle">Name</p>
          </div>
          <div className="col">
            <p className="card-subtitle">{name}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p className="card-subtitle">Designation</p>
          </div>
          <div className="col">
            <p className="card-subtitle">{designation}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p className="card-subtitle">Email ID</p>
          </div>
          <div className="col">
            <p className="card-subtitle">{emailId}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p className="card-subtitle">LinkedIn Profile</p>
          </div>
          <div className="col">
            <p className="card-subtitle">{linkedInProfile}</p>
          </div>
        </div>
      </div>
    </>
  );
};

function RecruiterOnboardingForm(props) {
  const { push } = useHistory();
  const { setStep, currentStep } = props;
  const [submitted, setSubmitted] = React.useState(false);
  const dimensions = useDimensions();

  const [email, setEmail] = React.useState('');

  const auth = useAuth();

  React.useEffect(() => {
    auth.getProfile((profile) => setEmail(profile.email));
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    getValues,
    reset,
    register,
  } = useForm({
    defaultValues: {
      companyName: '',
      companyWebsite: '',
      aboutCompany: '',
      name: '',
      designation: '',
      linkedInProfile: '',
    },
  });

  const next = async (e) => {
    // TODO: Validate form fields
    setSubmitted(true);
    const res = await trigger([
      'companyName',
      'name',
      'designation',
      'aboutCompany',
      'linkedInProfile',
      'companyWebsite',
    ]);
    res && handleSubmit(mutate)();

    e.preventDefault();
    e.stopPropagation();
  };

  const previous = () => {
    if (currentStep === 1) {
      push('/onboarding');
    } else {
      setStep(currentStep - 1);
    }
  };

  const queryClient = useQueryClient();

  const { isLoading, isSuccess, mutate } = useMutation(
    (data) => createRecruiterUser(data),
    {
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries('get-user-onboarding-status');
        queryClient.invalidateQueries('get-auth0-profile');
        push({ pathname: '/', state: 'user-details-submitted' });
      },
    }
  );

  return (
    <div className="w-100by485 sw-padding vh-100 onboarding bg-white">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <div
            className="sw-headings"
            {...(dimensions.width > 920 ? { style: { paddingTop: 80 } } : {})}
          >
            <h1>{currentStep == 2 ? 'Summary' : 'Tell us more about yourself'}</h1>
            {currentStep == 1 && <h3>Describe Your Company And Your Position</h3>}
          </div>
          <Form
            className="w-100 about-recruiter"
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              !submitted && setSubmitted(true);
              handleSubmit(mutate)();
            }}
          >
            <div className="sw-form" style={{ paddingTop: 23 }}>
              {currentStep == 1 && (
                <AboutCompany
                  submitted={submitted}
                  setSubmitted={setSubmitted}
                  errors={errors}
                  register={register}
                  control={control}
                  values={getValues}
                />
              )}
              {currentStep == 2 && (
                <Summary data={{ ...getValues(), emailId: email }} />
              )}
              <div className="sw-action">
                <div className="row align-items-center">
                  <div className="confirmation-buttons">
                    {currentStep > 0 ? (
                      <button
                        type="button"
                        onClick={previous}
                        className="btn sw-btn-default btn-rounded "
                      >
                        {dimensions.width < 576 ? (
                          <span className="chevron left"></span>
                        ) : (
                          'PREVIOUS STEP'
                        )}
                      </button>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div className="col text-right">
                    <div className="confirmation-buttons">
                      <button
                        type="button"
                        onClick={next}
                        className="btn btn-rounded sw-btn-next"
                      >
                        {isLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>{' '}
                          </Spinner>
                        ) : (
                          'Create profile'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

RecruiterOnboardingForm.propTypes = {
  currentStep: PropTypes.number,
  setStep: PropTypes.func,
  page: PropTypes.string,
};

const steps = ['Pick your role', 'Tell Us More About Yourself'];

export default Page(RecruiterOnboardingForm, steps, 1);
