import {
  getSavedCandidates,
  saveCandidate,
  UnSaveCandidate,
  searchCandidates,
} from '../../api';

export const fetchCandidateSearchResults = async ({ queryKey: [_, filters] }) => {
  try {
    const { data } = await searchCandidates(filters);

    /**
     * Leaving a gap here cause I need to return saved candidates in search endpoint
     */
    return { candidates: data.users, total: data.count, savedCandidates: [] };
  } catch (e) {
    console.log(e);
    throw e;
  }
};

/**
 * Still need a backend api for this, creating a mock for now
 */
export const toggleSaveCandidate = async ({ id, saved }) => {
  try {
    if (saved) {
      const { data } = await UnSaveCandidate(id);
      return data;
    } else {
      const { data } = await saveCandidate(id);
      return data;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchSavedCandidates = async () => {
  try {
    const { data } = await getSavedCandidates();
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
