import React from 'react';
import PropTypes from 'prop-types';
import backIcon from '../../images/Icons/back.svg';
import './detail-sidebar.scss';
import { Link } from 'react-router-dom';

const DetailSidebar = (props) => {
  const {
    userInfo,
    data,
    dataKey,
    header,
    selectedItemId,
    onSelectItem,
    onNavigateBack,
    titleKey,
    bodyKey,
    onPaginate,
    noDataText,
    greyOut,
    totalCountKey,
  } = props;

  return (
    <div className="card detail-sidebar rounded">
      <div className="d-flex p-3 header rounded-top">
        <div
          className="d-flex justify-content-start w-100"
          role="button"
          onClick={() => onNavigateBack()}
        >
          <img className="pr-1" src={backIcon} />
          <h5 className="mb-0 font-weight-bold">{header}</h5>
        </div>
      </div>

      <div className="d-flex flex-column p-3 items-list">
        {data[dataKey]?.length > 0 &&
          data[dataKey].map((item, key) => (
            <Link
              key={key}
              className={`card-link m-0${
                item.status !== 'published' && greyOut ? ' greyed-out' : ''
              }`}
              onClick={(e) =>
                item.status !== 'published' && greyOut && e.preventDefault()
              }
              to={() => {
                if (item.status !== 'published' && greyOut) {
                  return '';
                } else {
                  return onSelectItem(item.id, {
                    unavilablePost:
                      item.status !== 'published' && greyOut ? true : false,
                  });
                }
              }}
            >
              <div
                className={`card my-1 ${item.id === selectedItemId ? 'active' : ''}`}
                role="button"
              >
                <div className="card-body p-2">
                  <p className="font-weight-bold item-content mb-1">
                    {item[titleKey]}
                  </p>
                  <p
                    className={`mb-0 text-secondary item-content${
                      userInfo?.user_type == 'candidate' ? '' : ' preserve-line'
                    }`}
                  >
                    {userInfo?.user_type == 'candidate'
                      ? item[bodyKey]?.replaceAll('**', '')?.replaceAll('###', '')
                      : item[bodyKey]}
                  </p>
                </div>
              </div>
            </Link>
          ))}
      </div>
      {data[dataKey]?.length == 0 && (
        <h3 className="text-center text-primary my-4">{noDataText}</h3>
      )}
      {data[totalCountKey] !== data.limit && (
        <div className="pagination small d-flex align-items-center px-4 py-2">
          <span className="d-block mr-auto">
            Page {data.skip == 0 ? 1 : data.skip / data.limit + 1}
          </span>
          {data.skip !== 0 && (
            <div
              className="btn text-primary mr-2"
              onClick={() => onPaginate('prev')}
            >
              Previous
            </div>
          )}
          {!(data[totalCountKey] <= data.skip + data.limit) && (
            <div className="btn text-primary" onClick={() => onPaginate('next')}>
              Next
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DetailSidebar.propTypes = {
  userInfo: PropTypes.any,
  data: PropTypes.object,
  selectedItemId: PropTypes.string,
  onSelectItem: PropTypes.func,
  onNavigateBack: PropTypes.func,
  titleKey: PropTypes.string,
  greyOut: PropTypes.bool,
  onPaginate: PropTypes.func,
  bodyKey: PropTypes.string,
  dataKey: PropTypes.string,
  header: PropTypes.string,
  noDataText: PropTypes.string,
  totalCountKey: PropTypes.string,
};

DetailSidebar.defaultProps = {
  userInfo: null,
  data: null,
  itemsLoading: true,
  selectedItemId: null,
  onSelectItem: null,
  greyOut: false,
  onNavigateBack: null,
  onPaginate: null,
  titleKey: 'title',
  bodyKey: 'qualifications',
  dataKey: 'jobs',
  header: 'Edit Filters',
  noDataText: 'No Jobs',
  totalCountKey: 'totalJobs',
};
export default DetailSidebar;
