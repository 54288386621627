import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';
import {
  setNotification,
  clearNotification,
} from '../store/actions/notificationActions';
import close from '../images/Icons/close.svg';
import tick from '../images/Icons/tick.svg';
import 'react-toastify/dist/ReactToastify.css';

const Notification = () => {
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state);
  const closeNotification = () => {
    dispatch(clearNotification());
  };
  return (
    <>
      <ToastContainer
        autoClose={3000}
        closeButton={(closeButton) => <img className="mr-2" alt="x" src={close} />}
        toastClassName="bg-primary custom-alert d-flex align-items-center text-white"
        hideProgressBar
        transition={Slide}
      />
      {notification.show && (
        <div className="tost-notication">
          <img className="ch-icons" src={tick} />
          {notification.message}
          <img onClick={closeNotification} className="ch-icons pl-4" src={close} />
        </div>
      )}
    </>
  );
};

export default Notification;
