import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EmojiPicker from 'emoji-picker-react';
import Select, { components } from 'react-select';
import { Modal } from 'react-bootstrap';
import { useApplyJob } from '../../context/apply-job-context';
import AdaptiveTextArea from '../../components/adaptive-textarea';
import { ReactComponent as SendButton } from '../../images/Icons/send-btn.svg';
import Loader from '../../components/loader';
import { CreateJob } from '../myJobs/createJob';
import smile from '../../images/Icons/smile.svg';
import backBig from '../../images/Icons/back-big.svg';
import { useDimensions } from '../../hooks/useDimensions';
import { constructEncodedUrl, deconstructEncodedUrl } from '../../utils/helpers';
import { useHistory, useParams } from 'react-router-dom';

const Option = (props) => (
  <components.Option
    {...props}
    {...(props.data.id == 'createBtn' ? { className: 'create-btn' } : {})}
  >
    {props.data.id == 'createBtn' ? (
      <button className="btn create-job-btn p-2 py-3 d-flex align-items-center justify-content-center">
        <span className="d-block mb-1 mr-1">+ </span>
        {props.data.title}
      </button>
    ) : (
      <span className="d-block text-truncate">{props.data.title}</span>
    )}
  </components.Option>
);

export const InvitationPage = ({
  loading,
  userInfo,
  closeThreadModal,
  showDetails,
  sendInvite,
  setLoading,
  isLoading,
  myJobs,
  candidateData,
  messageContent,
  setMessageContent,
  candidateId,
  contactedUsers,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const decompressed = deconstructEncodedUrl(id);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [selectedJob, setSelectedJob] = useState(
    history.location.state?.newSelectedJob || null
  );
  const [showErrors, setShowErrors] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [minHeight, setMinHeight] = React.useState(window?.innerHeight);
  const [textAreaFocusKey, setTextAreaFocusKey] = useState(0);
  const titleRef = useRef();
  const [rows, setRows] = React.useState(1);
  const [recRows, setRecRows] = React.useState(9);
  const [cursorPosition, setCursorPosition] = React.useState(0);
  const { toggleApplyJob, showMessages } = useApplyJob();
  const dimensions = useDimensions();

  const keyPressHandler = (e) => {
    if (
      (e.keyCode === 13 || e.which == 13) &&
      e.shiftKey == false &&
      dimensions.width > 920
    ) {
      setRows(1);
      e.preventDefault();
      messageContent?.trim() !== '' &&
        sendInvite(messageContent, candidateData?.id, userInfo?.id);
      e.target.blur();
    }
  };

  const jobOptions = (jobs) => {
    if (jobs) {
      let contactedJobs = contactedUsers.filter(
        (item) => item.candidate_id === candidateData?.id
      );
      let newJobs = jobs.sort((a, b) =>
        moment.utc(b.created_at).diff(moment.utc(a.created_at))
      );
      if (contactedJobs?.length > 0) {
        const contactedJobIds = contactedJobs?.map((item) => item?.job_id);
        newJobs = newJobs?.filter((item) => !contactedJobIds?.includes(item?.id));
      }
      return newJobs;
    } else {
      return [];
    }
  };

  React.useEffect(() => {
    if (dimensions.width < 920 && minHeight - window?.innerHeight < 150) {
      setMinHeight(window?.innerHeight);
    }
  }, [window?.innerHeight]);

  React.useEffect(() => {
    loading && setLoading(false);
  }, []);

  return (
    <div
      className="slide-container right-slide bg-white"
      {...(dimensions.width < 920 ? { style: { minHeight } } : {})}
    >
      {!showMessages && <Loader className="messages-loader white-bg" />}
      {userInfo?.user_type == 'recruiter' && (
        <Modal
          className="create-job-modal invitation"
          show={createModalShow}
          animation="false"
          onHide={() => setCreateModalShow(false)}
        >
          <CreateJob
            userInfo={userInfo}
            onClose={(data) => {
              setCreateModalShow(false);
              data &&
                setSelectedJob({ id: data?.data?.id, title: data?.data?.title });
            }}
            setLoading={setLoading}
            isLoading={loading}
          />
        </Modal>
      )}
      {(isLoading || loading) && <Loader className="messages-loader" />}
      <div
        className={`row card-border-bottom mb-0 d-flex align-items-center title-holder${
          userInfo?.user_type == 'recruiter' ? ' recruiter-view' : ''
        }`}
        ref={titleRef}
      >
        <div
          className={`col-${
            dimensions.width < 920 ? 12 : 9
          } col text-md-center text-xl-left pr-0 col-md-9`}
          onClick={() => {
            closeThreadModal();
            if (dimensions.width < 920) {
              decompressed?.from
                ? history.push(decompressed.from, {
                    ...(decompressed?.state ? decompressed.state : {}),
                  })
                : history.go(-1);
            }
          }}
        >
          <img
            className="slide-bt-back btn-ab-left cursor-pointer mt-1"
            src={backBig}
          />
          <h5
            className={`slide-header job-title${
              dimensions.width < 920 ? ' pr-0 mr-4' : ''
            } ml-large mb-0 underline'`}
            onClick={(e) => {
              e.stopPropagation();
              showDetails();
            }}
          >
            {candidateData && userInfo?.user_type == 'recruiter'
              ? candidateData.designation
              : candidateData.title}
          </h5>
        </div>
        <div
          className={`col-md-${
            dimensions.width < 920 ? 12 : 3
          } col text-md-center text-xl-right col-md-3`}
        >
          <div className="confirmation-buttons messages d-flex align-items-center justify-content-around">
            {userInfo?.user_type == 'candidate' && (
              <button
                onClick={() => {
                  toggleApplyJob(candidateData, userInfo);
                  dimensions.width < 920 &&
                    history.push(
                      constructEncodedUrl(
                        { id: candidateData?.id, title: candidateData?.title },
                        'apply'
                      ),
                      {
                        ...history?.location?.state,
                      }
                    );
                }}
                type="button"
                className="btn btn-primary btn-rounded text-white ml-auto"
              >
                {dimensions.width > 920 ? 'Apply' : 'Apply for the job'}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-12 col text-md-center text-xl-left px-0 mt-4 invitation-page-content">
        {userInfo?.user_type == 'candidate' && (
          <>
            <div
              className="d-flex flex-column justify-content-between"
              style={{
                height:
                  dimensions.width < 920
                    ? ''
                    : `calc(100vh - ${titleRef?.current?.offsetHeight}px - 65px - ${
                        rows * 24
                      }px)`,
              }}
              onClick={(e) => {
                showPicker && setShowPicker(false);
              }}
            >
              <div
                className="mesages-container py-1"
                style={{
                  maxHeight:
                    dimensions.width < 920
                      ? 'unset'
                      : `calc(100vh - 168px${rows && ' - ' + rows + '*24px'})`,
                }}
              ></div>
              {showPicker && (
                <div className="chat-emoji-container">
                  <EmojiPicker
                    onEmojiClick={(event, emojiObject) => {
                      setMessageContent(
                        (prev) =>
                          prev.substring(0, cursorPosition) +
                          emojiObject.emoji +
                          prev.substring(cursorPosition)
                      );
                      setTextAreaFocusKey((prev) => prev + 1);
                      setShowPicker(!showPicker);
                    }}
                  />
                </div>
              )}
            </div>
            <form
              className={`mt-auto${
                userInfo.user_type == 'recruiter' ? ' mb-2' : ''
              }`}
              onSubmit={(e) => {
                if (dimensions.width > 920) {
                  e.preventDefault();
                  sendInvite(messageContent, candidateData?.id, userInfo?.id);
                }
              }}
              onKeyPress={keyPressHandler}
            >
              <div className="d-flex align-items-start message-input-holder position-relative">
                <AdaptiveTextArea
                  {...{ autofocus: true }}
                  msg={messageContent}
                  setMsg={setMessageContent}
                  getRows={(data) => setRows(data)}
                  rows={rows}
                  focusKey={textAreaFocusKey}
                  setCursorPosition={setCursorPosition}
                />
                {dimensions.width > 920 && (
                  <img
                    className="emoji-toggler position absolute"
                    src={smile}
                    alt="smile"
                    onClick={() => setShowPicker(!showPicker)}
                  />
                )}
                {dimensions.width < 920 && (
                  <SendButton
                    className="emoji-toggler position absolute"
                    style={{
                      width: 38,
                      height: 38,
                      top: 'unset',
                      bottom: 17,
                      right: 27,
                    }}
                    onClick={(e) => {
                      setRows(1);
                      messageContent?.trim() !== '' &&
                        sendInvite(messageContent, candidateData?.id, userInfo?.id);
                    }}
                  />
                )}
              </div>
            </form>
          </>
        )}
        {userInfo?.user_type == 'recruiter' && (
          <>
            <div
              className="message-info"
              style={{
                height:
                  dimensions.width > 920
                    ? `calc(100vh - ${titleRef?.current?.offsetHeight}px - 95px)`
                    : '',
                minHeight: dimensions.width > 920 ? 260 : ``,
              }}
            >
              <div className="position-relative form-group">
                {dimensions.width > 920 && (
                  <label className="form-label" style={{ fontWeight: 500 }}>
                    Choose job from the list
                  </label>
                )}
                <Select
                  className={`group-messages my-2 mb-3${
                    showErrors && !selectedJob ? ' is-invalid' : ''
                  }`}
                  hideSelectedOptions={false}
                  options={[
                    ...jobOptions(myJobs),
                    { id: 'createBtn', title: 'Create New Job' },
                  ]}
                  getOptionValue={(opt) => opt.id}
                  getOptionLabel={(opt) => opt.title}
                  placeholder={
                    dimensions.width > 920
                      ? 'Select an option'
                      : 'Choose job from the list'
                  }
                  isClearable
                  components={{ Option }}
                  value={selectedJob}
                  onChange={(value) => {
                    if (value?.id === 'createBtn') {
                      dimensions.width < 920
                        ? history.push(
                            constructEncodedUrl(
                              {
                                type: 'create',
                              },
                              'create-edit-job'
                            ),
                            { data: null, from: history.location.pathname }
                          )
                        : setCreateModalShow(true);
                    } else {
                      setSelectedJob(value);
                    }
                  }}
                  classNamePrefix="checkbox-select"
                />
                {dimensions.width < 920 && (
                  <label
                    className={`custom-label select position-absolute${
                      selectedJob || (showErrors && !selectedJob) ? ' show' : ''
                    }`}
                  >
                    {showErrors && !selectedJob
                      ? 'Required'
                      : 'Choose job from the list'}
                  </label>
                )}
                {showErrors && !selectedJob && (
                  <span className="invalid-feedback mb-2">Required</span>
                )}
              </div>
              <div className="position-relative form-group" style={{ zIndex: 5 }}>
                {dimensions.width > 920 && (
                  <label className="form-label" style={{ fontWeight: 500 }}>
                    Message
                  </label>
                )}
                <AdaptiveTextArea
                  msg={messageContent}
                  setMsg={setMessageContent}
                  getRows={(data) => data > recRows && setRecRows(data)}
                  rows={recRows}
                  maxRowsNumb={dimensions.width < 376 ? 15 : 24}
                  style={{ maxHeight: window?.innerHeight - 315 }}
                  className={`form-control${
                    showErrors && (!messageContent || messageContent?.trim() == '')
                      ? ' mb-3 is-invalid'
                      : ''
                  }`}
                  placeholder={
                    dimensions.width > 920 ? 'Enter your message' : 'Message'
                  }
                />
                {dimensions.width < 920 && (
                  <label
                    className={`custom-label position-absolute ${
                      messageContent ||
                      (showErrors &&
                        (!messageContent || messageContent?.trim() == ''))
                        ? 'show'
                        : ''
                    }
                   `}
                  >
                    Message
                  </label>
                )}
                {showErrors && (!messageContent || messageContent?.trim() == '') && (
                  <span className="invalid-feedback mb-1">Required</span>
                )}
              </div>
            </div>
            <div className="confirmation-buttons invite messages d-flex align-items-center justify-content-between">
              <button
                type="button"
                className="btn btn-outline btn-primary btn-rounded mr-2"
                data-dismiss="modal"
                onClick={() => {
                  closeThreadModal();
                  if (dimensions.width < 920) {
                    decompressed?.from
                      ? history.push(decompressed.from, {
                          ...(decompressed?.state ? decompressed.state : {}),
                        })
                      : history.go(-1);
                  }
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (messageContent?.trim() !== '' && selectedJob) {
                    sendInvite(messageContent, selectedJob.id, candidateId);
                  } else if (messageContent?.trim() == '' || !selectedJob) {
                    setShowErrors(true);
                  }
                }}
                disabled={
                  dimensions.width < 920 && (!selectedJob || !messageContent)
                }
                type="button"
                className="btn btn-primary btn-rounded text-white"
              >
                Send
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Option.propTypes = {
  data: PropTypes.any,
};

InvitationPage.propTypes = {
  userInfo: PropTypes.any,
  myJobs: PropTypes.any,
  candidateData: PropTypes.any,
  isLoading: PropTypes.any,
  loading: PropTypes.any,
  setLoading: PropTypes.any,
  messageContent: PropTypes.any,
  setMessageContent: PropTypes.any,
  candidateId: PropTypes.any,
  closeThreadModal: PropTypes.any,
  showDetails: PropTypes.any,
  sendInvite: PropTypes.any,
  contactedUsers: PropTypes.any,
};
