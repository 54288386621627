import newHttpClient from '../../utils/newHttpClient';

export const getJobs = async ({ _, queryKey }) => {
  const { data } = await newHttpClient.get(`/recruiter/${queryKey[1]['id']}/jobs`);
  return data;
};

export const createJob = async (data) => {
  return await newHttpClient.post('/job', { ...data });
};

export const deleteJob = async (data) => {
  return await newHttpClient.delete(`/job/${data.id}`);
};

export const getJob = async (data) => {
  return await newHttpClient.get(`/job/${data.id}`);
};

export const togglePublishState = async (data) => {
  if (data.status == 'published') {
    return await newHttpClient.put(`/job/${data.id}/unpublish`);
  }
  return await newHttpClient.put(`/job/${data.id}/publish`);
};

export const updateJob = async (data) => {
  const params = Object.keys(data)
    .filter((key) => key !== 'id')
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});
  return await newHttpClient.put(`/job/${data.id}`, { ...params });
};
