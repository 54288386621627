import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import '../styles.scss';
import Classnames from 'classnames';
import TextArea from '../../../components/descriptions-text-area';
import { isIOS } from '../../../utils/helpers';
import { useDimensions } from '../../../hooks/useDimensions';

function Personal(props) {
  const {
    register,
    errors,
    values,
    submitted,
    setSubmitted,
    onboarding = false,
  } = props;
  const dimensions = useDimensions();

  const [errorState, setErrorState] = React.useState(errors);

  const [firstField, setFirstField] = useState('');
  const [addfakeHeight, setAddFakeHeight] = useState(false);

  const onDreamJobFocus = () => {
    if (isIOS() || dimensions.width > 920) {
      return;
    }
    !addfakeHeight && setAddFakeHeight(true);
  };

  const onDreamJobBlur = () => {
    if (isIOS() || dimensions.width > 920) {
      return;
    }
    addfakeHeight && setAddFakeHeight(false);
  };

  React.useEffect(() => {
    const fieldsValue = values();
    fieldsValue?.professionalExperience &&
      setFirstField(fieldsValue?.professionalExperience);
  }, []);

  React.useEffect(() => {
    setErrorState(errors);
  }, [errors?.professionalExperience]);

  const professionalExperience = register('professionalExperience', {
    validate: (string) => string?.trim() !== '',
    required: true,
  });

  const scrollToError = (el, name) => {
    if (el && Object.keys(errors)[0] == name && submitted) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setSubmitted(false);
    } else {
      return;
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12 mb-3 mb-sm-0">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'professionalExperience')}
          >
            <Form.Label>Tell us about your professional experience.</Form.Label>
            <TextArea
              id="professionalExperience"
              name="professionalExperience"
              {...professionalExperience}
              required
              onboarding={onboarding}
              value={values().professionalExperience}
              rows={4}
              onFocus={onDreamJobFocus}
              onBlur={onDreamJobBlur}
              onChange={(e) => {
                setFirstField(e.target.value);
                setErrorState({ ...errorState, professionalExperience: false });
                professionalExperience.onChange(e);
              }}
              placeholder={
                dimensions.width < 576
                  ? 'Tell us about your professional experience'
                  : 'Enter value here'
              }
              className={Classnames('form-control mb-0', {
                'is-invalid': errorState?.professionalExperience,
              })}
            />
            <label
              className={`custom-label position-absolute${
                firstField?.length > 0 || errorState?.professionalExperience
                  ? ' show'
                  : ''
              }`}
            >
              {errorState?.professionalExperience
                ? 'Required'
                : 'Tell us about your professional experience'}
            </label>
            <Form.Control.Feedback className="d-flex text-grey mt-1" type="invalid">
              {Boolean(errorState?.professionalExperience) && (
                <span className="d-flex text-danger" type="invalid">
                  Required
                </span>
              )}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
      {addfakeHeight && !isIOS() && dimensions.width < 920 && (
        <div style={{ height: 180 }}></div>
      )}
    </>
  );
}

Personal.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  values: PropTypes.any,
  submitted: PropTypes.any,
  setSubmitted: PropTypes.any,
  initialText: PropTypes.any,
  onboarding: PropTypes.any,
};

export default Personal;
