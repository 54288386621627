import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { sortBy, escape, unescape, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import backBig from '../../images/Icons/back-big.svg';
import classNames from 'classnames';
import smile from '../../images/Icons/smile.svg';
import { ReactComponent as SendButton } from '../../images/Icons/send-btn.svg';
import { toggleSaveCandidate } from '../search-candidates/service';
import Loader from '../../components/loader';
import moment from 'moment';
import editIcon from '../../images/Icons/edit-black.svg';
import './styles.scss';
import { useDimensions } from '../../hooks/useDimensions';
import { useMessageThreads } from '../../context/message-threads-context';
import {
  constructEncodedUrl,
  deconstructEncodedUrl,
  isIOS,
} from '../../utils/helpers';
import AdaptiveTextArea from '../../components/adaptive-textarea';
import TextArea from '../../components/descriptions-text-area';
import saveIcon from '../../images/Icons/bookmark.svg';
import savedIcon from '../../images/Icons/bookmark-filled.svg';
import { Spinner } from 'react-bootstrap';
import EmojiPicker from 'emoji-picker-react';
import { useApplyJob } from '../../context/apply-job-context';
import { useSavedCandidates } from '../../hooks/useSavedCandidates';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getMessages,
  markAsRead,
  createMessage,
  updateMessage as UPDATE_MESSAGE,
} from '../../api';
import httpClient from '../../utils/newHttpClient';

const ApplicationContent = ({ candidate, jobId, setRefetchLoading }) => {
  const titleMapping = {
    designation: 'Designation',
    resume_key: 'CV',
    portfolio_link: 'Portfolio',
    git_profile_link: 'Github',
    linkedin_profile: 'Linkedin',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
  };
  const dimensions = useDimensions();

  const contentKeys = Object.keys(titleMapping);
  const getCv = async () => {
    let windowReference;

    if (isIOS()) {
      windowReference = window.open();
    }

    let response;
    setRefetchLoading(true);
    try {
      response = await httpClient.getFetch(
        `/job/${jobId}/candidate/${candidate.id}/resume`
      );

      isIOS()
        ? (windowReference.location = response.url)
        : window.open(response.url);

      setRefetchLoading(false);
    } catch (err) {
      console.log(err);
    }
    setTimeout(() => {
      response?.url && window.open(response?.url, '_blank');
    });
  };
  const generateLink = (key, content) => {
    if (key === 'email') {
      return <a href={`mailto:${content}`}>{content}</a>;
    }
    if (key == 'designation') {
      return <span>{content}</span>;
    }
    return (
      <a
        href={!content?.match(/^https?:\/\//i) ? 'http://' + content : content}
        rel="noreferrer"
        target="_blank"
      >
        {content}
      </a>
    );
  };

  return (
    <div className="d-flex flex-column application-message text-truncate">
      {contentKeys.map((key, index) => {
        if (key == 'name') {
          return;
        }
        if (key == 'resume_key') {
          return (
            <p key={`${key}-${index}`} className="mb-0">
              {titleMapping[key]} :{' '}
              <a
                href="#"
                onClick={async () => {
                  let response = await getCv();
                }}
              >
                Resume
              </a>
            </p>
          );
        }
        if (candidate[key] && candidate[key].length > 0) {
          return (
            <p
              key={`${key}-${index}`}
              className="mb-0 d-inline-block text-truncate"
              style={{ maxWidth: dimensions.width < 920 ? '90%' : '100%' }}
            >
              {titleMapping[key]} :{' '}
              {/* <a href={candidate[key]}>{candidate[key]}</a> */}
              {generateLink(key, candidate[key])}
            </p>
          );
        }
      })}
    </div>
  );
};

ApplicationContent.propTypes = {
  jobId: PropTypes.string,
  candidate: PropTypes.any,
  setRefetchLoading: PropTypes.func,
};

const MessageRow = React.forwardRef((props, ref) => {
  const {
    userType,
    content,
    content_type,
    setRefetchLoading,
    onSaveMessage,
    created_at,
    edited_at,
    userInfo: { id, name },
    sender_id,
    candidate,
    recruiter,
    applied,
    messageId,
    jobId,
    editMode,
    setEditMode,
  } = props;
  const [newContent, setNewContent] = React.useState(content);
  const messageTextEditRef = React.useRef();
  const dimensions = useDimensions();

  const userImage = () => {
    /**
     * If it is me, show my name
     * If it is the rec/candidate, for rec, show their name, for candidate, show their name or designation
     */
    let name = userName();
    return (
      <span
        className="align-self-top mr-2 d-flex align-items-center justify-content-center rounded-circle text-center text-white"
        style={{
          width: 32,
          minWidth: 32,
          height: 32,
          backgroundColor: userType == 'recruiter' ? '#f18343' : '#43adf1',
        }}
      >
        {name[0]?.toUpperCase()}
      </span>
    );
  };

  const userName = () => {
    let name = '';
    if (sender_id === id) {
      name = 'You';
    } else {
      if (userType === 'recruiter') {
        name = applied ? candidate.name : candidate.designation;
      } else {
        name = recruiter.name;
      }
    }
    return name;
  };

  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

  function detectURLs(message) {
    return content?.match(urlRegex);
  }

  const urlify = (text) => {
    const links = detectURLs(text);
    let newText = text;
    let newMessageObject = {};

    links?.map((link, key) => {
      newText = newText?.replace(link, sender_id);
    });

    newText = newText?.split(sender_id);

    links?.forEach((link, key) => {
      newMessageObject[`text-${key}`] = newText[key];
      newMessageObject[`link-${key}`] = link;
      if (key == links?.length - 1) {
        newMessageObject[`text-${key + 1}`] = newText[key + 1];
      }
    });

    return Object.keys(newMessageObject)?.map((key, idx) => {
      if (key?.includes('text')) {
        return <span key={idx}>{unescape(newMessageObject[key])}</span>;
      } else if (key?.includes('link')) {
        return (
          <a
            href={
              newMessageObject[key]?.match(/^https?:\/\//i)
                ? newMessageObject[key]
                : `http://${newMessageObject[key]}`
            }
            key={idx}
            target="_blank"
            rel="noreferrer"
            style={{ lineBreak: 'anywhere' }}
          >
            {newMessageObject[key]}
          </a>
        );
      }
    });
  };

  return (
    <div
      className="d-flex mb-3 message-content"
      onClick={(e) => {
        e.stopPropagation();
        messageId !== editMode && setEditMode(null);
      }}
      ref={ref}
    >
      {userImage()}
      {!editMode && content_type === 'text' && sender_id == id && (
        <img
          src={editIcon}
          onClick={(e) => {
            e.stopPropagation();
            setEditMode(messageId);
          }}
          alt="edit"
        />
      )}
      <div className="d-flex flex-column w-100">
        <p className="font-weight-bold mb-0 d-flex align-items-end">
          <span
            className="text-truncate d-block mr-2"
            style={{ maxWidth: dimensions.width < 920 ? '65%' : '75%' }}
          >
            {userName()}
          </span>{' '}
          <span className="message-date d-block" style={{ lineHeight: '21px' }}>
            {created_at && moment.utc(created_at).local().format('MMM DD HH:mm')}
          </span>
        </p>
        {editMode !== messageId && content_type === 'text' && (
          <p className="mb-0 preserve-line" style={{ wordBreak: 'break-word' }}>
            {detectURLs(content)?.length > 0 ? urlify(content) : unescape(content)}
            {edited_at && <span style={{ color: '#808080' }}> (Edited)</span>}
          </p>
        )}
        {editMode == messageId && content_type === 'text' && (
          <div className="w-100 position-relative">
            <TextArea
              {...{ autofocus: true }}
              value={newContent}
              className="form-control edit-msg"
              ref={messageTextEditRef}
              noref
              onKeyPress={(e) => {
                let charCode = e.which ? e.which : e.keyCode;
                if (charCode == 13 && dimensions.width < 920) {
                  e.target?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                  });
                }
              }}
              onChange={(e) => setNewContent(e.target.value)}
            />
            <div className="d-flex message-actions">
              <span
                onClick={(e) => {
                  setEditMode(null);
                  setNewContent(content);
                }}
              >
                Cancel
              </span>
              <span
                onClick={(e) =>
                  onSaveMessage({
                    content: escape(newContent?.trimEnd()),
                    message_id: messageId,
                  })
                }
              >
                Save
              </span>
            </div>
          </div>
        )}
        {content_type === 'application' && (
          <ApplicationContent
            jobId={jobId}
            candidate={candidate}
            setRefetchLoading={setRefetchLoading}
          />
        )}
      </div>
    </div>
  );
});

MessageRow.displayName = 'MessageRow';

MessageRow.propTypes = {
  userInfo: PropTypes.any,
  userType: PropTypes.string,
  content: PropTypes.string,
  content_type: PropTypes.string,
  created_at: PropTypes.string,
  jobId: PropTypes.string,
  messageId: PropTypes.string,
  candidateId: PropTypes.string,
  edited_at: PropTypes.any,
  editMode: PropTypes.any,
  setEditMode: PropTypes.any,
  name: PropTypes.string,
  from: PropTypes.string,
  sender_id: PropTypes.string,
  candidate: PropTypes.any,
  setRefetchLoading: PropTypes.func,
  onSaveMessage: PropTypes.func,
  recruiter: PropTypes.any,
  applied: PropTypes.bool,
};
const MessageDetails = ({
  userInfo,
  candidateId,
  setCandidateId,
  setResetThreads,
  setRecruiterId,
  last_message,
  setLast_Message,
  threads,
  setThreads,
  setShowThreads,
  initialLoading,
  setInitialLoading,
}) => {
  const {
    setJobId,
    threadId,
    jobId,
    setCandidateData,
    setThreadId,
    setJobData,
    closeThreadModal,
    candidateData,
    jobData,
    setMobileThreads,
    mobileLastMessage,
    setMobileLastMessage,
    contactedUsers,
    setContactedUsers,
  } = useMessageThreads();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const decompressed = deconstructEncodedUrl(id);
  const client = useQueryClient();

  const { toggleApplyJob, showMessages } = useApplyJob();
  const [showPicker, setShowPicker] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [textAreaFocusKey, setTextAreaFocusKey] = useState(0);
  const [savingCandidateId, setSavingCandidateId] = React.useState(null);
  const [editMode, setEditMode] = React.useState(null);
  const [cursorPosition, setCursorPosition] = React.useState(0);
  const dimensions = useDimensions();
  const [rerenderKey, setRerenderKey] = useState(1);
  const [thread, setThread] = useState(
    jobData
      ? {
          job: {
            ...jobData,
          },
        }
      : candidateData
      ? {
          candidate: {
            ...candidateData,
          },
        }
      : {}
  );
  const [messages, setMessages] = useState([]);
  const [shouldScroll, setShouldScroll] = useState(true);
  const [initalScroll, setInitialScroll] = useState(true);
  const [minHeight, setMinHeight] = React.useState(window?.innerHeight);

  const { candidates: savedCandidates, refetch: refetchSavedCandidates } =
    useSavedCandidates(userInfo?.user_type !== 'candidate' ? true : false);

  const { mutate } = useMutation(toggleSaveCandidate, {
    onSettled: async () => {
      await refetchSavedCandidates();
      setSavingCandidateId(null);
    },
    onMutate: ({ id }) => setSavingCandidateId(id),
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate: markThreadRead } = useMutation(markAsRead, {
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate: updateMessage } = useMutation(UPDATE_MESSAGE, {
    onSuccess: () => {
      client.invalidateQueries('getMessages');
      setEditMode(null);
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { data, isLoading: loading } = useQuery(
    ['getMessages', { id: threadId }],
    getMessages,
    {
      onSuccess: () => {
        // editMode && setEditMode(false);
        refetchLoading && setRefetchLoading(false);
        initialLoading && setInitialLoading(false);
      },
      refetchInterval: 5000,
      refetchOnMount: false,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  useEffect(() => {
    if (data) {
      let { candidate, job, id: thread_id } = data;
      const sortedMessages = sortBy(data.messages, ['created_at']);
      setThread({
        ...data,
        candidate: {
          ...candidate,
        },
        job: {
          ...job,
        },
      });
      markThreadRead({
        id: thread_id,
      });
      setMessages(sortedMessages);
    }
  }, [data]);

  const { mutate: sendMessage } = useMutation(createMessage, {
    onSuccess: async (data) => {
      client.invalidateQueries('getMessages');
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const titleRef = useRef();
  const [msg, setMsg] = useState('');
  const [rows, setRows] = React.useState(1);

  const showDetails = (showJob = false) => {
    if (!showJob) {
      window.open(
        constructEncodedUrl(
          {
            id:
              userInfo.user_type == 'recruiter'
                ? data?.candidate?.id
                : data?.job?.id,
            cameFrom: 'messages',
            ...(data?.job?.status == 'deleted' || data?.job?.status == 'unpublished'
              ? { filters: { unavilablePost: true } }
              : {}),
          },
          userInfo.user_type == 'recruiter' ? 'candidate' : 'job'
        )
      );
    } else {
      return constructEncodedUrl({ id: data?.job?.id, cameFrom: 'messages' }, 'job');
    }
  };

  const send = (e) => {
    e.preventDefault();
    if (msg?.trim() == '') {
      return;
    }
    sendMessage({
      content: escape(msg?.trimEnd()),
      content_type: 'text',
      thread_id: threadId,
    });
    setMsg('');
  };

  const onBackClick = async () => {
    setRefetchLoading(true);
    dimensions.width < 920 && window.scrollTo(window.scrollX, window.scrollY);
    let latestMessage = dimensions.width < 920 ? mobileLastMessage : last_message;
    let isUpdated = !(
      latestMessage == messages[messages?.length - 1]?.['content'] ||
      (latestMessage == '"Applied to Job"' &&
        messages[messages?.length - 1]?.['content_type'] == 'application')
    );

    const searchThreadID = threadId;

    if (!isUpdated && threads?.find((el) => el?.id == searchThreadID)?.unread) {
      dimensions.width < 920
        ? setMobileThreads(
            threads?.map((el) => {
              if (el?.id == searchThreadID) {
                return { ...el, unread: null };
              } else {
                return el;
              }
            })
          )
        : setThreads(
            threads?.map((el) => {
              if (el?.id == searchThreadID) {
                return { ...el, unread: null };
              } else {
                return el;
              }
            })
          );
    }

    if (isUpdated) {
      if (dimensions.width < 920) {
        setMobileThreads(null);
      } else {
        setResetThreads(true);
      }
    }

    if (dimensions.width > 920) {
      if (
        contactedUsers
          ?.filter((el) => el?.unread)
          ?.map((el) => el?.id)
          ?.includes(threadId)
      ) {
        setContactedUsers(
          contactedUsers?.map((el) => {
            if (el?.id == threadId) {
              return { ...el, unread: null };
            }
            return el;
          })
        );
      }
      setLast_Message(null);
      setRefetchLoading(false);
      setCandidateId(null);
      setRecruiterId(null);
      setCandidateData(null);
      setThreadId(null);
      setJobData(null);
      setJobId(null);
    } else {
      if (dimensions.width < 920) {
        setMobileLastMessage(null);
        closeThreadModal();
        if (decompressed?.from || history?.location?.state?.from) {
          history.push(decompressed?.from || history?.location?.state?.from, {
            ...(decompressed?.state || history?.location?.state
              ? decompressed.state || history?.location?.state
              : {}),
          });
        } else {
          history.go(-1);
        }
        return;
      }
    }
  };

  const keyPressHandler = (e) => {
    if (dimensions.width < 920 && msg?.trim() == '') {
      setRows(1);
    }
    if (
      (e.keyCode === 13 || e.which == 13) &&
      e.shiftKey == false &&
      dimensions.width > 920
    ) {
      setRows(1);
      send(e);
    }
  };

  React.useEffect(() => {
    setRerenderKey(rerenderKey + 1);
  }, [data, messages?.length]);

  React.useEffect(() => {
    if (dimensions.width < 920 && minHeight - window?.innerHeight < 150) {
      setMinHeight(window?.innerHeight);
    }
  }, [window?.innerHeight]);

  return (
    <>
      <div
        className="slide-container right-slide bg-white"
        {...(dimensions.width < 920
          ? {
              style: {
                minHeight,
              },
            }
          : {})}
      >
        {(loading ||
          !showMessages ||
          refetchLoading ||
          !titleRef?.current?.offsetHeight) &&
          !initialLoading && (
            <Loader
              noFlex={dimensions.width < 920}
              className={`messages-loader${
                !titleRef?.current?.offsetHeight ? ' white-bg' : ''
              }`}
            />
          )}
        {(!loading || jobData || candidateData) && showMessages && !isEmpty(thread) && (
          <>
            <div
              className={`row card-border-bottom mb-0 d-flex align-items-center title-holder${
                userInfo?.user_type == 'recruiter' ? ' recruiter-view' : ''
              }`}
              ref={titleRef}
            >
              <div
                className={`col-1${
                  userInfo?.user_type == 'recruiter' ? 1 : 2
                } col text-md-center text-xl-left pr-0 col-md-9`}
                onClick={() => onBackClick()}
                {...(dimensions.width < 920 ? { style: { minWidth: '90%' } } : {})}
              >
                <img
                  className="slide-bt-back btn-ab-left cursor-pointer mt-1"
                  src={backBig}
                />
                <h5
                  className={`slide-header job-title${
                    dimensions.width < 920 ? ' pr-0 mr-4' : ''
                  } ml-large mb-0 underline`}
                  onClick={(e) => {
                    e.stopPropagation();
                    showDetails(false);
                  }}
                >
                  {!isEmpty(thread) &&
                    (userInfo.user_type == 'candidate'
                      ? thread?.job?.title
                      : thread?.candidate?.name || thread?.candidate?.designation)}
                </h5>
              </div>
              <div
                className={`col-1${
                  userInfo?.user_type == 'recruiter' ? '' : 2
                } col text-md-center text-xl-right col-md-3`}
              >
                <div
                  className={classNames(
                    'confirmation-buttons messages d-flex align-items-center',
                    { 'justify-content-around': userInfo.user_type == 'candidate' }
                  )}
                >
                  {userInfo.user_type == 'recruiter' && (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        mutate({
                          id: thread?.candidate?.id,
                          saved: savedCandidates
                            .map((i) => i.id)
                            ?.includes(thread?.candidate?.id),
                        });
                      }}
                      style={{ width: 'fit-content' }}
                      className="ml-auto align-self-start p-0"
                    >
                      {savingCandidateId !== thread?.candidate?.id ? (
                        <img
                          className="ch-icons cursor-pointer px-0 mr-2"
                          src={
                            savedCandidates
                              ?.map((i) => i.id)
                              ?.includes(thread?.candidate?.id)
                              ? savedIcon
                              : saveIcon
                          }
                        />
                      ) : (
                        <div className="ml-0 mr-2">
                          <Spinner size="sm" animation="border" role="status" />
                        </div>
                      )}
                    </div>
                  )}
                  {userInfo.user_type == 'candidate' &&
                    thread?.job?.status == 'published' &&
                    (!isEmpty(thread) && thread?.applied ? (
                      <button
                        type="button"
                        className="btn btn-primary btn-rounded text-white"
                        disabled={true}
                      >
                        Applied
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          toggleApplyJob(thread?.job, userInfo);
                          dimensions.width < 920 &&
                            history.replace(
                              constructEncodedUrl(
                                {
                                  id: thread?.job?.id,
                                  title: thread?.job?.title,
                                },
                                'apply'
                              ),
                              {
                                ...history?.location?.state,
                              }
                            );
                        }}
                        type="button"
                        className="btn btn-primary btn-rounded text-white"
                      >
                        {dimensions.width > 920 ? 'Apply' : 'Apply for the job'}
                      </button>
                    ))}
                </div>
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12">
                {userInfo.user_type == 'recruiter' && (
                  <div
                    className="pl-large d-flex align-items-center"
                    style={{ fontSize: '13px', lineHeight: '14px' }}
                  >
                    <span className="text-grey">Position:</span>
                    <Link
                      className="text-truncate cursor-pointer d-block ml-2 position-link"
                      target="_blank"
                      to={() => showDetails(true)}
                      style={{
                        color: 'black',
                        maxWidth: '75%',
                      }}
                    >
                      {!isEmpty(thread) && thread?.job?.title}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            {titleRef?.current?.offsetHeight && (
              <>
                <div
                  className="d-flex flex-column justify-content-between flex-1"
                  style={{
                    height:
                      dimensions.width < 920
                        ? 'unset'
                        : `calc(100vh - ${
                            titleRef?.current?.offsetHeight
                          }px - 45px - ${rows * 24}px)`,
                  }}
                  onClick={(e) => {
                    showPicker && setShowPicker(false);
                  }}
                >
                  <div
                    className="mesages-container py-1"
                    style={{
                      maxHeight:
                        dimensions.width < 920
                          ? 'unset'
                          : `calc(100vh - ${
                              userInfo?.user_type == 'candidate' ? 80 : 120
                            }px)`,
                    }}
                  >
                    {data &&
                      messages?.map((message, index) => (
                        <MessageRow
                          key={index}
                          jobId={jobId}
                          editMode={editMode}
                          setEditMode={setEditMode}
                          candidateId={candidateId}
                          edited_at={message?.edited_at}
                          onSaveMessage={async (data) => {
                            setRefetchLoading(true);
                            updateMessage({
                              thread_id: threadId,
                              content_type: 'text',
                              ...data,
                            });
                          }}
                          userType={userInfo.user_type}
                          from={message?.from}
                          setRefetchLoading={setRefetchLoading}
                          content={message?.content}
                          messageId={message.id}
                          name={
                            userInfo.user_type == 'recruiter'
                              ? userInfo.name
                              : data?.applied
                              ? userInfo.name || userInfo?.designation
                              : userInfo?.designation
                          }
                          userInfo={userInfo}
                          content_type={message?.content_type}
                          created_at={message?.created_at}
                          sender_id={message?.sender_id}
                          applied={thread?.applied}
                          candidate={thread?.candidate}
                          recruiter={thread?.recruiter}
                        />
                      ))}
                    <div
                      ref={(el) => {
                        if (el) {
                          (dimensions.width > 920 ||
                            ((shouldScroll || initalScroll) &&
                              dimensions.width < 920)) &&
                            !refetchLoading &&
                            el.scrollIntoView({ behavior: 'smooth' });
                          initalScroll &&
                            setTimeout(() => {
                              setInitialScroll(false);
                            }, 1000);
                        }
                      }}
                      style={{
                        height: 1,
                        width: 5,
                      }}
                    ></div>
                  </div>
                  {showPicker && (
                    <div
                      className="chat-emoji-container"
                      onClick={(e) => e.preventDefault() + e.stopPropagation()}
                    >
                      <EmojiPicker
                        onEmojiClick={(event, emojiObject) => {
                          setMsg(
                            (prev) =>
                              prev.substring(0, cursorPosition) +
                              emojiObject.emoji +
                              prev.substring(cursorPosition)
                          );
                          setTextAreaFocusKey((prev) => prev + 1);
                          setShowPicker(!showPicker);
                        }}
                      />
                    </div>
                  )}
                </div>
                <form
                  className={`message-form mt-0${
                    userInfo.user_type == 'recruiter' ? ' mb-2' : ''
                  }`}
                  onSubmit={(e) => {
                    e.preventDefault();
                    dimensions.width > 920 && send(e);
                  }}
                  onKeyPress={keyPressHandler}
                >
                  <div className="d-flex align-items-start message-input-holder position-relative">
                    <AdaptiveTextArea
                      {...{ autofocus: true }}
                      msg={msg}
                      onFocus={() =>
                        dimensions.width < 920 &&
                        shouldScroll &&
                        setShouldScroll(false)
                      }
                      onBlur={() =>
                        dimensions.width < 920 &&
                        !shouldScroll &&
                        setShouldScroll(true)
                      }
                      setCursorPosition={setCursorPosition}
                      setMsg={setMsg}
                      getRows={(data) => setRows(data)}
                      onClick={() => showPicker && setShowPicker(false)}
                      rows={rows}
                      focusKey={textAreaFocusKey}
                    />
                    {dimensions.width > 920 && (
                      <img
                        className="emoji-toggler position absolute"
                        src={smile}
                        alt="smile"
                        onClick={() => setShowPicker(!showPicker)}
                      />
                    )}
                    {dimensions.width < 920 && (
                      <SendButton
                        className="emoji-toggler position absolute"
                        style={{
                          width: 38,
                          height: 38,
                          top: 'unset',
                          bottom: 17,
                          right: 27,
                        }}
                        onClick={(e) => {
                          e.target.blur();
                          setRows(1);
                          send(e);
                        }}
                      />
                    )}
                  </div>
                </form>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

MessageDetails.propTypes = {
  applyJob: PropTypes.func,
  job: PropTypes.object,
  messages: PropTypes.array,
  sendMessages: PropTypes.func,
  setResetThreads: PropTypes.func,
  isApplied: PropTypes.bool,
  sendingMessage: PropTypes.bool,
  userInfo: PropTypes.any,
  last_message: PropTypes.any,
  candidateId: PropTypes.string,
  recruiterId: PropTypes.string,
  setCandidateId: PropTypes.func,
  setRecruiterId: PropTypes.func,
  threads: PropTypes.any,
  setThreads: PropTypes.func,
  setLast_Message: PropTypes.func,
  setShowThreads: PropTypes.func,
  initialLoading: PropTypes.any,
  setInitialLoading: PropTypes.any,
};

export default MessageDetails;
