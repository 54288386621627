import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { deleteUser } from './service';
import Loader from '../../components/loader';

export const DeleteProfileModal = ({
  deleted,
  deleteLoading,
  setDeleteLoading,
  setModalShow,
  modalShow,
  userInfo,
  setDeleted,
  onUnPublish,
}) => {
  return (
    <Modal
      backdropClassName="profile-modal"
      className="profile-modal"
      show={modalShow}
      centered
      onHide={() => setModalShow(false)}
    >
      <Modal.Body>
        {deleteLoading && <Loader className="py-4" />}
        {!deleteLoading && (
          <div className="text-left">
            <h3 className="mb-4 fw-700">
              {deleted ? (
                'We are sad to see you leave :('
              ) : (
                <>
                  {' '}
                  Delete{' '}
                  <span className="d-inline-block text-truncate">
                    {userInfo?.name}
                  </span>{' '}
                  account?
                </>
              )}
            </h3>
            <p className="mb-4">
              {deleted
                ? 'We confirm receiving the request to delete your account. You will be informed via email when this operation is complete.'
                : 'Are you sure you want to permanently delete your account and all associated data?'}
            </p>
            {userInfo?.user_type == 'candidate' &&
              !deleted &&
              userInfo?.profile_published && (
                <p className="text-grey mb-4">
                  You can always{' '}
                  <a
                    style={{ color: '#454fb4', textDecoration: 'underline' }}
                    role="button"
                    onClick={onUnPublish}
                  >
                    Unpublish
                  </a>{' '}
                  it.
                </p>
              )}
            {!deleted && (
              <p className="text-grey mb-4">Note: This action cannot be reverted.</p>
            )}
            <div className="d-flex align-items-center justify-content-between">
              {!deleted && (
                <button
                  onClick={() => setModalShow(false)}
                  className="btn btn-outline btn-primary btn-rounded px-4"
                >
                  Cancel
                </button>
              )}
              <button
                onClick={async () => {
                  if (deleted) {
                    setModalShow(false);
                    return;
                  }
                  setDeleteLoading(true);
                  let response = await deleteUser({
                    message: `ID: ${userInfo?.id}`,
                    email: userInfo?.email,
                  });

                  if (response && response?.data?.message == 'success') {
                    setDeleted(true);
                    setDeleteLoading(false);
                  } else {
                    setModalShow(false);
                  }
                }}
                className="btn btn-primary btn-rounded text-white px-4 ml-auto"
              >
                {deleted ? 'Close' : 'Delete'}
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

DeleteProfileModal.propTypes = {
  userInfo: PropTypes.any,
  deleted: PropTypes.any,
  setDeleted: PropTypes.any,
  deleteLoading: PropTypes.any,
  setDeleteLoading: PropTypes.any,
  setModalShow: PropTypes.any,
  modalShow: PropTypes.any,
  onUnPublish: PropTypes.any,
};
