import React from 'react';
import PropTypes from 'prop-types';
import ApplyJob from '../applyJob';
import { useApplyJob } from '../../context/apply-job-context';
import Loader from '../../components/loader';
import { useParams } from 'react-router-dom';
import { deconstructEncodedUrl } from '../../utils/helpers';

const ApplyJobMobile = ({ userInfo }) => {
  const { id } = useParams();
  const decompressed = deconstructEncodedUrl(id);
  const { toggleApplyJob, job } = useApplyJob();

  React.useEffect(() => {
    if (!job && decompressed?.id) toggleApplyJob(decompressed, userInfo);

    return () => {
      job && toggleApplyJob();
    };
  }, []);

  return job ? (
    <ApplyJob userInfo={userInfo} />
  ) : (
    <Loader className="messages-loader" />
  );
};

ApplyJobMobile.propTypes = {
  userInfo: PropTypes.any,
};

export default ApplyJobMobile;
