import axios from 'axios';
import newHttpClient from './utils/newHttpClient';
export const api = (method, path, data = {}) => {
  const token = localStorage.getItem('id_token');
  return axios({
    method,
    url: `https://${process.env.REACT_APP_DOMAIN}/${path}`,
    data,
    headers: {
      Authorization: token,
    },
  });
};

export const getUserProfile = async () => newHttpClient.get('/profile');
export const getJobs = async (filters) =>
  newHttpClient.post('jobs/search', {
    ...filters,
  });

export const searchCandidates = async (filters) =>
  newHttpClient.post('users/search', {
    ...filters,
  });

export const getJob = async (id) => newHttpClient.get(`/job/${id}`);

export const getCandidate = async (id) => newHttpClient.get(`/user/${id}`);

export const getAllSavedJobs = async () => newHttpClient.get('/saved/jobs');

export const getAppliedJobs = async (id) =>
  newHttpClient.get(`/user/${id}/applications`);

export const applyJob = async (id) => newHttpClient.post(`/job/${id}/apply`);

export const saveJob = async ({ id, saved }) => {
  if (saved) {
    return newHttpClient.delete(`/save/job/${id}`);
  }
  return newHttpClient.put(`/save/job/${id}`);
};

export const saveCandidate = async (candidateId) =>
  newHttpClient.put(`/save/candidate/${candidateId}`);

export const UnSaveCandidate = async (candidateId) =>
  newHttpClient.delete(`/save/candidate/${candidateId}`);

export const getSavedCandidates = async () => newHttpClient.get('/saved/candidates');

export const getAllUnreadThreads = async ({ queryKey: [_, data] }) => {
  return newHttpClient.get('/threads?unread=true');
};
export const getMinifiedThreads = async () => newHttpClient.get('/threads/minified');

export const getAllThreads = async ({ queryKey: [_, data] }) => {
  return newHttpClient.get(
    `/threads?from=${data?.from}&size=${data?.size}${
      data?.archivedOnly ? '&include_archived=true' : ''
    }`,
    { ...data }
  );
};

export const getMessages = async ({ queryKey: [_, data] }) => {
  const res = await newHttpClient.get(`/thread/${data.id}`, { ...data });
  if (res) {
    return res.data;
  } else {
    return res;
  }
};

export const createThread = async (data) =>
  newHttpClient.post(
    `/thread/job/${data.jobid}${
      data?.user_type == 'recruiter' ? `?candidate_id=${data?.candidate_id}` : ''
    }`,
    { ...(data?.user_type == 'candidate' ? data : {}) }
  );

export const createMessage = async (data) =>
  newHttpClient.post(`/thread/${data.thread_id}/message`, { content: data.content });

export const markAsRead = async (data) =>
  newHttpClient.put(`/thread/${data.id}/read`);

export const updateMessage = async (data) =>
  newHttpClient.put(`/thread/${data.thread_id}/message/${data.message_id}`, {
    content: data.content,
  });

export const markAsUnRead = async (data) =>
  newHttpClient.put(`/thread/${data.id}/unread`);

export const markAsArchived = async (data) =>
  newHttpClient.put(`/thread/${data.id}/archive`);

export const markAsUnArchived = async (data) =>
  newHttpClient.put(`/thread/${data.id}/unarchive`);
