import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { createJob } from './service';
import { toast } from 'react-toastify';
import Loader from '../../components/loader';
import { Form } from 'react-bootstrap';
import { JobForm } from './form/jobForm';
import { useForm } from 'react-hook-form';
import arrowLeft from '../../images/Icons/arrow-detail.svg';
import RichTextEditor from 'react-rte';
import { useDimensions } from '../../hooks/useDimensions';
import { urlRE } from '../../utils/constants';

export const CreateJob = ({
  onClose,
  setLoading,
  userInfo,
  isLoading,
  setRecentJobsUpdated,
  recentUpdated,
  cloneData = null,
}) => {
  const {
    formState: { errors },
    control,
    trigger,
    getValues,
  } = useForm({
    defaultValues: cloneData
      ? {
          title: 'CLONE:' + cloneData?.title,
          experience: cloneData?.experience,
          min_salary: cloneData?.min_salary,
          max_salary: cloneData?.max_salary,
          show_salary: cloneData?.show_salary,
          employment_options: cloneData?.employment_options,
          skills: cloneData?.skills,
          languages: cloneData?.languages,
          locations: cloneData?.locations,
        }
      : {
          title: '',
          experience: '',
          min_salary: '',
          max_salary: '',
          show_salary: true,
          employment_options: [],
          skills: [],
          languages: [],
          locations: [],
        },
  });

  const [description, setDescription] = React.useState(
    cloneData
      ? RichTextEditor.createValueFromString(cloneData.description, 'markdown')
      : RichTextEditor.createEmptyValue()
  );
  const [rteError, setRteError] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const dimensions = useDimensions();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(createJob, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries('get-jobs');
      setLoading(false);
      onClose(data);
      toast.success('Successfully Posted');
      if (setRecentJobsUpdated !== null) {
        setRecentJobsUpdated(recentUpdated + 1);
      }
    },
    onError: () => {
      setLoading(false);
      toast.error("Couldn't Post");
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);
    const {
      title,
      experience,
      min_salary,
      max_salary,
      show_salary,
      employment_options,
      skills,
      languages,
      locations,
    } = getValues();

    await trigger([
      'title',
      'experience',
      'min_salary',
      'max_salary',
      'skills',
      'employment_options',
      'languages',
      'locations',
    ]).then((noError) => {
      if (
        noError &&
        description?.toString('markdown')?.replaceAll('**', '')?.trim()?.length >
          2 &&
        !description
          ?.toString('markdown')
          ?.toLocaleLowerCase()
          ?.replaceAll('.js')
          ?.match(urlRE)
      ) {
        setLoading(true);
        mutate({
          title,
          experience: parseInt(experience),
          min_salary: parseInt(min_salary),
          max_salary: parseInt(max_salary),
          description: description
            ?.toString('markdown')
            ?.replaceAll(/(\*+)\s*(.*?)\s*(\*+)/g, '$1$2$3'),
          show_salary,
          employment_options,
          skills,
          languages,
          locations,
          company_website: userInfo.company_website || '',
          company_name: userInfo.company_name || '',
          qualifications: '',
          about_company: userInfo.about_company || '',
        });
      }
      if (
        !(
          description?.toString('markdown')?.replaceAll('**', '')?.trim()?.length > 2
        ) ||
        description
          ?.toString('markdown')
          ?.toLocaleLowerCase()
          ?.replaceAll('.js')
          ?.match(urlRE)
      ) {
        setRteError(true);
      }
    });
  };

  return (
    <>
      {isLoading && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 'calc( 100vh - 5rem)' }}
        >
          <Loader />
        </div>
      )}
      <div className="create-job py-3">
        {!isLoading && (
          <>
            <div className="card py-2 px-3 mb-3">
              <h2 className="mb-1">
                {dimensions.width < 920 && (
                  <img
                    src={arrowLeft}
                    onClick={(e) => {
                      e.preventDefault();
                      onClose();
                    }}
                    alt="icon"
                  />
                )}
                Job Details
              </h2>
              <p className="mb-0">
                *Please describe your position in great details to attract more
                candidates
              </p>
            </div>
            <div className="card py-2 px-3">
              <Form id="createJob" onSubmit={onSubmit}>
                <JobForm
                  submitted={submitted}
                  setSubmitted={setSubmitted}
                  description={description}
                  setDescription={setDescription}
                  control={control}
                  setRteError={setRteError}
                  rteError={rteError}
                  errors={{ ...errors, description: rteError }}
                  firsFieldLength={
                    cloneData
                      ? cloneData?.description
                          ?.toString('markdown')
                          ?.replaceAll('**', '')
                          ?.trim()
                      : ''
                  }
                />
              </Form>
            </div>
            <div
              className={`d-flex job-form-buttons align-items-center ${
                dimensions.width > 920 ? 'mt-4' : ''
              }`}
            >
              <button
                type="button"
                form="createJob"
                className="btn btn-outline btn-primary btn-rounded px-4 py-1"
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                form="createJob"
                className="btn btn-primary btn-rounded text-white px-4 py-1 ml-auto"
              >
                Create job
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

CreateJob.propTypes = {
  userInfo: PropTypes.any,
  cloneData: PropTypes.any,
  recentUpdated: PropTypes.any,
  setRecentJobsUpdated: PropTypes.any,
  onClose: PropTypes.func,
  setLoading: PropTypes.func,
  isLoading: PropTypes.bool,
};

CreateJob.defaultProps = {
  userInfo: null,
  onClose: null,
  setRecentJobsUpdated: null,
  setLoading: null,
  isLoading: false,
};
