import React from 'react';
import { useHistory } from 'react-router';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import dotMenu from '../../images/Icons/dot-menu.svg';
import { constructEncodedUrl } from '../../utils/helpers';
import { useMessageThreads } from '../../context/message-threads-context';
import ReactMarkdown from 'react-markdown';
import NoResultsFound from '../../components/NoResultsComponent';
import { useDimensions } from '../../hooks/useDimensions';

export const MyJobsList = ({ data, onAction, view, recentUpdated }) => {
  const { openThreadModal, setSelectedJobFilter, setShowApplied } =
    useMessageThreads();

  const dimensions = useDimensions();

  const [filteredJobs, setFilteredJobs] = React.useState(data);
  const [searchValue, setSearchValue] = React.useState('');

  const history = useHistory();

  const selectJob = (id, blank = false) => {
    if (blank) {
      window.open(constructEncodedUrl({ id, view, recentUpdated }, 'my-jobs'));
    } else {
      history.push(constructEncodedUrl({ id, view, recentUpdated }, 'my-jobs'));
    }
  };

  React.useEffect(() => {
    setFilteredJobs(
      data?.filter((job) =>
        job?.title?.toLowerCase().includes(searchValue?.toLowerCase())
      )
    );
  }, [searchValue]);

  return (
    <div
      className="my-jobs-list"
      {...(view ? { style: { minHeight: 'calc(100vh - 66px)' } } : {})}
    >
      {data.length > 0 && (
        <input
          onKeyPress={(e) => {
            let charCode = e.which ? e.which : e.keyCode;
            if (charCode === 13) {
              e.preventDefault();
            }
          }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className="form-control mb-4 border-3 border-grey text-light-gray f16 w-100"
          type="text"
          placeholder="Search by title"
        />
      )}
      {filteredJobs.length == 0 && (
        <NoResultsFound height={dimensions.width < 920 ? '55vh' : '50vh'} />
      )}
      {filteredJobs.length > 0 &&
        filteredJobs
          ?.sort((a, b) => moment.utc(b.updated_at).diff(moment.utc(a.updated_at)))
          ?.map((job) => (
            <div
              key={job?.id}
              className={`card position-relative ${
                moment().diff(moment(job?.created_at), 'hours') < 24 ? 'new' : ''
              }`}
              onClick={(e) => {
                if (
                  e?.target?.alt == 'dot-menu' ||
                  e?.target?.id == 'dropdown-basic'
                ) {
                  return;
                } else {
                  selectJob(job?.id);
                }
              }}
            >
              {moment().diff(moment(job?.created_at), 'hours') < 24 && (
                <span className="new-badge">New</span>
              )}
              <div className="card-header d-flex align-items-start justify-content-between">
                <div>
                  <h4 className="text-truncate">{job?.title}</h4>
                  <div className="date">
                    {moment(job?.updated_at).format('DD MMM, YYYY')}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className={classNames('badge', {
                      active: job?.status == 'published',
                    })}
                  >
                    {job?.status == 'published' ? 'Active' : 'Draft'}
                  </div>
                  <div className="d-flex align-items-center">
                    <Dropdown alignRight={false}>
                      <Dropdown.Toggle
                        as={'div'}
                        className="job py-0 px-3"
                        id="dropdown-basic"
                      >
                        <img
                          className="cursor-pointer"
                          src={dotMenu}
                          alt="dot-menu"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="py-0"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Dropdown.Item onClick={() => onAction('edit', job)}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => onAction('clone', job)}>
                          Clone
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            navigator.clipboard.writeText(
                              `${window.location.origin}${constructEncodedUrl(
                                { id: job?.id },
                                'job'
                              )}`
                            )
                          }
                        >
                          Copy link
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => onAction('togglePublish', job)}
                        >
                          {job.status == 'published' ? 'Unpublish' : 'Publish'}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => onAction('remove', job)}>
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedJobFilter([
                              { id: job?.id, title: job?.title },
                            ]);
                            setShowApplied(false);
                            history.push('/messages');
                          }}
                        >
                          Conversations
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedJobFilter([
                              { id: job?.id, title: job?.title },
                            ]);
                            setShowApplied(true);
                            history.push('/messages');
                          }}
                        >
                          Applicants
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => onAction('see-candidates', job)}
                        >
                          More candidates
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <p className="desc-title">Job description</p>
                <p className="smart-ellipse mb-0 py-1">
                  <ReactMarkdown className="markdown-container">
                    {job?.description}
                  </ReactMarkdown>
                </p>
              </div>
            </div>
          ))}
    </div>
  );
};

MyJobsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onAction: PropTypes.func,
  recentUpdated: PropTypes.any,
  view: PropTypes.string,
};
