import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Redirect } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import { InputButton } from '../../components/input-button';
import logo from '../../images/logo.png';
import logoColored from '../../images/JobTerix-full.svg';
import './sign-up.scss';
import ReactTooltip from 'react-tooltip';
import { FiInfo, FiEye, FiEyeOff } from 'react-icons/fi';
import { Button } from 'react-bootstrap';
import StyledInput from '../../components/input';
import { validateEmail, validatePassword } from '../../utils/helpers';
import { SocialSignIn } from '../../components/social';
import PropTypes from 'prop-types';
import { useAuth } from '../../context/auth-context';
import { Terms } from './terms';
import { useDimensions } from '../../hooks/useDimensions';

const SignUp = () => {
  const auth = useAuth();
  const dimensions = useDimensions();
  if (auth.isAuthenticated()) {
    return <Redirect to="/" />;
  }

  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [toValidate, setToValidate] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showAgreement, setAgreement] = useState(false);
  const [callbackForSignup, setCallback] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  let checkboxRef = React.useRef(null);

  useEffect(() => {
    if (toValidate) {
      handleSubmit();
    }
  }, [toValidate]);

  const validate = () => {
    if (toValidate) {
      let flag = true;
      let tempErrors = [...errors];

      if (!validateEmail(email)) {
        tempErrors.push('email');
        flag = false;
      } else {
        tempErrors = tempErrors.filter((x) => x !== 'email');
      }

      if (password.length > 0 && !validatePassword(password)) {
        tempErrors.push('password');
        flag = false;
      } else {
        tempErrors = tempErrors.filter((x) => x !== 'password');
      }

      if (confirmPass !== password || confirmPass == 0) {
        tempErrors.push('confirmPass');
        flag = false;
      } else {
        tempErrors = tempErrors.filter((x) => x !== 'confirmPass');
      }

      if (!checked) {
        tempErrors.push('terms');
        flag = false;
      } else {
        tempErrors = tempErrors.filter((x) => x !== 'terms');
      }

      setErrors([...tempErrors]);
      return flag;
    }
  };

  const preSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (toValidate) {
      handleSubmit();
    } else {
      setToValidate(true);
    }
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }
    try {
      await auth.signup(email, password);
    } catch (error) {
      setLoginError('This username is already taken. Please try another one.');
    }
  };

  const hasError = (field) => {
    return errors.some((x) => x === field);
  };

  useEffect(() => {
    if (
      email.length > 0 ||
      password.length > 0 ||
      confirmPass.length > 0 ||
      !checked
    ) {
      validate();
    }
  }, [email, password, confirmPass, checked]);

  if (showAgreement) {
    return <Terms continueFn={callbackForSignup} />;
  }

  return (
    <>
      <div className="d-flex flex-column flex-md-row vh-100">
        <div className="w-50 jtx-signup-img p-3 d-none d-md-block">
          <img
            src={logo}
            alt="JobTerix"
            className="cursor-pointer"
            onClick={(e) => window.open('https://jobterix.com/', '_self')}
          />
        </div>
        <div
          className="d-flex align-items-center justify-content-center d-md-none responsive-logo p-4"
          style={{ boxShadow: '0px 4px 2px rgb(0 0 0 / 5%)' }}
        >
          <img src={logoColored} alt="JobTerix" />
        </div>
        <div className="d-flex flex-column align-items-xl-start justify-content-center w-100 p-3 pt-4 p-sm-5 pt-sm-5">
          <div className="d-flex flex-column ml-0 ml-sm-4 sign-up-content">
            <h1 className="position-relative text-center text-md-left sign-up-border-bottom">
              Sign up<span className="d-none d-sm-inline"> to JobTerix</span>
            </h1>
            <p
              className="text-center text-md-left d-none d-sm-block"
              style={{ color: '#333333' }}
            >
              Login with your social network account
            </p>
            <SocialSignIn
              checkAgreement={(e) => {
                setAgreement(true);
                setCallback(() => e);
              }}
            />
            <div className="d-flex w-100 justify-content-center align-items-center mt-3 mt-sm-0">
              <div className="w-100 line"></div>
              <p
                className="mb-0 ml-3 mr-3"
                style={{ color: '#91919F', whiteSpace: 'nowrap' }}
              >
                Or with
              </p>
              <div className="w-100 line"></div>
            </div>
            <Form className="w-100 sign-up mt-3" onSubmit={preSubmit}>
              <StyledInput>
                <Form.Group className="position-relative">
                  <Form.Label className="d-none d-sm-block">Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={
                      dimensions.width > 920 ? 'Enter your email' : 'Email'
                    }
                    autoComplete="off"
                    className={`form-control ${
                      hasError('email') || loginError ? 'is-invalid' : ''
                    }`}
                    value={email}
                    onChange={(e) => {
                      if (loginError) {
                        setLoginError(!loginError);
                      }
                      setEmail(e.target.value);
                    }}
                  />
                  <label
                    className={`custom-label position-absolute${
                      email !== '' ||
                      (submitted && email.length === 0) ||
                      hasError('email') ||
                      loginError
                        ? ' show'
                        : ''
                    }`}
                  >
                    {submitted && email.length === 0
                      ? 'Email cannot be empty'
                      : hasError('email')
                      ? 'Please enter a valid email address'
                      : loginError
                      ? loginError
                      : 'Email'}
                  </label>
                  {email.length === 0 ? (
                    <Form.Control.Feedback type="invalid">
                      Email cannot be empty
                    </Form.Control.Feedback>
                  ) : (
                    hasError('email') && (
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email address
                      </Form.Control.Feedback>
                    )
                  )}
                  {loginError && dimensions.width > 576 ? (
                    <div className="custom-error">{loginError}</div>
                  ) : null}
                </Form.Group>
              </StyledInput>
              <Form.Group className="position-relative">
                <Form.Label className="d-none d-sm-block">Password</Form.Label>
                <StyledInput>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder={
                        dimensions.width > 920 ? 'Enter your password' : 'Password'
                      }
                      style={{ borderRight: 'none' }}
                      value={password}
                      autoComplete="off"
                      className={`form-control ${
                        hasError('password') ? 'is-invalid' : ''
                      } ${password.length === 0 && submitted ? 'is-invalid' : ''}`}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label
                      className={`custom-label position-absolute${
                        password !== '' ||
                        (submitted && password.length < 1) ||
                        hasError('password')
                          ? ' show'
                          : ''
                      }`}
                    >
                      {submitted && password.length < 1
                        ? 'Password cannot be empty'
                        : hasError('password')
                        ? 'Please create a strong password'
                        : 'Password'}
                    </label>
                    <div tabIndex="-1">
                      <InputGroup.Append className="d-flex">
                        <InputButton
                          position="middle"
                          isInvalid={hasError('password')}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <FiEyeOff /> : <FiEye />}
                        </InputButton>
                        <InputButton
                          position="end"
                          isInvalid={hasError('password')}
                          data-tip
                          data-for="password-info"
                        >
                          <FiInfo />
                        </InputButton>
                      </InputGroup.Append>
                    </div>
                    <ReactTooltip
                      id="password-info"
                      type="light"
                      effect="solid"
                      place="top"
                      className="tooltip-shadow"
                    >
                      <ul className="p-1 pb-0 mb-0">
                        <li>8 characters or longer</li>
                        <li>At least one number</li>
                        <li>At least one lowercase letter</li>
                        <li>At least one uppercase letter</li>
                      </ul>
                    </ReactTooltip>
                    {submitted && password.length < 1 ? (
                      <Form.Control.Feedback type="invalid">
                        Password cannot be empty
                      </Form.Control.Feedback>
                    ) : (
                      hasError('password') && (
                        <Form.Control.Feedback type="invalid">
                          Please create a strong password
                        </Form.Control.Feedback>
                      )
                    )}
                  </InputGroup>
                </StyledInput>
              </Form.Group>
              <Form.Group className="position-relative">
                <Form.Label className="d-none d-sm-block">
                  Confirm password
                </Form.Label>
                <StyledInput>
                  <InputGroup>
                    <Form.Control
                      type={showConfPassword ? 'text' : 'password'}
                      placeholder={
                        dimensions.width > 920
                          ? 'Re-enter your password'
                          : 'Confirm password'
                      }
                      style={{ borderRight: 'none' }}
                      value={confirmPass}
                      autoComplete="off"
                      className={`form-control ${
                        hasError('confirmPass') ? 'is-invalid' : ''
                      }`}
                      onChange={(e) => setConfirmPass(e.target.value)}
                    />

                    <label
                      className={`custom-label position-absolute${
                        confirmPass !== '' ||
                        (submitted && confirmPass.length < 1) ||
                        hasError('confirmPass')
                          ? ' show'
                          : ''
                      }`}
                    >
                      {submitted && confirmPass.length < 1
                        ? 'Confirm password cannot be empty'
                        : hasError('confirmPass')
                        ? 'Passwords must match'
                        : 'Confirm Password'}
                    </label>
                    <InputGroup.Append className="d-flex">
                      <InputButton
                        position="end"
                        isInvalid={hasError('confirmPass')}
                        onClick={() => setShowConfPassword(!showConfPassword)}
                      >
                        {showConfPassword ? <FiEyeOff /> : <FiEye />}
                      </InputButton>
                    </InputGroup.Append>
                    {confirmPass.length === 0 ? (
                      <Form.Control.Feedback type="invalid">
                        Password cannot be empty
                      </Form.Control.Feedback>
                    ) : (
                      hasError('confirmPass') && (
                        <Form.Control.Feedback type="invalid">
                          Passwords must match
                        </Form.Control.Feedback>
                      )
                    )}
                  </InputGroup>
                </StyledInput>
              </Form.Group>
              <Form.Group>
                <Form.Check
                  custom
                  className={`${hasError('terms') ? 'is-invalid' : ''}`}
                  type="checkbox"
                  id="consent-checkbox"
                  ref={checkboxRef}
                  label={
                    <span className="text-dark">
                      By signing up, I agree to the JobTerix&apos;s{' '}
                      <a
                        href="https://jobterix.com/legal/#privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-primary"
                      >
                        Privacy Statement
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://jobterix.com/legal/#terms"
                        className="link-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service.
                      </a>
                    </span>
                  }
                  checked={checked}
                  onChange={() => {
                    setChecked(!checked);
                    if (checked && !hasError('terms')) {
                      if (checkboxRef.current.className === 'custom-control-input') {
                        checkboxRef.current.className += ' is-invalid';
                      }
                      setErrors(errors.filter((err) => err !== 'terms'));
                    } else if (!checked && hasError('terms')) {
                      checkboxRef.current.className = 'custom-control-input';
                    }
                  }}
                />
                {hasError('terms') ? (
                  <Form.Control.Feedback className="mt-0" type="invalid">
                    You must agree to Privacy Statement and Terms of Service
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Button
                className="signup-button"
                type="submit"
                {...(dimensions?.width < 576 ? { disabled: errors.length > 0 } : {})}
                style={{
                  width: '100%',
                  color: '#fff',
                  marginTop:
                    dimensions?.width < 576
                      ? 'clamp(1rem, calc( 100vh - 720px ), calc( 100vh - 720px ))'
                      : '1rem',
                  borderRadius: dimensions?.width < 576 ? 5 : 20,
                  height: dimensions?.width < 576 ? 56 : 'auto',
                }}
              >
                {dimensions.width > 920 ? 'Join' : 'Sign up'}
              </Button>
            </Form>
            <div className="d-flex align-items-center justify-content-center w-100 mt-3">
              <p className="create-account-button">
                {dimensions.width > 920
                  ? 'Already on JobTerix?'
                  : 'Already have an account? '}{' '}
                <a className="link-primary" href="/login">
                  {dimensions.width > 920 ? 'Sign in' : ' Login'}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SignUp.propTypes = {
  auth: PropTypes.any,
};

export default SignUp;
