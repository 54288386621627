import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import swLogo from '../../images/Icons/sw-done.svg';
import sw1 from '../../images/Icons/sw-1.svg';
import sw2 from '../../images/Icons/sw-2.svg';
import sw3 from '../../images/Icons/sw-3.svg';
import sw4 from '../../images/Icons/sw-4.svg';

const getIcon = (index) => {
  switch (index) {
    case 0:
      return sw1;
    case 1:
      return sw2;
    case 2:
      return sw3;
    case 3:
      return sw4;
  }
};

const Stepper = ({ steps, currentStep }) => (
  <div className="sw-steps">
    <ul className="list-group no-bg">
      {steps.map((step, idx) => (
        <li key={idx} className="list-group-item border-0 no-bg">
          {idx < currentStep ? (
            <img className="sw-icon" src={swLogo} />
          ) : (
            <img className="sw-icon" src={getIcon(idx)} />
          )}
          <h4>{step}</h4>
        </li>
      ))}
    </ul>
  </div>
);

Stepper.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number,
};

export default Stepper;
