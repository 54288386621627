import { getUserProfile } from '../../api';

export const fetchUserProfileDetails = async ({ queryKey: [_] }) => {
  try {
    const { data } = await getUserProfile();
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
