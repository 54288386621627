import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import classNames from 'classnames';
import RichTextEditor from 'react-rte';
import {
  useEmploymentOptions,
  useGeographies,
  useLanguages,
  useSkills,
} from '../../../hooks/useEntities';
import { useDimensions } from '../../../hooks/useDimensions';
import { isIOS } from '../../../utils/helpers';
import { urlRE } from '../../../utils/constants';

export const JobForm = ({
  errors,
  control,
  firsFieldLength,
  editView = false,
  description,
  setDescription,
  setRteError,
  rteError,
  submitted,
  setSubmitted,
}) => {
  const { data: skills } = useSkills();
  const { data: languages } = useLanguages();
  const { data: empOpts } = useEmploymentOptions();
  const { data: geographies } = useGeographies();
  const dimensions = useDimensions();
  const [initialHeight] = React.useState(window?.innerHeight);
  const [addFakeHeight, setAddFakeHeight] = React.useState(false);
  const [errorState, setErrorState] = React.useState(errors);
  const [addScrollDiv, setAddScrollDiv] = React.useState(false);
  const [firstField, setFirstField] = React.useState(firsFieldLength || '');

  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
    ],
  };

  React.useEffect(() => {
    let handleKeyboard = (e) => {
      if (window.innerHeight < initialHeight - 100) {
        !addFakeHeight && setAddFakeHeight(true);
      } else {
        addFakeHeight && setAddFakeHeight(false);
      }
    };
    window.addEventListener('resize', handleKeyboard);
    return () => {
      window.removeEventListener('resize', handleKeyboard);
    };
  });

  React.useEffect(() => {
    setErrorState(errors);
  }, [
    errors?.title,
    errors?.skills,
    errors?.min_salary,
    errors?.max_salary,
    errors?.languages,
    errors?.locations,
    errors?.description,
    errors?.experience,
    errors?.employment_options,
  ]);

  const scrollToError = (el, name) => {
    if (
      el &&
      errors[Object.keys(errors)[0]] &&
      Object.keys(errors)[0] == name &&
      submitted
    ) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setSubmitted(false);
    } else {
      return;
    }
  };

  const preventCharacters = (e) => {
    let charCode = e.which ? e.which : e.keyCode;

    if (
      charCode == 69 ||
      charCode == 40 ||
      charCode == 38 ||
      charCode == 110 ||
      charCode == 107 ||
      charCode == 109 ||
      charCode == 190 ||
      charCode == 189 ||
      charCode == 187
    ) {
      e.preventDefault();
    }
  };

  const Option = (props) => {
    return (
      <components.Option {...props} className="custom-option">
        <input
          className="form-check-input me-1 f16 border-3 cursor-pointer"
          type="checkbox"
          checked={props.isSelected}
          readOnly
        />
        <span>{props.label}</span>
      </components.Option>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'title')}
          >
            <Form.Label>Position/Title</Form.Label>
            <Controller
              control={control}
              id={`title`}
              name={`title`}
              defaultValue={''}
              rules={{
                required: true,
                validate: (title) => title?.trim()?.length > 0,
              }}
              render={(params) => (
                <>
                  <input
                    type="text"
                    className={classNames('form-control', {
                      'is-invalid': errorState?.title,
                    })}
                    placeholder={
                      dimensions.width < 920 ? 'Position/Title' : `Enter title here`
                    }
                    onChange={(e) =>
                      params.field.onChange(e.target.value) +
                      setErrorState({ ...errorState, title: false })
                    }
                    defaultValue={params.field.value}
                  />
                  <label
                    className={`custom-label position-absolute${
                      params.field.value !== '' || errorState?.title ? ' show' : ''
                    }`}
                  >
                    {errorState?.title ? 'Required' : 'Position/Title'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.title) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        {dimensions.width > 920 && (
          <div className="col-sm-12 col-md-12 col-xl-12">
            <Form.Group
              className="form-group position-relative"
              ref={(el) => scrollToError(el, 'skills')}
            >
              <Form.Label>Skills</Form.Label>
              <Controller
                control={control}
                id="skills"
                name="skills"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Select
                      components={{ Option }}
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      hideSelectedOptions={false}
                      options={skills}
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) => opt.name}
                      isMulti
                      placeholder={
                        dimensions.width < 920 ? 'Skills' : 'Select skills'
                      }
                      onChange={(e) =>
                        onChange(e) + setErrorState({ ...errorState, skills: false })
                      }
                      defaultValue={value}
                      className={classNames({
                        'is-invalid mb-3': errorState?.skills,
                      })}
                      classNamePrefix="checkbox-select"
                    />
                    <label
                      className={`custom-label select position-absolute${
                        value?.length > 0 || errorState?.skills ? ' show' : ''
                      }`}
                    >
                      {errorState?.skills ? 'Required' : 'Skills'}
                    </label>
                  </>
                )}
              />
              {Boolean(errorState?.skills) && (
                <Form.Control.Feedback className="d-flex" type="invalid">
                  Required
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        )}
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'min_salary')}
          >
            <Form.Label className="d-flex align-items-center justify-content-between">
              {dimensions.width < 920 ? 'Keep salary' : 'Salary range (monthly)'}{' '}
              <div className="d-flex align-items-center">
                <Controller
                  control={control}
                  id={`show_salary`}
                  name={`show_salary`}
                  defaultValue={''}
                  render={(params) => (
                    <>
                      <label className="radio-container d-flex align-items-center mb-0">
                        <input
                          type="radio"
                          readOnly
                          className="mr-2"
                          onChange={() => params.field.onChange(false)}
                          disabled
                          checked={params.field.value == false}
                        />
                        <span className="text-truncate">Private</span>
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-container d-flex align-items-center ml-2 mb-0">
                        <input
                          type="radio"
                          readOnly
                          className="mr-2"
                          checked={params.field.value == true}
                          disabled
                          onChange={() => params.field.onChange(true)}
                        />
                        <span className="text-truncate">Public</span>
                        <span className="checkmark"></span>
                      </label>
                    </>
                  )}
                />
              </div>
            </Form.Label>
            <div className="row">
              <Controller
                control={control}
                id={`min_salary`}
                name={`min_salary`}
                defaultValue={''}
                rules={{
                  required: true,
                  min: 3,
                  validate: (value) =>
                    parseInt(value) <=
                    parseInt(control?.fieldsRef?.current?.max_salary?._f?.value),
                }}
                render={(params) => (
                  <>
                    <div className="input-group position-relative col-12 col-sm-6">
                      <div className="input-group-prepend">
                        <span
                          className={classNames('input-group-text', {
                            'is-invalid mb-3': errorState?.min_salary,
                          })}
                        >
                          $
                        </span>
                      </div>
                      <input
                        onKeyDown={preventCharacters}
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        className={classNames('form-control mb-0', {
                          'is-invalid': errorState?.min_salary,
                        })}
                        placeholder={`Min salary`}
                        onChange={(e) => {
                          params.field.onChange(e.target.value);
                          if (
                            e.target.value <
                            control?.fieldsRef?.current?.max_salary?._f?.value
                          ) {
                            setErrorState({
                              ...errorState,
                              min_salary: false,
                              max_salary: false,
                            });
                          } else {
                            setErrorState({ ...errorState, min_salary: false });
                          }
                        }}
                        defaultValue={params.field.value}
                      />
                      <label
                        className={`custom-label position-absolute${
                          params.field.value !== '' || errorState?.min_salary
                            ? ' show'
                            : ''
                        }`}
                      >
                        {errorState?.min_salary
                          ? errorState?.min_salary?.type == 'validate'
                            ? 'Higher than max salary'
                            : 'Required'
                          : 'Minimum salary'}
                      </label>
                      {Boolean(errorState?.min_salary) && (
                        <Form.Control.Feedback className="d-flex" type="invalid">
                          {errorState?.min_salary?.type == 'validate'
                            ? 'Higher than max salary'
                            : 'Required'}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </>
                )}
              />
              <Controller
                control={control}
                id={`max_salary`}
                name={`max_salary`}
                defaultValue={''}
                rules={{
                  required: true,
                  min: 3,
                  validate: (value) =>
                    parseInt(value) >=
                    parseInt(control?.fieldsRef?.current?.min_salary?._f?.value),
                }}
                render={(params) => (
                  <>
                    <div className="input-group position-relative col-12 col-sm-6">
                      <div className="input-group-prepend">
                        <span
                          className={classNames('input-group-text', {
                            'is-invalid mb-3': errorState?.max_salary,
                          })}
                        >
                          $
                        </span>
                      </div>
                      <input
                        onKeyDown={preventCharacters}
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        className={classNames('form-control mb-0', {
                          'is-invalid': errorState?.max_salary,
                        })}
                        placeholder={`Max salary`}
                        onChange={(e) => {
                          params.field.onChange(e.target.value);
                          if (
                            e.target.value >
                            control?.fieldsRef?.current?.min_salary?._f?.value
                          ) {
                            setErrorState({
                              ...errorState,
                              max_salary: false,
                              min_salary: false,
                            });
                          } else {
                            setErrorState({
                              ...errorState,
                              max_salary: false,
                            });
                          }
                        }}
                        defaultValue={params.field.value}
                      />
                      <label
                        className={`custom-label position-absolute${
                          params.field.value !== '' || errorState?.max_salary
                            ? ' show'
                            : ''
                        }`}
                      >
                        {errorState?.max_salary
                          ? errorState?.max_salary?.type == 'validate'
                            ? 'Lower than min salary'
                            : 'Required'
                          : 'Maximum salary'}
                      </label>
                      {Boolean(errorState?.max_salary) && (
                        <Form.Control.Feedback className="d-flex" type="invalid">
                          {errorState?.max_salary?.type == 'validate'
                            ? 'Lower than min salary'
                            : 'Required'}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </>
                )}
              />
            </div>
          </Form.Group>
        </div>
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'experience')}
          >
            <Form.Label>Minimum work experience (years)</Form.Label>
            <Controller
              control={control}
              id={`experience`}
              name={`experience`}
              defaultValue={''}
              rules={{
                required: true,
                min: 0,
                max: {
                  value: '99',
                  message: 'Work experience cannot be greater than 99',
                },
              }}
              render={(params) => (
                <>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={preventCharacters}
                    className={classNames('form-control', {
                      'is-invalid mb-3': errorState?.experience,
                    })}
                    placeholder={
                      dimensions.width < 920
                        ? 'Minimum work experience (years)'
                        : `Enter experience here`
                    }
                    onChange={(e) =>
                      params.field.onChange(e.target.value) +
                      setErrorState({ ...errorState, experience: false })
                    }
                    defaultValue={params.field.value}
                  />
                  <label
                    className={`custom-label position-absolute${
                      params.field.value !== '' || errorState?.experience
                        ? ' show'
                        : ''
                    }`}
                  >
                    {errorState?.experience
                      ? errorState?.experience?.message
                        ? errorState?.experience?.message
                        : 'Required'
                      : 'Minimum work experience (years)'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.experience) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                {errorState?.experience?.message
                  ? errorState?.experience?.message
                  : 'Required'}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        {dimensions.width < 920 && (
          <div className="col-sm-12 col-md-12 col-xl-12">
            <Form.Group
              className="form-group position-relative"
              ref={(el) => scrollToError(el, 'skills')}
            >
              <Form.Label>Skills</Form.Label>
              <Controller
                control={control}
                id="skills"
                name="skills"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Select
                      components={{ Option }}
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      hideSelectedOptions={false}
                      options={skills}
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) => opt.name}
                      isMulti
                      placeholder={
                        dimensions.width < 920 ? 'Skills' : 'Select skills'
                      }
                      onChange={(e) =>
                        onChange(e) + setErrorState({ ...errorState, skills: false })
                      }
                      defaultValue={value}
                      className={classNames({
                        'is-invalid mb-3': errorState?.skills,
                      })}
                      classNamePrefix="checkbox-select"
                    />
                    <label
                      className={`custom-label select position-absolute${
                        value?.length > 0 || errorState?.skills ? ' show' : ''
                      }`}
                    >
                      {errorState?.skills ? 'Required' : 'Skills'}
                    </label>
                  </>
                )}
              />
              {Boolean(errorState?.skills) && (
                <Form.Control.Feedback className="d-flex" type="invalid">
                  Required
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        )}
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'languages')}
          >
            <Form.Label>Languages</Form.Label>
            <Controller
              control={control}
              id="languages"
              name="languages"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <Select
                    components={{ Option }}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    hideSelectedOptions={false}
                    options={languages}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    isMulti
                    placeholder={
                      dimensions.width < 920 ? 'Languages' : 'Select languages'
                    }
                    onChange={(e) =>
                      onChange(e) +
                      setErrorState({ ...errorState, languages: false })
                    }
                    defaultValue={value}
                    className={classNames({
                      'is-invalid mb-3': errorState?.languages,
                    })}
                    classNamePrefix="checkbox-select"
                  />
                  <label
                    className={`custom-label select position-absolute${
                      value?.length > 0 || errorState?.languages ? ' show' : ''
                    }`}
                  >
                    {errorState?.languages ? 'Required' : 'Languages'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.languages) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'employment_options')}
          >
            <Form.Label>Employment options</Form.Label>
            <Controller
              control={control}
              id="employment_options"
              name="employment_options"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <Select
                    components={{ Option }}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    hideSelectedOptions={false}
                    options={empOpts}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    isMulti
                    placeholder={
                      dimensions.width < 920
                        ? 'Employment options'
                        : 'Select employment options'
                    }
                    onChange={(e) =>
                      onChange(e) +
                      setErrorState({ ...errorState, employment_options: false })
                    }
                    defaultValue={value}
                    maxMenuHeight="180px"
                    className={classNames({
                      'is-invalid mb-3': errorState?.employment_options,
                    })}
                    classNamePrefix="checkbox-select"
                  />
                  <label
                    className={`custom-label select position-absolute${
                      value?.length > 0 || errorState?.employment_options
                        ? ' show'
                        : ''
                    }`}
                  >
                    {errorState?.employment_options
                      ? 'Required'
                      : 'Employment options'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.employment_options) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'locations')}
          >
            <Form.Label>Preferred geographies</Form.Label>
            <Controller
              control={control}
              id="locations"
              name="locations"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <Select
                    components={{ Option }}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    hideSelectedOptions={false}
                    options={geographies}
                    maxMenuHeight="180px"
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    isMulti
                    placeholder={
                      dimensions.width < 920
                        ? 'Preferred geographies'
                        : 'Select geographies'
                    }
                    onChange={(e) =>
                      onChange(e) +
                      setErrorState({ ...errorState, locations: false })
                    }
                    defaultValue={value}
                    className={classNames({
                      'is-invalid mb-3': errorState?.locations,
                    })}
                    classNamePrefix="checkbox-select"
                  />
                  <label
                    className={`custom-label select position-absolute${
                      value?.length > 0 || errorState?.locations ? ' show' : ''
                    }`}
                  >
                    {errorState?.locations ? 'Required' : 'Preferred geographies'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.locations) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-xl-12">
          <Form.Group
            className="form-group position-relative"
            ref={(el) => scrollToError(el, 'description')}
            onFocusCapture={(e) => {
              setAddScrollDiv(true);
              e.target.parentElement.parentElement.parentElement.parentElement.classList.add(
                'orange-border'
              );
            }}
            onBlurCapture={(e) => {
              setAddScrollDiv(false);
              e.target.parentElement.parentElement.parentElement.parentElement.classList.remove(
                'orange-border'
              );
            }}
          >
            <Form.Label>Job description</Form.Label>
            <RichTextEditor
              value={description}
              toolbarConfig={toolbarConfig}
              onChange={(value) => {
                rteError && setRteError(false);
                setDescription(value);
                setErrorState({ ...errorState, description: false });
                setFirstField(value.toString('markdown')?.replaceAll('**', ''));
              }}
              placeholder={
                dimensions.width > 920 ? 'Enter value here' : 'Job description'
              }
              className={`rte-control ${
                errorState?.description ? ' is-invalid' : ' '
              }`}
            />
            {addScrollDiv && (
              <div
                ref={(el) => {
                  if (el && dimensions.width > 920)
                    el.scrollIntoView({ behavior: 'smooth' });
                }}
                style={{
                  height: 1,
                  width: 5,
                }}
              ></div>
            )}
            <label
              className={`custom-label rte-label position-absolute${
                firstField.length - 2 > 0 || errorState?.description ? ' show' : ''
              }`}
            >
              {errorState?.description
                ? `${
                    description?.toString('markdown')?.match(urlRE)
                      ? 'External links are not allowed'
                      : 'Required'
                  }`
                : 'Job description'}
            </label>
            {Boolean(errorState?.description) && (
              <Form.Control.Feedback className="d-flex pt-3" type="invalid">
                {description?.toString('markdown')?.match(urlRE)
                  ? 'External links are not allowed'
                  : 'Required'}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      {addFakeHeight && !isIOS() && dimensions.width < 920 && (
        <div style={{ height: 260 }}></div>
      )}
    </>
  );
};

JobForm.propTypes = {
  errors: PropTypes.any,
  firsFieldLength: PropTypes.any,
  editView: PropTypes.any,
  description: PropTypes.any,
  setRteError: PropTypes.any,
  rteError: PropTypes.any,
  setDescription: PropTypes.any,
  control: PropTypes.any,
  isSelected: PropTypes.any,
  label: PropTypes.any,
  submitted: PropTypes.any,
  setSubmitted: PropTypes.any,
};
