import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import './styles.scss';
import { ReactComponent as Close } from '../../images/Icons/react-select-x.svg';
import Select, { components } from 'react-select';
import {
  useEmploymentOptions,
  useGeographies,
  useSkills,
  useLanguages,
} from '../../hooks/useEntities';
import arrowLeft from '../../images/Icons/arrow-detail.svg';
import { useFilters } from '../../context/filters-context';
import { useDimensions } from '../../hooks/useDimensions';
import { useJobSort } from '../../context/job-sort-context';

const Filter = ({ view, onClearAll, setSkip, setShowFilters }) => {
  const { data: skills } = useSkills();
  const { data: employementOptions } = useEmploymentOptions();
  const { data: geographies } = useGeographies();
  const { data: languages } = useLanguages();
  const dimensions = useDimensions();
  const { loading } = useSelector((state) => state.jobs);

  const {
    clearAllFilters,
    selectedTitleFilter,
    setSelectedTitleFilter,
    selectedSkillsFilter,
    setSelectedSkillsFilter,
    selectedLanguagesFilter,
    setSelectedLanguagesFilter,
    jobTypesFilter,
    setJobTypesFilter,
    experience,
    setExperience,
    salary,
    setSalary,
    selectedGeosFilter,
    setSelectedGeosFilter,
  } = useFilters();
  const { sortBy, setSortBy, sortingOptions } = useJobSort();

  const initialFilters = {
    title: selectedTitleFilter,
    skills: selectedSkillsFilter,
    salary,
    languages: selectedLanguagesFilter,
    employment_options: jobTypesFilter,
    experience,
    sortBy: sortBy,
    locations: selectedGeosFilter,
  };
  const [params, setParams] = useState(initialFilters);
  const [selectKeys, setSelectKeys] = useState({
    languages: 1,
    skills: 2,
    locations: 3,
    employementOptions: 4,
  });

  //accordions state
  const [allOpenAccordion, setOpenAccordion] = useState([]);
  const [titleFilterValue, setTitleFilterValue] = useState(selectedTitleFilter);

  const addTitleFilter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let toUpdate = true;
    if (selectedTitleFilter) {
      toUpdate = !(selectedTitleFilter === titleFilterValue);
    }
    if (titleFilterValue && toUpdate) {
      dimensions.width > 920
        ? setSelectedTitleFilter(titleFilterValue)
        : setParams({ ...params, title: titleFilterValue });
    }
  };
  const removeTitleFilter = (e) => {
    e.stopPropagation();
    if (dimensions.width < 920) {
      setParams({ ...params, title: '' });
    } else {
      setSelectedTitleFilter(null);
      setTitleFilterValue('');
    }
  };
  const removeValue = (e, name, setState) => {
    e.stopPropagation();
    if (dimensions.width < 920) {
      if (name == 'salary') setParams({ ...params, salary: '' });
      if (name == 'experience') setParams({ ...params, experience: '' });
    } else {
      setState('');
    }
  };

  const handleSalaryChange = (e) => {
    if (e.currentTarget.value < 100000 && e.currentTarget.value >= 0) {
      dimensions.width > 920
        ? setSalary(e.currentTarget.value)
        : setParams({ ...params, salary: e.currentTarget.value });
    } else if (e.currentTarget.value >= 100000) {
      dimensions.width > 920
        ? setSalary(100000)
        : setParams({ ...params, salary: 100000 });
    }
    setSkip(0);
  };

  const handleExperienceChange = (e) => {
    if (e.target.value <= 99 && e.target.value >= 0) {
      dimensions.width > 920
        ? setExperience(e.target.value)
        : setParams({ ...params, experience: e.target.value });
    } else if (e.target.value === '') {
      dimensions.width > 920
        ? setExperience(0)
        : setParams({ ...params, experience: 0 });
    }
    setSkip(0);
  };

  const preventCharacters = (e) => {
    let charCode = e.which ? e.which : e.keyCode;

    if (
      charCode == 69 ||
      charCode == 40 ||
      charCode == 38 ||
      charCode == 110 ||
      charCode == 107 ||
      charCode == 109 ||
      charCode == 190 ||
      charCode == 189 ||
      charCode == 187
    ) {
      e.preventDefault();
    }
  };

  const Option = (props) => {
    return (
      <components.Option {...props} className="custom-option">
        <input
          className="form-check-input me-1 f16 border-3 cursor-pointer"
          type="checkbox"
          checked={props.isSelected}
          readOnly
        />
        <span>{props.label}</span>
      </components.Option>
    );
  };

  return (
    <>
      <div className="card-body pb-0">
        <div
          className={`row card-border-bottom${!view && ' mb-0'}`}
          {...(!view ? { style: { borderBottom: 'none' } } : {})}
        >
          <div className="col d-flex align-items-center">
            {dimensions.width < 920 && (
              <img
                src={arrowLeft}
                onClick={(e) => setShowFilters(false)}
                alt="arrow"
              />
            )}
            <h5
              className={`card-title pl-3${dimensions.width < 920 ? ' mb-0' : ''}`}
            >
              Filter By
            </h5>
          </div>
          <div className="col text-right">
            <button
              onClick={() => {
                dimensions.width > 920 && clearAllFilters();
                setTitleFilterValue('');
                setParams({
                  title: null,
                  skills: [],
                  salary: '',
                  languages: [],
                  employment_options: [],
                  experience: '',
                  locations: [],
                  sortBy: { name: 'Date', value: 'date' },
                });

                setSelectKeys({
                  languages: selectKeys.languages + 1,
                  skills: selectKeys.skills + 1,
                  locations: selectKeys.locations + 1,
                  employementOptions: selectKeys.employementOptions + 1,
                });

                onClearAll && onClearAll();
              }}
              disabled={
                dimensions.width < 920
                  ? !(
                      params.title ||
                      params.skills.length > 0 ||
                      params.languages.length > 0 ||
                      params.employment_options.length > 0 ||
                      params.locations.length > 0 ||
                      params.experience ||
                      params.salary
                    )
                  : view
                  ? !(
                      selectedTitleFilter ||
                      selectedSkillsFilter.length > 0 ||
                      selectedLanguagesFilter.length > 0 ||
                      jobTypesFilter.length > 0 ||
                      selectedGeosFilter.length > 0 ||
                      experience ||
                      salary
                    )
                  : true
              }
              className={`btn button1 ${
                view &&
                (selectedTitleFilter ||
                  selectedSkillsFilter.length > 0 ||
                  selectedLanguagesFilter.length > 0 ||
                  jobTypesFilter.length > 0 ||
                  selectedGeosFilter.length > 0 ||
                  experience ||
                  salary)
                  ? 'btn-primary'
                  : 'btn-light'
              } sidebarBtn`}
            >
              Clear All
            </button>
          </div>
        </div>
      </div>

      {view && (
        <div className="accordion-container">
          <div className="accordion-controller">
            <div className="card">
              <div className="card-body">
                <div className="accordion-padding">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                      <div className="col-xs-12 col-md-12 col-lg-12 text-right p-0 d-flex flex-row justify-content-end align-items-start">
                        <span className="position-relative w-100">
                          <form onSubmit={!loading ? addTitleFilter : () => {}}>
                            <input
                              onBlur={!loading ? addTitleFilter : () => {}}
                              className="form-control input-custom mb-0 border-3 fs-14-ph"
                              type="text"
                              onChange={(e) => {
                                if (e.currentTarget.value == '') {
                                  removeTitleFilter(e);
                                }
                                if (dimensions.width < 920) {
                                  setParams({
                                    ...params,
                                    title: e.currentTarget.value,
                                  });
                                } else {
                                  setTitleFilterValue(e.currentTarget.value);
                                }
                                setSkip(0);
                              }}
                              value={
                                dimensions.width > 920
                                  ? titleFilterValue
                                  : params.title
                              }
                              placeholder="Search by title/positions"
                            />
                            {((dimensions.width < 920 && params.title) ||
                              (dimensions.width > 920 && selectedTitleFilter)) && (
                              <div
                                onClick={removeTitleFilter}
                                className="dropdown-arrow r-10"
                              >
                                <Close />
                              </div>
                            )}
                          </form>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xl-12"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="accordion-padding">
                  <div className="row">
                    <div className="col  col-xs-12">
                      <div className="position-relative mb-0">
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="form-control input-custom border-3 mb-0 fs-14-ph"
                          min={0}
                          max={100}
                          pattern="\d*"
                          placeholder="Min. work experience (years)"
                          id="input-right"
                          onKeyDown={preventCharacters}
                          value={
                            dimensions.width > 920 ? experience : params.experience
                          }
                          onChange={handleExperienceChange}
                        />
                        {((dimensions.width < 920 && params.experience) ||
                          (dimensions.width > 920 && experience)) && (
                          <div
                            onClick={(e) =>
                              removeValue(e, 'experience', setExperience)
                            }
                            className="dropdown-arrow r-10"
                          >
                            <Close />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="accordion-padding">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                      <Select
                        components={{ Option }}
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        hideSelectedOptions={false}
                        options={skills}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.name}
                        isMulti
                        placeholder="Skills"
                        key={selectKeys.skills}
                        onChange={(data) => {
                          dimensions.width > 920
                            ? setSelectedSkillsFilter(data)
                            : setParams({ ...params, skills: data });
                          setSkip(0);
                        }}
                        defaultValue={
                          dimensions.width > 920
                            ? selectedSkillsFilter
                            : params.skills
                        }
                        classNamePrefix="checkbox-select-filter-box"
                        className="checkbox-select-filter-box"
                        defaultMenuIsOpen={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="accordion-padding">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                      <Select
                        components={{ Option }}
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        hideSelectedOptions={false}
                        options={languages}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.name}
                        isMulti
                        placeholder="Languages"
                        key={selectKeys.languages}
                        onChange={(data) => {
                          dimensions.width > 920
                            ? setSelectedLanguagesFilter(data)
                            : setParams({ ...params, languages: data });
                          setSkip(0);
                        }}
                        defaultValue={
                          dimensions.width > 920
                            ? selectedLanguagesFilter
                            : params.languages
                        }
                        classNamePrefix="checkbox-select-filter-box"
                        className="checkbox-select-filter-box"
                        defaultMenuIsOpen={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="accordion-padding">
                  <div className="row">
                    <div className="col  col-xs-12">
                      <div className="position-relative mb-0">
                        <input
                          placeholder="Monthly salary in USD"
                          type="number"
                          className="form-control input-custom border-3 mb-0 fs-14-ph"
                          id="input-right"
                          onWheel={(e) => e.target.blur()}
                          value={dimensions.width > 920 ? salary : params.salary}
                          pattern="\d*"
                          onKeyDown={preventCharacters}
                          onChange={handleSalaryChange}
                        />
                        {((dimensions.width < 920 && params.salary) ||
                          (dimensions.width > 920 && salary)) && (
                          <div
                            onClick={(e) => removeValue(e, 'salary', setSalary)}
                            className="dropdown-arrow r-10"
                          >
                            <Close />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="accordion-padding">
                  <div className="row">
                    <div className={`col-sm-12 col-md-12 col-xl-12`}>
                      <Select
                        components={{ Option }}
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        hideSelectedOptions={false}
                        options={employementOptions}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.name}
                        isMulti
                        key={selectKeys.employementOptions}
                        placeholder="Employment options"
                        onChange={(data) => {
                          dimensions.width > 920
                            ? setJobTypesFilter(data)
                            : setParams({ ...params, employment_options: data });
                          setSkip(0);
                        }}
                        defaultValue={
                          dimensions.width > 920
                            ? jobTypesFilter
                            : params.employment_options
                        }
                        classNamePrefix="checkbox-select-filter-box"
                        className="checkbox-select-filter-box"
                        defaultMenuIsOpen={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`card no-border ${dimensions.width < 920 ? '' : 'pb-4'}`}
            >
              <div className="card-body">
                <div className="accordion-padding">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                      <Select
                        components={{ Option }}
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        hideSelectedOptions={false}
                        options={geographies}
                        getOptionValue={(opt) => opt.id}
                        getOptionLabel={(opt) => opt.name}
                        isMulti
                        key={selectKeys.locations}
                        placeholder="Preferred geographies"
                        onChange={(data) => {
                          dimensions.width > 920
                            ? setSelectedGeosFilter(data)
                            : setParams({ ...params, locations: data });
                          setSkip(0);
                        }}
                        defaultValue={
                          dimensions.width > 920
                            ? selectedGeosFilter
                            : params.locations
                        }
                        classNamePrefix="checkbox-select-filter-box"
                        className="checkbox-select-filter-box"
                        defaultMenuIsOpen={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {dimensions.width < 920 && (
        <div className="filter-buttons bg-white d-flex justify-content-end border-top-0">
          <button
            onClick={(e) => setShowFilters(false)}
            className="btn btn-outline btn-primary btn-rounded mr-2"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-rounded"
            disabled={JSON?.stringify(initialFilters) === JSON?.stringify(params)}
            onClick={(e) => {
              setSelectedTitleFilter(params.title);
              setSelectedSkillsFilter(params.skills);
              setSelectedLanguagesFilter(params.languages);
              setJobTypesFilter(params.employment_options);
              setExperience(params.experience);
              setSalary(params.salary);
              setSelectedGeosFilter(params.locations);
              sortBy?.value !== params.sortBy?.value && setSortBy(params.sortBy);
              setShowFilters(true);
            }}
          >
            Apply
          </button>
        </div>
      )}
    </>
  );
};

Filter.propTypes = {
  getAppliedFilters: PropTypes.any,
  view: PropTypes.bool,
  onClearAll: PropTypes.func,
  isSelected: PropTypes.any,
  label: PropTypes.any,
  setSkip: PropTypes.func,
  setShowFilters: PropTypes.func,
};

export default Filter;
