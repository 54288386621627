import React from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import Threads from '../threads';
import { deconstructEncodedUrl } from '../../utils/helpers';
import { useMessageThreads } from '../../context/message-threads-context';

const MobileThread = ({ userInfo }) => {
  const { id } = useParams();
  const {
    setThreadId,
    setJobId,
    jobId,
    threadId,
    jobData,
    setJobData,
    candidateData,
    setCandidateData,
  } = useMessageThreads();
  const decompressed = deconstructEncodedUrl(id);

  React.useEffect(() => {
    if (!threadId && decompressed?.thread_id) setThreadId(decompressed?.thread_id);
    if (!jobId && decompressed?.job_id) setJobId(decompressed?.job_id);
    if (!jobData && decompressed?.jobData) setJobData(decompressed?.jobData);
    if (!candidateData && decompressed?.candidateData)
      setCandidateData(decompressed?.candidateData);

    return () => {
      if (jobData) setJobData(null);
      if (candidateData) setCandidateData(null);
    };
  }, []);

  return <Threads userInfo={userInfo} />;
};

MobileThread.propTypes = {
  userInfo: PropTypes.any,
  props: PropTypes.any,
  data: PropTypes.any,
};

export default MobileThread;
