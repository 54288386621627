import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../images/jobTerix-logo-white.svg';
import downArrow from '../../images/Icons/down-arrow-white.svg';
import profileIcon from '../../images/Icons/profile-mobile.svg';
import exitIcon from '../../images/Icons/exit-icon.svg';
import { Accordion } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/auth-context';

const SideBar = ({
  userDetails,
  setSideBarOpened,
  sideBarOpened,
  setOpenedFrom,
}) => {
  const history = useHistory();
  const auth = useAuth();

  const [accordionIsOpen, setAccordionIsOpen] = React.useState(false);
  const [myJobsaccordionIsOpen, setMyJobsAccordionIsOpen] = React.useState(false);
  const [profileAccordionIsOpen, setProfileAccordionIsOpen] = React.useState(false);

  const onRouteChange = (route) => {
    history.push(route);
    setSideBarOpened(false);
  };

  const logout = () => {
    auth.logout();
  };

  return (
    <div className={`sidebarHolder${sideBarOpened ? ' sideBarOpened' : ''}`}>
      <img src={logo} alt="logo" />
      <div className="sidebar-links d-flex flex-column align-items-start justify-content-between">
        <div className="w-100">
          <Accordion
            style={{ borderColor: 'transparent' }}
            className="accordion-controller"
          >
            <Accordion.Toggle
              eventKey="0"
              id="0"
              className={`sidebar-link d-flex align-items-center${
                history?.location?.pathname?.includes('/jobs') ||
                history?.location?.pathname?.includes('/candidates')
                  ? ' active'
                  : ''
              }`}
              onClick={() => setAccordionIsOpen(!accordionIsOpen)}
              data-toggle="collapse"
              aria-expanded="true"
            >
              <span>
                {userDetails.user_type == 'candidate' ? 'Jobs' : 'Candidates'}
              </span>
              <img
                className="ml-auto"
                src={downArrow}
                alt="icon"
                {...(accordionIsOpen
                  ? { style: { transform: 'rotate(-180deg)' } }
                  : {})}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div className="links-holder">
                {userDetails.user_type == 'recruiter' && (
                  <>
                    <div
                      onClick={(e) => onRouteChange('/candidates')}
                      className={
                        history?.location?.pathname == '/candidates' ? 'active' : ''
                      }
                    >
                      All Candidates
                    </div>
                    <div
                      onClick={(e) => onRouteChange('/candidates/saved')}
                      className={
                        history?.location?.pathname == '/candidates/saved'
                          ? 'active'
                          : ''
                      }
                    >
                      Saved Candidates
                    </div>
                  </>
                )}
                {userDetails.user_type == 'candidate' && (
                  <>
                    <div
                      onClick={(e) => onRouteChange('/jobs')}
                      className={
                        history?.location?.pathname == '/jobs' ? 'active' : ''
                      }
                    >
                      All jobs
                    </div>
                    <div
                      onClick={(e) => onRouteChange('/jobs/saved')}
                      className={
                        history?.location?.pathname == '/jobs/saved' ? 'active' : ''
                      }
                    >
                      Saved jobs
                    </div>
                    <div
                      onClick={(e) => onRouteChange('/jobs/applied')}
                      className={
                        history?.location?.pathname == '/jobs/applied'
                          ? 'active'
                          : ''
                      }
                    >
                      Applied jobs
                    </div>
                  </>
                )}
              </div>
            </Accordion.Collapse>
            {userDetails.user_type == 'recruiter' && (
              <>
                <Accordion.Toggle
                  eventKey="1"
                  id="1"
                  className={`sidebar-link d-flex align-items-center${
                    history?.location?.pathname?.includes('/my-jobs')
                      ? ' active'
                      : ''
                  }`}
                  style={{ marginTop: 17 }}
                  onClick={() => setMyJobsAccordionIsOpen(!myJobsaccordionIsOpen)}
                  data-toggle="collapse"
                  aria-expanded="true"
                >
                  <span>My jobs</span>
                  <img
                    className="ml-auto"
                    src={downArrow}
                    alt="icon"
                    {...(myJobsaccordionIsOpen
                      ? { style: { transform: 'rotate(-180deg)' } }
                      : {})}
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <div className="links-holder">
                    {userDetails.user_type == 'recruiter' && (
                      <>
                        <div
                          onClick={(e) => {
                            history.push('/my-jobs', { view: 'recent' });
                            setSideBarOpened(false);
                          }}
                          className={
                            history?.location?.pathname == '/my-jobs' &&
                            history?.location?.state?.view == 'recent'
                              ? 'active'
                              : ''
                          }
                        >
                          Published jobs
                        </div>
                        <div
                          onClick={(e) => {
                            history.push('/my-jobs', { view: 'draft' });
                            setSideBarOpened(false);
                          }}
                          className={
                            history?.location?.pathname == '/my-jobs' &&
                            history?.location?.state?.view == 'draft'
                              ? 'active'
                              : ''
                          }
                        >
                          Drafts
                        </div>
                      </>
                    )}
                  </div>
                </Accordion.Collapse>
              </>
            )}
          </Accordion>
          <div
            className={`sidebar-link${
              history?.location?.pathname == '/messages' ? ' active' : ''
            }`}
            onClick={(e) => onRouteChange('/messages')}
          >
            Messages
          </div>
          <div
            className="sidebar-link"
            onClick={() => {
              setTimeout(() => {
                window.open(
                  'mailto:info@jobterix.com?subject="Support request"',
                  '_self'
                );
              }, 100);
            }}
          >
            Help
          </div>
        </div>
        <div className="w-100">
          <div
            className="sidebar-link d-flex align-items-center"
            onClick={(e) => {
              setSideBarOpened(false);
              history.push('/profile', {
                returnTo: history.location?.pathname,
                ...history?.location?.state,
              });
              setOpenedFrom('sidebar');
            }}
          >
            <img className="mr-2 pb-1" src={profileIcon} alt="icon" />
            <span>Profile</span>
          </div>
          <div className="sidebar-link d-flex align-items-center" onClick={logout}>
            <img className="mr-2 pb-1" src={exitIcon} alt="icon" />
            <span>Sign out</span>
          </div>
        </div>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  userDetails: PropTypes.any,
  setSideBarOpened: PropTypes.any,
  sideBarOpened: PropTypes.any,
  setOpenedFrom: PropTypes.any,
};

export default SideBar;
