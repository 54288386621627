import { JobSortContext } from './job-sort-context';
import PropTypes from 'prop-types';
import { useState } from 'react';

export const JobSortProvider = ({ children }) => {
  const sortingOptions = [
    { name: 'Date', value: 'date' },
    { name: 'Relevance', value: '' },
  ];
  const [sortBy, setSortBy] = useState(sortingOptions[0]);

  return (
    <JobSortContext.Provider
      value={{
        sortBy,
        setSortBy,
        sortingOptions,
      }}
    >
      {children}
    </JobSortContext.Provider>
  );
};

JobSortProvider.propTypes = {
  children: PropTypes.any,
};
