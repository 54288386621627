import React from 'react';
import logo from '../../images/JobTerix-full.svg';
import Loader from '../loader';
import { useHistory, useParams } from 'react-router';
import { deconstructEncodedUrl } from '../../utils/helpers';
import { getJob } from './service';
import { useQuery } from 'react-query';
import JobDetailCard from '../job-detail-card';
import PageNotFound from '../404Card';
import { CustomHelmet } from '../common/SEO';
import { useDimensions } from '../../hooks/useDimensions';

const LoggedOutJobDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const decompressed = deconstructEncodedUrl(id);
  const [unAvailablePost, setUnAvailablePost] = React.useState(false);

  const dimensions = useDimensions();

  React.useEffect(() => {
    if (decompressed?.id && window) {
      window.localStorage.setItem('visit', history.location.pathname);
    }
  }, []);

  const {
    data: job,
    isLoading: jobLoading,
    isFetching,
  } = useQuery(['fetch-job', { id: decompressed?.id }], getJob, {
    refetchOnMount: false,
    enabled: decompressed?.id ? true : false,
    onSuccess: (data) => {
      if (data?.status == 'unpublished' || data?.status == 'deleted') {
        setUnAvailablePost(true);
      }
    },
    onError: () => {
      setUnAvailablePost(true);
    },
    refetchOnWindowFocus: false,
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.toString()?.includes('Job not found')) {
        return false;
      }
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  return (
    <>
      {job && (
        <CustomHelmet
          pageTitle={job?.title || 'JobTerix'}
          title={job?.title || 'JobTerix'}
          description={job?.description?.slice(0, 155)}
        >
          <meta
            name="keywords"
            content={job?.skills?.map((skill) => skill?.name)?.join(', ')}
          />
        </CustomHelmet>
      )}
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container-lg">
          <a className="navbar-brand" href="/">
            <img src={logo} />
          </a>
          <div
            className="btn btn-primary btn-rounded text-white px-4 py-0"
            onClick={() => history.push('/login')}
          >
            Log in
          </div>
        </div>
      </nav>
      <div className="main-container">
        <section className="s-padding pb-0">
          <div className="container-lg dashboard">
            <div className="w-100 d-flex justify-content-start">
              {unAvailablePost && (
                <div className="card w-100 text-left p-4">
                  <h4 className="bb">About Job</h4>
                  <p className="mb-0 text-grey">
                    This position is no longer available
                  </p>
                </div>
              )}
              {decompressed?.id == undefined && <PageNotFound />}
              {!unAvailablePost &&
                decompressed?.id &&
                (jobLoading ? (
                  <div className="detail-loader">
                    <Loader />
                  </div>
                ) : (
                  <div
                    className={`w-100 ${dimensions.width > 920 ? 'cx-padding' : ''}`}
                  >
                    {jobLoading && (
                      <div className="d-flex flex-column h-100">
                        <Loader className="h-100" />
                      </div>
                    )}
                    {!jobLoading && (
                      <JobDetailCard
                        userInfo={{ user_type: 'candidate' }}
                        job={job}
                        showClipBoard={false}
                        showActions={false}
                        applied={false}
                        loggedOut
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default LoggedOutJobDetail;
