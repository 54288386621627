import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDimensions } from '../../hooks/useDimensions';

const AdaptiveTextArea = ({
  msg,
  setMsg,
  rows,
  getRows,
  focusKey,
  maxRowsNumb,
  className,
  onClick,
  placeholder,
  setCursorPosition,
  style,
  onFocus,
  onBlur,
}) => {
  const dimensions = useDimensions();
  const [row, setRow] = useState(rows);
  const textAreaRef = useRef();
  const maxRows = maxRowsNumb ? maxRowsNumb : 10,
    minRows = rows;

  const focusOnTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  React.useEffect(() => {
    setCursorPosition &&
      dimensions.width > 920 &&
      setCursorPosition(textAreaRef?.current?.selectionStart);
  }, [textAreaRef?.current?.selectionStart]);

  React.useEffect(() => {
    !placeholder && focusOnTextArea();
  }, [focusKey]);

  const handleChange = (event) => {
    event.preventDefault();
    const textareaLineHeight = 24;

    const previousRows = event.target.rows;
    event.target.rows = minRows;

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }
    getRows && getRows(currentRows < maxRows ? currentRows : maxRows);
    if (placeholder) {
      setRow(
        currentRows > rows ? (currentRows < maxRows ? currentRows : maxRows) : rows
      );
    } else {
      setRow(currentRows < maxRows ? currentRows : maxRows);
    }
    setMsg(event.target.value);
  };

  React.useEffect(() => {
    rows && setRow(rows);
  }, [rows]);

  return (
    <textarea
      rows={row}
      onClick={onClick}
      value={msg}
      onTouchStart={(e) => e.preventDefault()}
      ref={textAreaRef}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder ? placeholder : 'Send a message'}
      className={`form-control message-input mb-2 ${className}`}
      onChange={handleChange}
      {...(dimensions.width < 920 && row > 2
        ? { style: { paddingBottom: '12px', ...style } }
        : {})}
      {...(style ? { ...style } : {})}
    />
  );
};

AdaptiveTextArea.propTypes = {
  msg: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  setMsg: PropTypes.func,
  onClick: PropTypes.func,
  rows: PropTypes.number,
  maxRowsNumb: PropTypes.number,
  style: PropTypes.any,
  setCursorPosition: PropTypes.any,
  focusKey: PropTypes.number,
  getRows: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
export default AdaptiveTextArea;
