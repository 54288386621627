import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import AllJobsList from './allJobs';
import SavedJobsList from './savedJobs';
import AppliedJobsList from './appliedJobs';
import { useMessageThreads } from '../../context/message-threads-context';

const SearchCandidatePage = (props) => {
  const { userInfo } = props;
  const { contactedUsers } = useMessageThreads();

  let { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={path}
        exact
        component={() => (
          <AllJobsList
            contactedJobs={contactedUsers?.map((i) => i.job_id)}
            userInfo={userInfo}
          />
        )}
      />
      <Route
        path={`${path}/saved`}
        component={() => (
          <SavedJobsList
            contactedJobs={contactedUsers?.map((i) => i.job_id)}
            userInfo={userInfo}
          />
        )}
      />
      <Route
        path={`${path}/applied`}
        component={() => (
          <AppliedJobsList
            contactedJobs={contactedUsers?.map((i) => i.job_id)}
            userInfo={userInfo}
          />
        )}
      />
    </Switch>
  );
};

SearchCandidatePage.propTypes = {
  userInfo: PropTypes.any,
};

SearchCandidatePage.defaultProps = {
  userInfo: null,
};

export default SearchCandidatePage;
