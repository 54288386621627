import React, { useState } from 'react';
import { MessageThreadsContext } from './message-threads-context';
import PropTypes from 'prop-types';
import { useDimensions } from '../hooks/useDimensions';

export const MessageThreadsProvider = ({ children }) => {
  const dimensions = useDimensions();
  const [showThreads, setShowThreads] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [selectedJobFilter, setSelectedJobFilter] = useState([]);
  const [jobData, setJobData] = useState(null);
  const [candidateData, setCandidateData] = useState(null);
  const [showApplied, setShowApplied] = useState(false);
  const [mobileLastMessage, setMobileLastMessage] = useState(null);
  const [mobileThreads, setMobileThreads] = useState(null);
  const [archivedOnly, setArchivedOnly] = React.useState(false);
  const [candidateId, setCandidateId] = useState(null);
  const [recruiterId, setRecruiterId] = useState(null);
  const [threadId, setThreadId] = useState(null);
  const [contactedUsers, setContactedUsers] = useState([]);

  const openThreadModal = (id = null, threadId = null) => {
    setJobId(id);
    setThreadId(threadId);
    setShowThreads(true);
  };

  const closeThreadModal = () => {
    setShowThreads(false);
    setJobId(null);
    setCandidateId(null);
    dimensions.width > 920 && setShowApplied(false);
    dimensions.width > 920 && setArchivedOnly(false);
    dimensions.width > 920 && setSelectedJobFilter([]);
    setRecruiterId(null);
    dimensions.width > 920 && setCandidateData(null);
    setThreadId(null);
    dimensions.width > 920 && setJobData(null);
  };

  return (
    <MessageThreadsContext.Provider
      value={{
        jobId,
        setJobId,
        showThreads,
        openThreadModal,
        closeThreadModal,
        candidateId,
        recruiterId,
        setCandidateId,
        setRecruiterId,
        selectedJobFilter,
        setSelectedJobFilter,
        candidateData,
        setCandidateData,
        threadId,
        setThreadId,
        showApplied,
        setShowApplied,
        jobData,
        setJobData,
        contactedUsers,
        setContactedUsers,
        archivedOnly,
        setArchivedOnly,
        mobileThreads,
        setMobileThreads,
        mobileLastMessage,
        setMobileLastMessage,
      }}
    >
      {children}
    </MessageThreadsContext.Provider>
  );
};

MessageThreadsProvider.propTypes = {
  children: PropTypes.any,
};
