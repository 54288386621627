import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../dashboard/styles.scss';
import './styles.scss';
import ThreadsForm from './threadsSearch';
import slideClose from '../../images/Icons/slide-close.svg';
import Loader from '../../components/loader';
import Overlay from '../../components/common/modal';
import { InvitationPage } from './invitation-page';
import { getJobs } from '../myJobs/service';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { deleteUser } from '../profile/service';
import { toast } from 'react-toastify';
import { useMessageThreads } from '../../context/message-threads-context';
import { constructEncodedUrl, isCandidate, isRecruiter } from '../../utils/helpers';
import Thread from './thread';
import ContentLoader from 'react-content-loader';
import MessageDetails from './message-details-card';
import PropTypes from 'prop-types';
import NoResultsFound from '../../components/NoResultsComponent';
import { useAppliedJobs } from '../../hooks/useAppliedJobs';
import {
  getAllThreads,
  createThread,
  createMessage,
  markAsRead,
  markAsUnRead,
  markAsArchived,
  markAsUnArchived,
} from '../../api';

import { escape } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDimensions } from '../../hooks/useDimensions';

const Threads = (props) => {
  const { userInfo } = props;
  const location = useLocation();
  const {
    jobId,
    selectedJobFilter,
    setJobId,
    closeThreadModal,
    candidateId,
    recruiterId,
    setCandidateId,
    setRecruiterId,
    candidateData,
    setThreadId,
    threadId,
    showApplied,
    jobData,
    contactedUsers,
    setContactedUsers,
    archivedOnly,
    mobileThreads,
    setMobileThreads,
  } = useMessageThreads();
  /**
   * Below applied jobs logic needs to go
   */
  const { appliedJobs } = useAppliedJobs(userInfo.user_type, userInfo?.id);
  const dimensions = useDimensions();
  const appliedJobsIds = appliedJobs ? appliedJobs?.map((j) => j.id) : [];
  const client = useQueryClient();

  const [from, setFrom] = React.useState(0);
  const size = 30;
  const [threads, setThreads] = useState([]);
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [showInvitationPage, setShowInvitationPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showUnread, setShowUnread] = React.useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showPlaceHolder, setShowPlaceHolder] = React.useState(false);
  const [last_message, setLast_Message] = React.useState(null);
  const [scrollHeight, setScrollHeight] = React.useState(0);
  const [showCandidateTag, setShowCandidateTag] = React.useState(null);
  const [resetThreads, setResetThreads] = useState(false);
  const {
    data,
    isLoading: gqlLoading,
    refetch: loadMessages,
  } = useQuery(['allThreads', { archivedOnly, from, size }], getAllThreads, {
    onSuccess: (data) => {
      loading && setLoading(false);
      data?.data?.length < size && setHasMore(false);
      if (threads && !resetThreads) {
        setThreads([...threads, ...data?.data]);
      } else {
        setThreads(data?.data);
        resetThreads && setResetThreads(false);
      }
    },
    cacheTime: 0,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate: mutateCreateThread } = useMutation(createThread, {
    onSuccess: async (data) => {
      const {
        data: {
          thread: { id, job },
        },
      } = data;
      sendMessage({
        thread_id: id,
        job_id: job.id,
        content: escape(messageContent),
      });
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate: sendMessage } = useMutation(createMessage, {
    onSuccess: async (data, variables) => {
      setLoading(false);
      !threadId && setThreadId(variables?.thread_id);
      setJobId(variables?.job_id);
      showInvitationPage && setShowInvitationPage(false);
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate: markThreadUnread } = useMutation(markAsUnRead, {
    onSuccess: async (data, variables) => {
      if (contactedUsers?.map((thread) => thread?.id)?.includes(variables.id)) {
        setContactedUsers(
          contactedUsers?.map((thread) => {
            if (thread?.id == variables.id) {
              return { ...thread, unread: true };
            }
            return thread;
          })
        );
      } else {
        setContactedUsers([...contactedUsers, { ...variables, unread: true }]);
      }
      setThreads(
        threads?.map((thread) => {
          if (thread?.id == variables.id) {
            return { ...thread, unread: true };
          }
          return thread;
        })
      );
      setLoading(false);
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate: markThreadRead } = useMutation(markAsRead, {
    onSuccess: async (data, variables) => {
      if (contactedUsers?.map((thread) => thread?.id)?.includes(variables.id)) {
        setContactedUsers(
          contactedUsers?.map((thread) => {
            if (thread?.id == variables.id) {
              return { ...thread, unread: null };
            }
            return thread;
          })
        );
      } else {
        setContactedUsers([...contactedUsers, { ...variables, unread: null }]);
      }
      setThreads(
        threads?.map((thread) => {
          if (thread?.id == variables.id) {
            return { ...thread, unread: null };
          }
          return thread;
        })
      );
      setLoading(false);
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate: archiveThread } = useMutation(markAsArchived, {
    onSuccess: async (data, variables) => {
      if (archivedOnly) {
        setThreads(
          threads?.map((thread) => {
            if (thread?.id == variables?.id) {
              return {
                ...thread,
                archived: true,
              };
            }
            return thread;
          })
        );
      } else {
        setThreads(threads?.filter((thread) => thread?.id !== variables?.id));
      }

      setLoading(false);
      toast.success('Archived Succesfully');
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate: unarchiveThread } = useMutation(markAsUnArchived, {
    onSuccess: async (data, variables) => {
      setThreads(threads?.filter((thread) => thread?.id !== variables?.id));
      setLoading(false);
      toast.success('Unarchived Succesfully');
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const filterThreads = (threads) => {
    const selectedJobIds = selectedJobFilter.map((job) => job.id);
    let filteredThreads = threads;
    const lowerCaseSearchValue = searchFilterValue.toLowerCase();
    if (selectedJobIds.length > 0) {
      filteredThreads = filteredThreads.filter((thread) =>
        selectedJobIds.includes(thread?.job?.id)
      );
    }
    if (showApplied) {
      filteredThreads = filteredThreads.filter((thread) => thread?.applied === true);
    }
    if (showUnread) {
      filteredThreads = filteredThreads.filter((thread) => thread?.unread === true);
    }
    if (showCandidateTag) {
      filteredThreads = filteredThreads.filter(
        (thread) => thread?.candidate?.id === showCandidateTag?.id
      );
    }
    if (searchFilterValue) {
      if (isCandidate(userInfo.user_type)) {
        filteredThreads = filteredThreads.filter((thread) =>
          thread?.job?.title?.toLowerCase()?.includes(lowerCaseSearchValue)
        );
      } else if (isRecruiter(userInfo.user_type)) {
        filteredThreads = filteredThreads.filter((thread) => {
          if (thread?.applied) {
            if (thread?.candidate?.name) {
              return thread?.candidate?.name
                ?.toLowerCase()
                ?.includes(lowerCaseSearchValue);
            }
          }
          return thread?.candidate?.designation
            ?.toLowerCase()
            ?.includes(lowerCaseSearchValue);
        });
      }
    }

    return filteredThreads;
  };

  useEffect(() => {
    if (
      !candidateData?.showInvitation &&
      !jobId &&
      !threadId &&
      !(
        candidateData &&
        contactedUsers.filter((item) => item.candidate_id === candidateId)?.length ==
          1
      ) &&
      !jobData &&
      !location?.pathname?.includes('/thread')
    ) {
      loadMessages({
        archivedOnly,
        from,
        size,
      });
    }
  }, []);

  useEffect(() => {
    !threadId &&
      !candidateData &&
      !jobId &&
      dimensions.width > 920 &&
      loadMessages({
        from,
        size,
        archivedOnly,
      });
  }, [from]);

  useEffect(() => {
    if (resetThreads && dimensions.width > 920) {
      from == 0
        ? loadMessages({
            from,
            size,
            archivedOnly,
          })
        : setFrom(0);
      setHasMore(true);
    }
  }, [resetThreads]);

  useEffect(() => {
    if (dimensions.width > 920 && !threadId && !jobId && !candidateData) {
      from == 0
        ? loadMessages({
            from,
            size,
            archivedOnly,
          })
        : setFrom(0);
      setHasMore(true);
    }
  }, [archivedOnly]);

  useEffect(() => {
    if ((jobId != null && threadId == null) || candidateData) {
      if (contactedUsers) {
        /**
         * Also filter by candidate ID
         */
        let threads = contactedUsers;
        let thread;
        if (isRecruiter(userInfo.user_type)) {
          if (
            threads.filter((item) => item.candidate_id === candidateId)?.length >
              1 ||
            (threads.filter((item) => item.candidate_id === candidateId)?.length ==
              1 &&
              candidateData?.showInvitation)
          ) {
            if (candidateData?.showInvitation) {
              setShowInvitationPage(true);
            } else {
              !showCandidateTag &&
                setShowCandidateTag({
                  designation: candidateData.designation,
                  id: candidateData.id,
                });
            }
          } else {
            [thread] = threads.filter((item) => item.candidate_id === candidateId);
          }
        } else {
          [thread] = threads.filter((item) => item.job_id === jobId);
        }
        if (thread) {
          !threadId && setThreadId(thread.id);
          setJobId(thread.job_id);
        } else {
          if (
            isRecruiter(userInfo.user_type) &&
            threads.filter((item) => item.candidate_id === candidateId)?.length > 1
          )
            return;

          setShowInvitationPage(true);
        }
      }
    }
  }, [jobId, contactedUsers]);

  const { data: myJobs, isLoading } = useQuery(
    ['get-jobs', { id: userInfo?.id }],
    getJobs,
    {
      refetchOnWindowFocus: false,
      enabled:
        isRecruiter(userInfo.user_type) &&
        (showInvitationPage || dimensions.width > 920),
      useErrorBoundary: (error) => {
        return error.response?.status === 401;
      },
      retry: (count, error) => {
        if (error.response?.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  const showDetails = (id) => {
    if (isRecruiter(userInfo.user_type) && id) {
      return constructEncodedUrl({ id }, 'job');
    }
    if (candidateData) {
      window.open(constructEncodedUrl({ id: candidateId }, 'candidate'));
    } else if (jobData) {
      window.open(constructEncodedUrl({ id: jobData?.id }, 'job'));
    }
  };

  const sendInvite = async (messageContent, selectedJobId, selectedCandidateId) => {
    setLoading(true);
    try {
      mutateCreateThread({
        jobid: selectedJobId,
        candidate_id: selectedCandidateId,
        user_type: userInfo?.user_type,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const setMessageInfo = (thread) => {
    setJobId(thread.job.id);
    setCandidateId(thread.candidate.id);
    setRecruiterId(thread.recruiter.id);
    setThreadId(thread.id);
    setLast_Message(thread?.last_message?.content);
  };

  const onReport = async (thread_id) => {
    setLoading(true);
    let response = await deleteUser({
      subject: `Thread Reported By User`,
      message: `Thread ID: ${thread_id}`,
    });

    if (response && response?.data?.message == 'success') {
      setLoading(false);
      toast.success('Reported Succesfully');
    }
  };

  const onMarkUnread = (thread_id, unread) => {
    setLoading(true);
    if (unread) {
      markThreadRead({
        id: thread_id,
      });
    } else {
      markThreadUnread({
        id: thread_id,
      });
    }
  };

  const threadProps = (thread) => {
    let props = {
      title: '',
      lastMessage: '',
      locations: '',
      experience: '',
      employmentOptions: '',
      salary: '',
    };
    const { job, candidate } = thread;
    if (isCandidate(userInfo.user_type)) {
      props = {
        ...props,
        title: job.title,
        jobTitle: '',
        lastMessage: {
          ...thread?.last_message,
          sender_name:
            thread?.last_message?.sender_id == userInfo?.id
              ? 'You: '
              : thread?.recruiter?.name + ': ',
        },
      };
    } else if (isRecruiter(userInfo.user_type)) {
      props = {
        ...props,
        title: candidate.name || candidate.designation,
        status: job.status,
        jobTitle: job.title,
        lastMessage: {
          ...thread?.last_message,
          sender_name:
            thread?.last_message?.sender_id == userInfo?.id
              ? 'You: '
              : 'Candidate: ',
        },
      };
    }

    return props;
  };

  return (
    <Overlay
      close={() => {
        if (threadId) {
          client.invalidateQueries('minified-threads');
        }
        closeThreadModal();
      }}
    >
      {((!showInvitationPage &&
        (candidateData || jobData) &&
        gqlLoading &&
        !threadId) ||
        ((!candidateData || !jobData) && gqlLoading) ||
        loading) && <Loader className="messages-loader" />}
      {(candidateData || jobData) && !threadId && showInvitationPage && (
        <InvitationPage
          userInfo={userInfo}
          messageContent={messageContent}
          setMessageContent={setMessageContent}
          candidateId={candidateId}
          closeThreadModal={closeThreadModal}
          sendInvite={sendInvite}
          showDetails={showDetails}
          loading={loading}
          setLoading={setLoading}
          myJobs={myJobs}
          isLoading={isLoading}
          contactedUsers={contactedUsers}
          candidateData={
            isRecruiter(userInfo.user_type)
              ? {
                  ...candidateData,
                  employmentOptions: candidateData.employment_options,
                }
              : jobData
          }
        />
      )}
      {jobId === null &&
        (candidateData == null ||
          (candidateData &&
            contactedUsers.filter((item) => item.candidate_id === candidateId)
              ?.length > 1 &&
            !candidateData?.showInvitation)) &&
        !location.pathname?.includes('/thread') && (
          <div className="slide-container right-slide bg-white">
            <span
              onClick={() => closeThreadModal()}
              style={{ zIndex: 111 }}
              className="slide-close"
            >
              <img src={slideClose} className="cursor-pointer" />
            </span>
            <div className="row">
              <div className="col-md-12 col-xl-12">
                <h5 className="slide-header bb d-inline">My Messages</h5>
                <p
                  className={`f14 mt-2 mb-2${
                    isCandidate(userInfo?.user_type) ? ' text-grey' : ''
                  }`}
                >
                  Message Center allows quick access to all your conversations.
                </p>
              </div>
            </div>
            <ThreadsForm
              userInfo={userInfo}
              myJobs={myJobs}
              showUnread={showUnread}
              showCandidateTag={showCandidateTag}
              setShowCandidateTag={setShowCandidateTag}
              setShowUnread={setShowUnread}
              setResetThreads={setResetThreads}
              searchFilterValue={searchFilterValue}
              setSearchFilterValue={setSearchFilterValue}
              setShowPlaceHolder={setShowPlaceHolder}
              setLoading={setLoading}
            />
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {showPlaceHolder && (
                  <ContentLoader
                    speed={2}
                    width={500}
                    height={200}
                    viewBox="0 0 800 200"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="22" y="13" rx="3" ry="3" width="604" height="20" />
                    <rect x="23" y="53" rx="3" ry="3" width="604" height="20" />
                    <rect x="26" y="95" rx="3" ry="3" width="127" height="20" />
                    <rect x="160" y="95" rx="3" ry="3" width="127" height="20" />
                    <rect x="25" y="134" rx="3" ry="3" width="604" height="20" />
                  </ContentLoader>
                )}
                <div
                  className={`thread-container${
                    isRecruiter(userInfo.user_type) ? ' large' : ''
                  }`}
                  id="thread-container"
                >
                  {!gqlLoading &&
                    !showPlaceHolder &&
                    !loading &&
                    filterThreads(threads)?.length == 0 && <NoResultsFound />}
                  <InfiniteScroll
                    dataLength={threads?.length}
                    onScroll={(e) => setScrollHeight(e?.target?.scrollTop)}
                    initialScrollY={!resetThreads && scrollHeight}
                    next={() => setFrom(from + size)}
                    loader={
                      selectedJobFilter?.length == 0 &&
                      searchFilterValue == '' &&
                      !showApplied &&
                      !archivedOnly &&
                      !showUnread &&
                      threads?.length !== 0 ? (
                        <p>Loading..</p>
                      ) : (
                        ''
                      )
                    }
                    hasMore={hasMore}
                    endMessage={<p className="text-secondary"></p>}
                    scrollableTarget="thread-container"
                  >
                    {!showPlaceHolder &&
                      filterThreads(threads).map((thread, index) => {
                        if (thread == null) {
                          return;
                        }
                        return (
                          <Thread
                            key={index}
                            candidateId={thread.candidate.id}
                            jobId={thread.job.id}
                            showDetails={showDetails}
                            archived={thread.archived}
                            unread={thread.unread}
                            onArchive={async () => {
                              setLoading(true);
                              await archiveThread({
                                id: thread.id,
                              });
                            }}
                            onUnarchive={async () => {
                              setLoading(true);
                              await unarchiveThread({
                                id: thread.id,
                              });
                            }}
                            onMark={() => onMarkUnread(thread.id, thread.unread)}
                            onReport={() => onReport(thread.id)}
                            candidateView={isCandidate(userInfo.user_type)}
                            applied={thread?.applied}
                            companyName={thread.job.company_name}
                            {...threadProps(thread)}
                            updatedAt={thread?.updated_at || thread?.created_at}
                            onClick={() => {
                              setMessageInfo(thread);
                            }}
                          />
                        );
                      })}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        )}
      {jobId !== null && threadId !== null && (
        <MessageDetails
          userInfo={userInfo}
          isApplied={appliedJobsIds.includes(jobId)}
          candidateId={candidateId}
          recruiterId={recruiterId}
          last_message={last_message}
          initialLoading={loading}
          setInitialLoading={setLoading}
          threads={
            location?.pathname?.includes('/thread') && dimensions.width < 920
              ? mobileThreads
              : threads
          }
          setThreads={
            location?.pathname?.includes('/thread') && dimensions.width < 920
              ? setMobileThreads
              : setThreads
          }
          setLast_Message={setLast_Message}
          setResetThreads={setResetThreads}
          setCandidateId={setCandidateId}
          setRecruiterId={setRecruiterId}
        />
      )}
    </Overlay>
  );
};

Threads.propTypes = {
  userInfo: PropTypes.any,
  props: PropTypes.any,
  data: PropTypes.any,
};
export default Threads;
