import React, { useState } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import Loader from '../../components/loader';
import classNames from 'classnames';
import upload from '../../images/Icons/upload.svg';
import uploadMobile from '../../images/Icons/uploadMobile.svg';
import pngIcon from '../../images/Icons/pngIcon.svg';
import { toast } from 'react-toastify';
import slideClose from '../../images/Icons/close-black.svg';
import closeMobile from '../../images/Icons/closeMobile.svg';
import { updateUser } from '../profile/service';

import { useMessageThreads } from '../../context/message-threads-context';
import { useLists } from '../../context/lists-context';
import { useDimensions } from '../../hooks/useDimensions';

import { useApplyJob } from '../../context/apply-job-context';
import { useQueryClient, useMutation } from 'react-query';
import { applyJob } from '../../api';
import { allowedTypes } from '../../utils/constants';
import { constructEncodedUrl } from '../../utils/helpers';
import { useHistory } from 'react-router-dom';

/* eslint-disable react/prop-types */

const ApplyJob = (props) => {
  const {
    toggleApplyJob,
    job: { id },
    showWarning,
    setShowMessages,
    setShowApplyJob,
  } = useApplyJob();
  const history = useHistory();
  const { userInfo } = props;
  const dimensions = useDimensions();
  const { openThreadModal } = useMessageThreads();
  const [dragOver, setDragOver] = useState(false);
  const [update, setUpdate] = React.useState(false);
  const [fileName, setFileName] = useState(null);
  const [firstName, setFirstName] = useState(userInfo?.name || '');
  const [file, setFile] = useState(null);
  const [updatedName, setUpdatedName] = useState(false);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(false);
  const [minHeight, setMinHeight] = React.useState(window?.innerHeight);
  const queryClient = useQueryClient();
  const { setIsAppliedJobsInvalidated } = useLists();

  const { mutate: apply } = useMutation(applyJob, {
    onSuccess: async (data, variables) => {
      if (dimensions.width < 920) {
        history.replace(
          constructEncodedUrl(
            {
              thread_id: data.data.id,
              job_id: id,
            },
            'thread'
          ),
          {
            ...(history?.location?.state?.previousFrom
              ? {
                  from: history?.location?.state?.previousFrom,
                  skip: history?.location?.state?.skip,
                }
              : {
                  from: history?.location?.state
                    ? history?.location?.state?.from
                    : '',
                }),
          }
        );
      } else {
        openThreadModal(id, data.data.id);
      }
      await queryClient.invalidateQueries('get-auth0-profile');
      setIsAppliedJobsInvalidated(true);
      await queryClient.invalidateQueries('fetch-applied-jobs');
      setIsAppliedJobsInvalidated(false);
      setShowMessages(true);
    },
    useErrorBoundary: (error) => {
      return error.response?.status === 401;
    },
    retry: (count, error) => {
      if (error.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate } = useMutation(updateUser, {
    onMutate: (data) => {
      setUpdateProfileLoading(true);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries('get-auth0-profile');
      toast.success('Profile Updated Succesfully');
      onSubmit();
    },
    useErrorBoundary: (error) => {
      return error.response?.status === 401;
    },
    retry: (count, error) => {
      if (error.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onSubmit = (e) => {
    setShowMessages(false);
    setShowApplyJob(false);
    openThreadModal(id);
    apply(id);
    if (e) {
      e.preventDefault();
    }
  };

  const onProfileUpdate = async () => {
    let resume = null;
    if (file) {
      resume = await toBase64(file[0]);
    }
    let params = {};
    if (resume) params.resume_doc = resume;
    if (firstName) {
      params.name = firstName?.trim();
    }

    if ((!showWarning && !resume) || (userInfo?.name == '' && !firstName)) {
      return;
    }

    mutate({
      ...userInfo,
      ...params,
    });
  };

  const handleResumeUpload = (e) => {
    if (allowedTypes.candidate.resume.indexOf(e.target?.files[0]?.type) > -1) {
      e.target?.files[0]?.name && setFileName(e.target.files[0].name);
      setFile(e.target.files);
      update && setUpdate(false);
    } else {
      toast.error('only .pdf format is allowed');
    }
  };

  React.useEffect(() => {
    if (dimensions.width < 920 && minHeight - window?.innerHeight < 150) {
      setMinHeight(window?.innerHeight);
    }
  }, [window?.innerHeight]);

  return (
    <div
      className="popup-form overflow-auto w-100 h-100 d-flex flex-column justify-content-center align-items-center"
      {...(dimensions.width < 920 ? { style: { minHeight } } : {})}
    >
      <div className="content-holder rounded position-relative bg-white px-4 py-3">
        {dimensions.width < 920 && (
          <div className="tabs">
            <h2>Applying for job</h2>
          </div>
        )}
        {updateProfileLoading && (
          <div className="card border-0">
            <div className="card-body justify-content-center d-flex flex-column align-items-center">
              <h3 className="mb-4">Updating Profile</h3>
              <Loader />
            </div>
          </div>
        )}
        {!updateProfileLoading && (
          <React.Fragment>
            <p className="mb-3 note">
              {showWarning &&
                (userInfo?.name == '' || !firstName
                  ? 'To apply to this position you need to provide your full name'
                  : 'Are you sure you want to apply to this position?')}
              {!showWarning &&
                `To apply to this position you need to upload your resume${
                  userInfo?.name == '' || (updatedName && !firstName)
                    ? ' and provide your fullname'
                    : ''
                }`}
            </p>
            {dimensions.width > 920 && (
              <div className="w-100 d-flex align-items-center justify-content-between">
                <input
                  className="form-control w-100 mr-2"
                  placeholder="Full Name"
                  value={firstName}
                  autoFocus
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    if (userInfo?.name !== '') {
                      setUpdatedName(true);
                    }
                  }}
                />
              </div>
            )}
            <div
              onDrop={() => {
                setDragOver(false);
              }}
              onDragEnter={() => {
                setDragOver(true);
              }}
              onDragLeave={() => {
                setDragOver(false);
              }}
              style={{ padding: '1rem 4rem' }}
              className={classNames(
                'dash-border position-relative orange-border d-flex flex-column justify-content-center align-items-center',
                { 'drag-over': dragOver }
              )}
            >
              {!update && (userInfo?.resume_url || fileName) && (
                <div
                  className="d-flex download-cv-holder align-items-center position-relative overflow-hidden border-grey"
                  style={{
                    zIndex: 5,
                    cursor: fileName == null ? 'pointer' : 'default',
                    borderRadius: '0.5rem',
                    width: '14rem',
                  }}
                  onClick={() => {
                    if (fileName == null) {
                      window.open(userInfo?.resume_url, '_blank');
                    }
                  }}
                >
                  <div className="download-cv position-absolute d-flex align-items-center justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#666666"
                      stroke="#666666"
                      strokeWidth="1"
                      className="bi bi-download mr-2  text-grey"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                    <span className="d-block bb text-grey">Download</span>
                  </div>
                  {dimensions.width > 920 && (
                    <span
                      className="d-block w-25 p-4 bb d-flex align-items-center justify-content-center text-white"
                      style={{ backgroundColor: '#cc1016', letterSpacing: '1px' }}
                    >
                      PDF
                    </span>
                  )}
                  {dimensions.width < 920 && <img src={pngIcon} alt="icon" />}
                  <span className="d-block ml-3">
                    {fileName || userInfo?.resume_name || 'Resume'}
                  </span>
                  <img
                    className="close-icon ml-auto p-2 mr-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setFile(null);
                      setFileName(null);
                      setUpdate(true);
                    }}
                    src={dimensions.width < 920 ? closeMobile : slideClose}
                    alt="close"
                  />
                </div>
              )}
              {((showWarning && !userInfo?.resume_url) ||
                (!fileName && !showWarning) ||
                update) && (
                <React.Fragment>
                  <img src={dimensions.width < 920 ? uploadMobile : upload} />
                  <div className="m-0">
                    <div>
                      <input
                        className="upload-zone"
                        id="resume"
                        name="resume"
                        accept=".pdf"
                        type="file"
                        onChange={handleResumeUpload}
                        defaultValue={file}
                      />
                    </div>
                    {dimensions.width < 920 && <span>Click here to </span>}
                    <a style={{ zIndex: '11111' }} href="#">
                      Upload your resume
                    </a>{' '}
                    {dimensions.width > 920 && 'or drag and drop it here'}
                  </div>
                  <p className="m-0">(only .pdf format is allowed)</p>
                </React.Fragment>
              )}
            </div>
            {dimensions.width < 920 && (
              <div className="w-100 d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap">
                <label className="form-label">Full Name</label>
                <input
                  className="form-control w-100 w-xl-50 mr-xl-2"
                  placeholder="Full Name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    if (userInfo?.name !== '') {
                      setUpdatedName(true);
                    }
                  }}
                />
              </div>
            )}
            {dimensions.width > 920 && (
              <div
                className={`d-flex justify-content-between apply-job-buttons${
                  dimensions.width > 920 ? ' mt-4 mb-2' : ''
                }`}
              >
                <button
                  className={`btn btn-outline btn-primary btn-rounded px-4 py-1 ${
                    dimensions.width > 920 ? 'mx-1' : 'mr-1'
                  }`}
                  onClick={() => toggleApplyJob()}
                >
                  Cancel
                </button>
                <button
                  disabled={
                    (!showWarning && !(file && firstName)) ||
                    (showWarning && update && !file) ||
                    ((userInfo?.name == '' || updatedName) && !firstName)
                  }
                  className="btn btn-primary btn-rounded px-4 py-1 text-white"
                  onClick={(e) => {
                    if (showWarning) {
                      if (
                        !fileName &&
                        !update &&
                        userInfo?.name !== '' &&
                        !updatedName
                      ) {
                        onSubmit(e);
                      } else {
                        onProfileUpdate();
                      }
                    } else if (!showWarning && file) {
                      onProfileUpdate();
                    }
                  }}
                  id="applyJobButton"
                >
                  Apply
                </button>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      {dimensions.width < 920 && (
        <div
          className={`d-flex justify-content-between apply-job-buttons${
            dimensions.width > 920 ? ' mt-4 mb-2' : ''
          }`}
        >
          <button
            className={`btn btn-outline btn-primary btn-rounded px-4 py-1 ${
              dimensions.width > 920 ? 'mx-1' : 'mr-1'
            }`}
            onClick={() => {
              history.go(-1);
            }}
          >
            Cancel
          </button>
          <button
            disabled={
              (!showWarning && !(file && firstName)) ||
              (showWarning && update && !file) ||
              ((userInfo?.name == '' || updatedName) && !firstName)
            }
            className="btn btn-primary btn-rounded px-4 py-1 text-white"
            onClick={(e) => {
              if (showWarning) {
                if (!fileName && !update && userInfo?.name !== '' && !updatedName) {
                  onSubmit(e);
                } else {
                  onProfileUpdate();
                }
              } else if (!showWarning && file) {
                onProfileUpdate();
              }
            }}
            id="applyJobButton"
          >
            Apply
          </button>
        </div>
      )}
    </div>
  );
};
ApplyJob.propTypes = {
  close: PropTypes.func,
  job: PropTypes.object,
};
export default ApplyJob;
