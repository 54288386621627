import { useQuery } from 'react-query';
import http from '../utils/newHttpClient';
import { orderByName } from '../utils/helpers';

const getSkills = async () => {
  const { data } = await http.get(`/references/skills`);
  return orderByName(data, 'name');
};

const getEmploymentOptions = async () => {
  const { data } = await http.get(`/references/employmentoptions`);
  return data;
};

const getLanguages = async () => {
  const { data } = await http.get(`/references/langauges`);
  return orderByName(data, 'name');
};

const getGeographies = async () => {
  const { data } = await http.get(`/references/locations`);
  const globalNameIndex = data.map((i) => i.name.toLowerCase()).indexOf('global');
  return data.slice(globalNameIndex, 1).concat(
    orderByName(
      data.filter((i, index) => index != globalNameIndex),
      'name'
    )
  );
};

export function useSkills() {
  return useQuery('skills', getSkills, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });
}

export function useEmploymentOptions() {
  return useQuery('employmentOptions', getEmploymentOptions, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });
}

export function useLanguages() {
  return useQuery('languages', getLanguages, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });
}

export function useGeographies() {
  return useQuery('geographies', getGeographies, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });
}
