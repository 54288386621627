import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useHistory, Redirect } from 'react-router';
import Loader from '../../components/loader';
import Filter from '../dashboard/filter';
import arrowLeft from '../../images/Icons/arrow-detail.svg';
import Gauge from '../dashboard/gauge';
import { Helmet } from 'react-helmet';
import { toggleSaveCandidate } from '../search-candidates/service';
import { constructEncodedUrl } from '../../utils/helpers';
import CandidateList from '../dashboard/candidatelist';
import { useSavedCandidates } from '../../hooks/useSavedCandidates';
import { useDimensions } from '../../hooks/useDimensions';
import NoResultsFound from '../../components/NoResultsComponent';

const SavedCandidatesList = (props) => {
  const { userInfo, contactedCandidates } = props;

  if (userInfo?.user_type == 'candidate') {
    return <Redirect to="/jobs" />;
  }

  const history = useHistory();
  const dimensions = useDimensions();
  const [skip] = useState(history.location.state?.skip || 0);
  const [limit] = useState(5);
  const [savingCandidateId, setSavingCandidateId] = useState(null);

  const {
    candidates: savedCandidates,
    isLoading: isLoadingSavedCandidates,
    refetch: refetchSavedCandidates,
    isFetching: isFetchingSavedCandidates,
  } = useSavedCandidates(true);

  useEffect(() => {
    if (!isFetchingSavedCandidates) {
      if (savingCandidateId !== null) {
        setSavingCandidateId(null);
      }
    }
  }, [isFetchingSavedCandidates]);

  const { mutate } = useMutation(toggleSaveCandidate, {
    onSettled: () => {
      refetchSavedCandidates();
    },
    onMutate: ({ id }) => setSavingCandidateId(id),
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const selectCandidate = (id, key, returnLink = false) => {
    const filters = { from: skip, size: limit };

    if (returnLink) {
      return constructEncodedUrl(
        { id, filters, view: dimensions.width < 920 ? 'saved' : '' },
        dimensions.width < 920 ? 'candidate' : 'candidate/saved'
      );
    }
    history.push(
      constructEncodedUrl(
        { id, filters, view: dimensions.width < 920 ? 'saved' : '' },
        dimensions.width < 920 ? 'candidate' : 'candidate/saved'
      )
    );
  };

  const paginationArray = [
    ...Array(parseInt(Math.ceil(savedCandidates?.length / limit) || 0)).keys(),
  ];

  const renderPagination = () => {
    let pages = paginationArray;

    if (pages.length > 5) {
      if (skip <= 2 * limit) {
        pages = pages.slice(0, 4).concat(['...', pages[pages.length - 1]]);
      } else if (skip > 2 * limit && skip < limit * (pages.length - 2)) {
        pages = [
          pages[0],
          '...',
          skip / limit - 1,
          skip / limit,
          skip / limit + 1,
          '...',
          pages[pages.length - 1],
        ];
      } else if (skip > limit * (pages.length - 3)) {
        pages = [
          pages[0],
          '...',
          pages[pages.length - 4],
          pages[pages.length - 3],
          pages[pages.length - 2],
          pages[pages.length - 1],
        ];
      }
    }

    return (
      <>
        {pages.length > 5 && (
          <button
            className="btn py-1 d-flex align-items-center justify-content-center"
            style={{ width: 'min-content' }}
            onClick={() => onPaginate(skip / limit - 1)}
          >
            <span className="chevron left"></span>
          </button>
        )}
        {pages.map((item, key) => {
          return (
            <button
              className={classNames(
                'btn px-3 py-1 d-flex align-items-center justify-content-center',
                {
                  'bg-primary text-white': item == skip / limit,
                  'cursor-default': item === '...',
                  'mr-2': key !== pages.length - 1,
                }
              )}
              key={key}
              onClick={() => (item !== '...' ? onPaginate(item) : null)}
            >
              <span className="d-block">{item !== '...' ? item + 1 : item}</span>
            </button>
          );
        })}
        {pages.length > 5 && (
          <button
            className="btn py-1 d-flex align-items-center justify-content-center"
            style={{ width: 'min-content' }}
            onClick={() => onPaginate(skip / limit + 1)}
          >
            <span className="chevron right"></span>
          </button>
        )}
      </>
    );
  };

  const onPaginate = (page) => {
    if (
      page == skip / limit ||
      page < 0 ||
      page > paginationArray[paginationArray.length - 1]
    ) {
      return;
    }
    window && window.scrollTo(0, 0);
    history.push('/candidates/saved', {
      skip: limit * page,
    });
  };

  React.useEffect(() => {
    if (savedCandidates) {
      if (savedCandidates?.length <= skip) {
        onPaginate((skip - limit) / 5);
      }
    }
  }, [savedCandidates]);

  return (
    <>
      <Helmet>
        <title>JobTerix - Saved candidates</title>
      </Helmet>
      {dimensions.width > 920 && (
        <div className="w-320px">
          <div className="row sticky-header">
            <div className="col-xs-12 col-md-12 col-xl-12">
              <div className="card sticky-header">
                <Filter view={false} onClearAll={() => onPaginate(0)} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={dimensions.width <= 920 ? 'w-100' : 'w-75 cx-padding'}>
        <div className="row">
          <div className="col-xs-12 col-md-12 col-xl-12 jobs-list applied">
            {dimensions.width > 920 && (
              <Gauge
                recruiter
                view="saved"
                title={'Candidates'}
                appliedFilters={null}
                listLength={savedCandidates && savedCandidates?.length}
                showSearchaAndSortOptions={false}
              />
            )}
            {dimensions.width < 920 && (
              <div className="jobs-applied-mobile-nav d-flex align-items-center">
                <img
                  src={arrowLeft}
                  onClick={(e) => {
                    history.push('/candidates');
                  }}
                  alt="arrow"
                />
                <h5 className="mb-0 ml-4">Saved candidates</h5>
              </div>
            )}
            {isLoadingSavedCandidates && <Loader className="mt-3" />}
            {!isLoadingSavedCandidates && (
              <>
                <CandidateList
                  contactedCandidates={contactedCandidates}
                  data={
                    savedCandidates && savedCandidates?.slice(skip, skip + limit)
                  }
                  showRemoveText
                  userInfo={userInfo}
                  to={selectCandidate}
                  onSelect={(id, key) => selectCandidate(id, key)}
                  saveCandidate={mutate}
                  savingCandidateId={savingCandidateId}
                  savedCandidates={
                    (!isLoadingSavedCandidates &&
                      savedCandidates?.map((c) => c.id)) ||
                    []
                  }
                />
                {savedCandidates?.length == 0 && (
                  <NoResultsFound
                    height={dimensions.width < 920 ? '77vh' : '50vh'}
                  />
                )}
              </>
            )}
            {savedCandidates && (
              <div className="pagination d-flex align-items-center justify-content-center mb-4">
                {savedCandidates?.length !== 0 &&
                  !(savedCandidates?.length <= limit) &&
                  savedCandidates?.length !== limit &&
                  renderPagination()}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

SavedCandidatesList.propTypes = {
  userInfo: PropTypes.any,
  contactedCandidates: PropTypes.any,
};

SavedCandidatesList.defaultProps = {
  userInfo: null,
};

export default SavedCandidatesList;
