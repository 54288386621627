import { createContext, useContext } from 'react';

export const MessageThreadsContext = createContext(undefined);

export const useMessageThreads = () => {
  const context = useContext(MessageThreadsContext);

  if (context === undefined) {
    throw new Error("useMessageThreads can only be used inside MessageThreadsProvider");
  }

  return context;
}