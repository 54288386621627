/* eslint-disable no-prototype-builtins */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Threads from '../threads';
import MobileThread from '../mobileThread';
import './styles.scss';
import ApplyJob from '../applyJob';
import ApplyJobMobile from '../applyJobMobile';
import withUserStateChecks from '../../hoc/with-user-state-checks';
import Header from '../../components/header';
import SubHeader from '../../components/SubHeader';
import PropTypes from 'prop-types';
import Profile from '../profile';
import ProfileMobile from '../profileMobile';
import Filters from '../filters';
import CreateEditJob from '../create-edit-job';
import Messages from '../messagesMobile';
import SearchJobsPage from '../search-jobs';
import SideBar from '../../components/sidebar';
import MyJobs from '../myJobs';
import JobDetailPage from '../jobDetail/jobDetail';
import RecruiterDetailPage from '../recruiterDetail';
import SavedJobDetail from '../savedJobDetail/savedJobDetail';
import AppliedJobDetail from '../appliedJobDetail/appliedJobDetail';
import MyJobsDetail from '../myJobDetail';
import SearchCandidatePage from '../search-candidates';
import CandidateDetail from '../candidateDetail';
import SavedCandidateDetail from '../savedCandidateDetail';
import { useMessageThreads } from '../../context/message-threads-context';
import { useApplyJob } from '../../context/apply-job-context';
import { useDimensions } from '../../hooks/useDimensions';
import { constructEncodedUrl } from '../../utils/helpers';

const Dashboard = (props) => {
  const { userInfo } = props;

  const { showThreads } = useMessageThreads();
  const dimensions = useDimensions();
  const [sideBarOpened, setSideBarOpened] = React.useState(false);
  const [subHeaderIsOpened, setSubHeaderIsOpened] = React.useState(false);
  const [openedFrom, setOpenedFrom] = React.useState('');

  const { job: selectedJobApply, showApplyJob } = useApplyJob();

  const redirectPath = (userType) => {
    if (window?.localStorage.getItem('visit')) {
      return window?.localStorage.getItem('visit');
    }
    if (window?.localStorage.getItem('visit-candidate')) {
      return constructEncodedUrl(
        { id: window?.localStorage.getItem('visit-candidate') },
        'candidate'
      );
    }
    if (userType === 'candidate') {
      return '/jobs';
    } else {
      return '/candidates';
    }
  };

  let showHeader =
    props?.location?.pathname == '/jobs' ||
    props?.location?.pathname == '/messages' ||
    props?.location?.pathname == '/candidates' ||
    (props?.location?.pathname == '/my-jobs' && !props?.location?.state?.view) ||
    props?.location?.pathname?.includes('/recruiter') ||
    dimensions.width > 920;

  return (
    <>
      {dimensions.width < 920 && (
        <Route
          path={'/thread/:id'}
          component={(props) => (
            <>
              <MobileThread {...props} userInfo={userInfo} />
            </>
          )}
        />
      )}
      <Route
        path={`/create-edit-job/:id`}
        component={(props) => <CreateEditJob {...props} userInfo={userInfo} />}
      />
      {dimensions.width < 920 && (
        <Route
          path={'/apply/:id'}
          component={(props) => (
            <>
              <ApplyJobMobile {...props} userInfo={userInfo} />
            </>
          )}
        />
      )}
      {!props?.location?.pathname?.includes('/thread') &&
        !props?.location?.pathname?.includes('/apply') &&
        !props?.location?.pathname?.includes('/create-edit-job') && (
          <div className={`sidebar${sideBarOpened ? ' opened' : ' closed'}`}>
            {dimensions.width < 920 && (
              <SideBar
                userDetails={userInfo}
                setSideBarOpened={setSideBarOpened}
                sideBarOpened={sideBarOpened}
                setSubHeaderIsOpened={setSubHeaderIsOpened}
                setOpenedFrom={setOpenedFrom}
              />
            )}
            <div
              className={sideBarOpened ? 'sideBarOpened app-holder' : 'app-holder'}
              onClick={(e) => {
                sideBarOpened && setSideBarOpened(false);
              }}
            >
              <Header
                showHeader={showHeader}
                userDetails={userInfo}
                setSideBarOpened={setSideBarOpened}
                setSubHeaderIsOpened={setSubHeaderIsOpened}
                sideBarOpened={sideBarOpened}
              />
              {dimensions.width < 920 &&
                subHeaderIsOpened &&
                !location.pathname?.includes('/profile') && (
                  <SubHeader
                    setSubHeaderIsOpened={setSubHeaderIsOpened}
                    userInfo={userInfo}
                    openedFrom={openedFrom}
                    subHeaderTab=""
                  />
                )}
              <div
                className={`main-container${
                  dimensions.width > 920 ? '' : ' no-header'
                }`}
                {...(dimensions.width < 920 &&
                subHeaderIsOpened &&
                !location.pathname?.includes('/profile')
                  ? { style: { overflow: 'hidden', height: 'calc(100vh - 125px)' } }
                  : {})}
              >
                <section className="s-padding pb-0">
                  <div className="container-lg dashboard">
                    <div className="w-100 d-flex justify-content-start">
                      <Switch>
                        <Route
                          path={`/profile`}
                          component={(props) =>
                            dimensions.width < 920 ? (
                              <ProfileMobile
                                {...props}
                                userInfo={userInfo}
                                openedFrom={openedFrom}
                                setSubHeaderIsOpened={setSubHeaderIsOpened}
                                setOpenedFrom={setOpenedFrom}
                              />
                            ) : (
                              <Profile {...props} userInfo={userInfo} />
                            )
                          }
                        />

                        {dimensions.width < 920 && (
                          <Route
                            path={`/messages`}
                            component={(props) => (
                              <Messages {...props} userInfo={userInfo} />
                            )}
                          />
                        )}
                        {dimensions.width < 920 && (
                          <Route
                            path={`/filters`}
                            component={(props) => (
                              <Filters {...props} userInfo={userInfo} />
                            )}
                          />
                        )}
                        <Route
                          path={`/my-jobs`}
                          exact
                          component={(props) => (
                            <MyJobs userInfo={userInfo} {...props} />
                          )}
                        />
                        <Route
                          exact
                          path={'/recruiter/:id'}
                          component={() => (
                            <RecruiterDetailPage userInfo={userInfo} />
                          )}
                        />
                        <Route
                          path={`/jobs`}
                          component={() => <SearchJobsPage userInfo={userInfo} />}
                        />
                        <Route
                          path={'/job/:id'}
                          component={() => <JobDetailPage userInfo={userInfo} />}
                          exact
                        />
                        <Route
                          exact
                          path={'/job/saved/:id'}
                          component={() => <SavedJobDetail userInfo={userInfo} />}
                        />
                        <Route
                          exact
                          path={'/job/applied/:id'}
                          component={() => <AppliedJobDetail userInfo={userInfo} />}
                        />
                        <Route
                          exact
                          path={'/my-jobs/:id'}
                          component={() => <MyJobsDetail userInfo={userInfo} />}
                        />
                        <Route
                          path={`/candidates`}
                          component={() => (
                            <SearchCandidatePage userInfo={userInfo} />
                          )}
                        />
                        <Route
                          exact
                          path={'/candidate/:id'}
                          component={() => <CandidateDetail userInfo={userInfo} />}
                        />
                        <Route
                          exact
                          path={'/candidate/saved/:id'}
                          component={() => (
                            <SavedCandidateDetail userInfo={userInfo} />
                          )}
                        />
                        <Redirect
                          exact
                          from="/"
                          to={redirectPath(userInfo.user_type)}
                        />
                      </Switch>
                    </div>
                  </div>
                </section>
              </div>

              {showApplyJob && dimensions.width > 920 && (
                <ApplyJob job={selectedJobApply} userInfo={userInfo} />
              )}
              {showThreads && dimensions.width > 920 && (
                <Threads userInfo={userInfo} />
              )}
            </div>
          </div>
        )}
    </>
  );
};

Dashboard.propTypes = {
  userInfo: PropTypes.any,
  location: PropTypes.any,
};

export default withUserStateChecks(Dashboard);
