import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import AllCandidatesList from './allCandidates';
import SavedCandidatesList from './savedCandidates';
import { useMessageThreads } from '../../context/message-threads-context';

const SearchCandidatePage = (props) => {
  const { userInfo } = props;

  let { path, url } = useRouteMatch();
  const { contactedUsers } = useMessageThreads();

  return (
    <Switch>
      <Route
        path={path}
        exact
        component={() => (
          <AllCandidatesList
            contactedCandidates={contactedUsers?.map((i) => i.candidate_id)}
            userInfo={userInfo}
          />
        )}
      />
      <Route
        path={`${path}/saved`}
        component={() => (
          <SavedCandidatesList
            contactedCandidates={contactedUsers?.map((i) => i.candidate_id)}
            userInfo={userInfo}
          />
        )}
      />
    </Switch>
  );
};

SearchCandidatePage.propTypes = {
  userInfo: PropTypes.any,
};

SearchCandidatePage.defaultProps = {
  userInfo: null,
};

export default SearchCandidatePage;
