import React from 'react';
import SubHeader from '../../components/SubHeader';
import PropTypes from 'prop-types';
import { Switch, Route, useHistory } from 'react-router-dom';

const ProfileMobile = ({
  userInfo,
  setSubHeaderIsOpened,
  openedFrom,
  setOpenedFrom,
}) => {
  const history = useHistory();

  const [reRenderKey, setRerenderKey] = React.useState(1);

  React.useEffect(() => {
    setRerenderKey(2);
  }, []);

  return (
    <Switch key={reRenderKey}>
      <Route
        exact
        path="/profile"
        render={() => (
          <SubHeader
            setSubHeaderIsOpened={setSubHeaderIsOpened}
            userInfo={userInfo}
            openedFrom={openedFrom}
            onGoBack={() => {
              if (history?.location?.state?.returnTo) {
                history?.push(history?.location?.state?.returnTo, {
                  ...history?.location?.state,
                });
              } else {
                history.go(-1);
              }
              openedFrom && setOpenedFrom('');
            }}
            subHeaderTab={
              userInfo?.user_type == 'recruiter'
                ? 'recruiter-profile'
                : 'candidate-profile'
            }
          />
        )}
      />
      {userInfo.user_type == 'candidate' && (
        <Route
          exact
          path="/profile/details"
          render={() => (
            <SubHeader
              setSubHeaderIsOpened={setSubHeaderIsOpened}
              userInfo={userInfo}
              openedFrom={openedFrom}
              onGoBack={() => {
                if (history?.location?.state?.returnTo) {
                  history?.push(history?.location?.state?.returnTo, {
                    ...history?.location?.state,
                  });
                } else {
                  history.go(-1);
                }
                openedFrom && setOpenedFrom('');
              }}
              subHeaderTab="candidate-details"
            />
          )}
        />
      )}
    </Switch>
  );
};

ProfileMobile.propTypes = {
  userInfo: PropTypes.any,
  setSubHeaderIsOpened: PropTypes.any,
  openedFrom: PropTypes.any,
  setOpenedFrom: PropTypes.any,
};

export default ProfileMobile;
