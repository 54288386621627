import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import dropdown from '../../images/Icons/dropdown.svg';
import search from '../../images/Icons/search.svg';
import closeBlack from '../../images/Icons/close-black.svg';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

import './styles.scss';
import { useJobSort } from '../../context/job-sort-context';

const Gauge = ({
  listLength,
  showSearchaAndSortOptions,
  appliedFilters,
  title,
  recruiter = false,
  view,
}) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState('');
  const [activeSearch, toggleSearch] = useState(false);
  const [isSortModalOpen, toggleSortModal] = useState(false);

  const ref = useRef();
  const { sortBy, setSortBy, sortingOptions } = useJobSort();

  useEffect(() => {
    setKeyword('');
  }, [activeSearch]);

  useEffect(() => {
    setKeyword('');
  }, [appliedFilters]);

  useOnClickOutside(ref, () => {
    if (isSortModalOpen) {
      toggleSortModal(false);
    }
  });

  const toggleSortModalFn = () => {
    toggleSortModal(!isSortModalOpen);
  };

  const setSortingFn = (e) => {
    const v = e.currentTarget.dataset.value;
    const sortOption = sortingOptions.find((x) => x.value === v);

    setSortBy(sortOption);

    toggleSortModal(false);
  };

  const handleKeywordChange = (e) => {
    setKeyword(e.currentTarget.value);
  };

  const handleViewChange = (type) => {
    if (type == view) {
      return;
    } else {
      window && window.scrollTo(0, 0);
      if (type == 'all') {
        history.push(recruiter ? '/candidates' : '/jobs');
      } else {
        history.push(recruiter ? `/candidates/${type}` : `/jobs/${type}`);
      }
    }
  };

  return (
    <div className="card">
      <div className="card-body card-body pb-0">
        <div className="row card-border-bottom">
          <div className="col-md-12 col-xl-12">
            <h5 className="card-title f20 bb">
              {title}{' '}
              {listLength !== undefined && (
                <span className="card-subtitle">{listLength} found</span>
              )}
            </h5>
          </div>
        </div>
        <div className="row pt-4">
          <div className={`col-xs-12 col-md-6 col-lg-6 d-flex align-items-end`}>
            <span
              className={`mr-3 pb-3 cursor-pointer${
                view === 'all' ? ' text-primary' : ''
              }`}
              style={{
                borderBottom:
                  view === 'all' ? ' 2px solid #f18343' : '2px solid transparent',
              }}
              onClick={() => handleViewChange('all')}
            >
              {recruiter ? 'Search' : 'All'}
            </span>
            <span
              className={`mx-3 pb-3 cursor-pointer${
                view === 'saved' ? ' text-primary' : ''
              }`}
              style={{
                borderBottom:
                  view === 'saved' ? ' 2px solid #f18343' : '2px solid transparent',
              }}
              onClick={() => handleViewChange('saved')}
            >
              Saved
            </span>
            {!recruiter && (
              <span
                className={`mx-3 pb-3 cursor-pointer${
                  view === 'applied' ? ' text-primary' : ''
                }`}
                style={{
                  borderBottom:
                    view === 'applied'
                      ? ' 2px solid #f18343'
                      : '2px solid transparent',
                }}
                onClick={() => handleViewChange('applied')}
              >
                Applied
              </span>
            )}
            {/* {showSearchaAndSortOptions && (
              <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <NavLink className="nav-link" exact to="/jobs">
                    Search
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" exact to="/jobs/saved">
                    Saved
                  </NavLink>
                </li>
              </ul>
            )} */}
          </div>
          {showSearchaAndSortOptions && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
              }}
              className="col-xs-12 col-md-6 col-lg-6 text-right"
            >
              <div className="form-group theme-input-group cursor-pointer" ref={ref}>
                <div onClick={toggleSortModalFn} className="row">
                  <span>
                    Sort by <strong>{sortBy.name}</strong>
                    <img
                      className={`ch-icons sort-by${
                        isSortModalOpen ? ' rotate' : ''
                      }`}
                      src={dropdown}
                    />
                  </span>
                </div>
                {isSortModalOpen && (
                  <div className="row">
                    <div
                      style={{
                        width: '135px',
                        boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.233829)',
                        position: 'absolute',
                        zIndex: '3',
                        background: 'white',
                      }}
                      className="col-sm-12 col-md-12 col-xl-12 p-0 text-left"
                    >
                      <ul className="list-group no-bg p-1 multi-check dropdown">
                        {sortingOptions.map((option, idx) => (
                          <li
                            onClick={setSortingFn}
                            key={option.value}
                            data-value={option.value}
                            className={`list-group-item border-0 ${
                              sortBy === option.value && 'selected'
                            } p-1 no-bg`}
                          >
                            {option.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Gauge.propTypes = {
  title: PropTypes.string,
  listLength: PropTypes.any,
  view: PropTypes.string,
  showSearchaAndSortOptions: PropTypes.bool,
  appliedFilters: PropTypes.object,
  recruiter: PropTypes.bool,
};

Gauge.defaultProps = {
  title: 'Jobs',
  recruiter: false,
};

export default memo(Gauge);
