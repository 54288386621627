import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const CardBody = (props) => {
  const { bodyHeader, body, onClick, userInfo } = props;

  return (
    <div className="card-body pt-1 pb-2">
      {userInfo.user_type == 'recruiter' && (
        <p
          className="font-weight-bold primary-paragraph cursor-pointer"
          onClick={onClick}
        >
          {bodyHeader}
        </p>
      )}
      <p
        style={{ maxWidth: 820 }}
        className={`mb-0 text-grey secondary-paragraph cursor-pointer${
          userInfo?.user_type == 'candidate' ? '' : ' preserve-line'
        }`}
        onClick={onClick}
      >
        {userInfo?.user_type == 'candidate' ? (
          <ReactMarkdown className="markdown-container">{body}</ReactMarkdown>
        ) : (
          body
        )}
      </p>
    </div>
  );
};

CardBody.propTypes = {
  userInfo: PropTypes.any,
  bodyHeader: PropTypes.string,
  body: PropTypes.string,
  onClick: PropTypes.func,
};

CardBody.defaultProps = {
  userInfo: null,
  bodyHeader: '',
  body: '',
  onClick: null,
};

export default CardBody;
