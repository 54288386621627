import React from 'react';
import PropTypes from 'prop-types';
import './gaugeMobile.scss';
import downArrow from '../../images/down-arrow.svg';

const GaugeMobile = ({
  listLength,
  setShowFilters,
  filterCount,
  itemName = 'job',
}) => {
  return (
    <div className="gauge-mobile">
      <div className="gauge-mobile__results-filter d-flex align-items-center justify-content-between">
        <div {...(itemName == 'message' ? { style: { opacity: 0 } } : {})}>
          <strong>{listLength}</strong>
          {` ${itemName}s `}
          matched
        </div>
        <div onClick={(e) => setShowFilters(true)}>
          {filterCount > 0 && (
            <div className="filter-count text-center pr-1 mobile">{filterCount}</div>
          )}
          Edit filters <img src={downArrow} alt="down-arrow" />
        </div>
      </div>
    </div>
  );
};

GaugeMobile.propTypes = {
  listLength: PropTypes.any,
  setShowFilters: PropTypes.any,
  itemName: PropTypes.any,
  filterCount: PropTypes.any,
};

export default GaugeMobile;
