import React from 'react';
import PropTypes from 'prop-types';
import profileIcon from '../../images/Icons/profile-icon-black.svg';
import helpIcon from '../../images/Icons/help-icon-black.svg';
import { DeleteProfileModal } from '../../pages/profile/delete-profileModal';
import preferenceIcon from '../../images/Icons/settings-icon-black.svg';
import { toast } from 'react-toastify';
import defaultAvatar from '../../images/default-avatar.svg';
import Loader from '../loader';
import { ReactComponent as ExitIcon } from '../../images/Icons/exit-icon.svg';
import arrowLeft from '../../images/Icons/arrow-detail.svg';
import { updateUser } from '../../pages/profile/service';
import editIcon from '../../images/Icons/edit.svg';
import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from '../../context/auth-context';
import { allowedTypes } from '../../utils/constants';
import RecruiterInfo from '../../pages/profile/recruiterInfo';
import Details from '../../pages/profile/details';
import Info from '../../pages/profile/info';
import { resizeImage } from '../../utils/helpers';
import './index.scss';
import { useHistory } from 'react-router-dom';

const SubHeader = ({
  setSubHeaderIsOpened,
  onGoBack,
  userInfo,
  subHeaderTab,
  openedFrom,
}) => {
  const history = useHistory();
  const subHeaderRef = React.useRef();
  const [published, setPublished] = React.useState(userInfo?.profile_published);
  const [modalShow, setModalShow] = React.useState(false);
  const [accordionIsOpen, setAccordionIsOpen] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const auth = useAuth();
  const queryClient = useQueryClient();

  const logout = () => {
    auth.logout();
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleProfileImageUpload = async (e) => {
    if (
      allowedTypes.candidate.profilePhoto.indexOf(
        e.target?.files[0]?.type.split('/')[0]
      ) > -1
    ) {
      const resizedImage = await resizeImage(e.target.files[0]);
      const resizedFile = {
        file: {
          originFileObj: resizedImage,
        },
      };
      const picture = await toBase64(resizedFile.file.originFileObj);
      if (picture) {
        mutate({ ...userInfo, avatar_img: picture, updateType: 'img' });
      }
    } else {
      toast.error('file selected is not of image format');
    }
  };

  const { mutate } = useMutation(updateUser, {
    onMutate: () => setLoading(true),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('get-auth0-profile');
      if (variables.updateType == 'publish') {
        toast.success(
          variables.profile_published
            ? 'Published Succesfully'
            : 'Unpublished Succesfully'
        );
      } else {
        toast.success('Updated Succesfully');
      }

      setLoading(false);
    },
    onError: () => {
      toast.error("Couldn't Update");
      setLoading(false);
    },
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  React.useEffect(() => {
    setAccordionIsOpen(false);
  }, [subHeaderTab]);

  React.useEffect(() => {
    if (subHeaderRef?.current) {
      subHeaderRef.current.scrollTop = 0;
    }
  }, []);

  const onUnPublish = () => {
    setPublished(!published);
    mutate({
      ...userInfo,
      profile_published: !published,
      updateType: 'publish',
    });
  };

  return (
    <>
      <DeleteProfileModal
        {...{
          onUnPublish,
          deleted,
          deleteLoading,
          setDeleted,
          modalShow,
          setDeleteLoading,
          setModalShow,
          userInfo,
        }}
      />
      <div
        className={`sub-header${subHeaderTab !== '' ? ' as-page' : ''}`}
        ref={subHeaderRef}
        style={{ height: subHeaderTab == '' ? window?.innerHeight : '' }}
        onClick={() => {
          subHeaderTab == '' && setSubHeaderIsOpened(false);
        }}
      >
        <div
          className={`sub-header__content${subHeaderTab !== '' ? ' as-page' : ''}`}
          onClick={(e) => e.stopPropagation()}
        >
          {subHeaderTab == '' && (
            <>
              <div
                className="sub-header__content__action"
                onClick={(e) =>
                  history.push('/profile', {
                    returnTo: history.location?.pathname,
                    ...history?.location?.state,
                  })
                }
              >
                <img src={profileIcon} alt="icon" />
                <span>Profile</span>
              </div>
              <div
                className="sub-header__content__action"
                onClick={() => {
                  setTimeout(() => {
                    window.open(
                      'mailto:info@jobterix.com?subject="Support request"',
                      '_self'
                    );
                  }, 100);
                }}
              >
                <img src={helpIcon} alt="icon" />
                <span>Help</span>
              </div>
              <div className="sub-header__content__action">
                <img src={preferenceIcon} alt="icon" />
                <span>Preference</span>
              </div>
              <div className="sub-header__content__action sign-out" onClick={logout}>
                <ExitIcon />
                <span>Sign out</span>
              </div>
            </>
          )}
          {subHeaderTab !== '' && (
            <div
              className={`profile-mobile-top${
                userInfo?.user_type == 'candidate' ? ' pb-0' : ''
              }`}
            >
              <div className="goBack-content d-flex align-items-center">
                <img
                  src={arrowLeft}
                  onClick={(e) => {
                    if (openedFrom == 'sidebar') {
                      setSubHeaderIsOpened(false);
                    }
                    onGoBack && onGoBack();
                  }}
                  alt="arrow"
                />
                <h5 className="mb-0">Edit Profile</h5>
              </div>
              <div className="position-relative edit-picture">
                <img
                  className="m-0"
                  src={userInfo?.avatar_url || defaultAvatar}
                  alt="avatar"
                />
                <input
                  className="upload-zone image-upload"
                  type="file"
                  onChange={handleProfileImageUpload}
                  accept="image/*"
                />
                <div className="edit d-flex algin-items-center justify-content-center">
                  <img src={editIcon} alt="edit" />
                </div>
              </div>
              {userInfo.user_type == 'candidate' && (
                <>
                  <div className="toggle-holder">
                    <div className="right-side d-flex align-items-center">
                      <span className="text-bold">Unpublish</span>
                      <label className="switch mb-0 mx-2">
                        <input
                          type="checkbox"
                          checked={published}
                          onChange={() => {
                            setPublished(!published);
                            mutate({
                              ...userInfo,
                              profile_published: !published,
                              updateType: 'publish',
                            });
                          }}
                        />
                        <span className="slider round"></span>
                      </label>
                      <span className="text-bold">Publish</span>
                    </div>
                  </div>
                  <div
                    className="gauge-mobile mt-4"
                    style={{ position: 'relative', zIndex: 5 }}
                  >
                    <div
                      className={`gauge-mobile__tabs ${
                        history.location.pathname == '/profile'
                          ? 'jobs-view'
                          : 'messages-view'
                      }`}
                    >
                      <div className="d-flex align-items-center justify-content-around">
                        <span
                          onClick={() =>
                            history.push('/profile', { ...history?.location?.state })
                          }
                          className={
                            history.location.pathname == '/profile' ? 'active' : ''
                          }
                        >
                          Profile
                        </span>
                        <span
                          onClick={() =>
                            history.push('/profile/details', {
                              ...history?.location?.state,
                            })
                          }
                          className={
                            history.location.pathname == '/profile/details'
                              ? `active messages-button`
                              : `messages-button`
                          }
                        >
                          Contact Details
                        </span>
                      </div>
                      <div className="tab-indicator"></div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {subHeaderTab == 'recruiter-profile' && (
            <RecruiterInfo
              deleteProfile={() => setModalShow(true)}
              userInfo={userInfo}
              published={published}
              setLoading={(data) => {
                subHeaderRef.current.scrollTop = 0;
                setLoading(data);
              }}
            />
          )}
          {subHeaderTab == 'candidate-profile' && (
            <Info
              deleteProfile={() => setModalShow(true)}
              userInfo={userInfo}
              published={published}
              setLoading={(data) => {
                subHeaderRef.current.scrollTop = 0;
                setLoading(data);
              }}
            />
          )}
          {subHeaderTab == 'candidate-details' && (
            <Details
              userInfo={userInfo}
              published={published}
              setLoading={(data) => {
                subHeaderRef.current.scrollTop = 0;
                setLoading(data);
              }}
            />
          )}
        </div>
        {(loading || !userInfo || Object.keys(userInfo)?.length == 1) && (
          <div className="loader-container d-flex align-items-center justify-content-center">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

SubHeader.propTypes = {
  userInfo: PropTypes.any,
  setSubHeaderIsOpened: PropTypes.any,
  onGoBack: PropTypes.any,
  subHeaderTab: PropTypes.any,
  openedFrom: PropTypes.any,
};

export default SubHeader;
