export const filtersEmptyState = {
  skills: [],
  languages: [],
  employmentOptions: [],
  locations: [],
};

export const postTypes = {
  job: 'job',
  candidate: 'candidate',
};

export const allowedTypes = {
  candidate: {
    resume: ['application/pdf'],
    profilePhoto: ['image'],
  },
};

export const urlRE = new RegExp(
  '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+'
);

export const filledPositions = [
  //qa
  //prod
];
