import React from 'react';
import { useQuery } from 'react-query';
import { getAllSavedJobs } from '../pages/dashboard/service';
import { useLists } from '../context/lists-context';

export function useSavedJobs(userType = null) {
  const { savedJobs, setSavedJobs } = useLists();
  const { data, isLoading, isError, error, refetch, isFetching } = useQuery(
    'fetch-saved-jobs',
    getAllSavedJobs,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      useErrorBoundary: (error) => {
        return error?.response?.status === 401;
      },
      retry: (count, error) => {
        if (error?.response?.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
      enabled:
        savedJobs?.length == 0 && (userType === null || userType === 'candidate'),
    }
  );

  React.useEffect(() => {
    data && setSavedJobs(data);
  }, [data]);

  return {
    jobs: data,
    isLoading,
    isError,
    error,
    refetch,
    isFetching,
  };
}
