import React from 'react';
import logo from '../../images/logo.png';
import './styles.scss';
import { useAuth } from '../../context/auth-context';
import httpClient from '../../utils/newHttpClient';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useDimensions } from '../../hooks/useDimensions';

const Verify = () => {
  const auth = useAuth();

  const dimensions = useDimensions();

  const { data, refetch, isFetched } = useQuery(
    'auth0-getprofile',
    async () => {
      return await new Promise((resolve, reject) => {
        auth.getProfile((profile) => {
          const { email, email_verified, sub } = profile;

          resolve({
            email,
            email_verified,
            sub,
          });
        });
      });
    },
    { refetchOnWindowFocus: false }
  );

  const resend = async () => {
    if (data.email_verified) {
      return;
    }

    const res = await httpClient.post(
      '/resendVerifyEmail',
      { user_id: data.sub },
      { baseURL: process.env.REACT_APP_RESEND_VERIFY_EMAIL_BASE_URL }
    );

    if (res.status === 200) {
      toast.success('Activation email has been resent successfully!');
    } else {
      toast.error('There was an error, please try again later');
    }

    refetch();
  };

  const logout = () => {
    auth.logout();
  };

  return (
    <>
      {isFetched && (
        <div className="d-flex vh-100">
          <div className="w-50 jtx-signup-img p-3">
            <img src={logo} alt="JobTerix" />
          </div>
          <div className="d-flex flex-column align-items-xl-start justify-content-around w-100 p-5">
            <div className="d-flex flex-column ml-4">
              <h1>Email Verification</h1>
              <p style={{ color: '#333333' }}>
                Please validate your email address to continue.
              </p>
              <p style={{ fontSize: '18px' }}>
                We have sent an email to <strong>{data && data.email}</strong> with
                the instructions on how to activate your account.
                <br />
                <br />
                Please follow the instructions in the email to complete the
                registration process.
              </p>
              <p className="mt-3">
                Haven&apos;t received the email?&nbsp;{' '}
                {dimensions.width < 392 && <br />}
                <a href="#" onClick={resend}>
                  Resend it.
                </a>
              </p>
              <p className="mt-1">
                <a href="#" onClick={logout}>
                  Sign in
                </a>{' '}
                with another account
              </p>
            </div>
            <div className="ml-4"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Verify;
