import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, Switch, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/loader';
import edit from '../../images/Icons/edit.svg';
import defaultAvatar from '../../images/default-avatar.svg';
import { useMutation, useQueryClient } from 'react-query';
import { updateUser } from './service';
import Info from './info';
import PaymentInfo from './payment';
import RecruiterInfo from './recruiterInfo';
import Details from './details';
import { Helmet } from 'react-helmet';
import { allowedTypes } from '../../utils/constants';
import './styles.scss';
import { DeleteProfileModal } from './delete-profileModal';
import { resizeImage } from '../../utils/helpers';

const Profile = ({ userInfo, location: { pathname } }) => {
  const [published, setPublished] = React.useState(userInfo?.profile_published);
  const [loading, setLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const queryClient = useQueryClient();

  const { mutate } = useMutation(updateUser, {
    onMutate: () => setLoading(true),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('get-auth0-profile');
      if (variables.updateType == 'publish') {
        toast.success(
          variables.profile_published
            ? 'Published Succesfully'
            : 'Unpublished Succesfully'
        );
      } else {
        toast.success('Updated Succesfully');
      }

      setLoading(false);
    },
    onError: () => {
      toast.error("Couldn't Update");
      setLoading(false);
    },
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleProfileImageUpload = async (e) => {
    if (
      allowedTypes.candidate.profilePhoto.indexOf(
        e.target?.files[0]?.type.split('/')[0]
      ) > -1
    ) {
      const resizedImage = await resizeImage(e.target.files[0]);
      const resizedFile = {
        file: {
          originFileObj: resizedImage,
        },
      };
      const picture = await toBase64(resizedFile.file.originFileObj);
      if (picture) {
        mutate({ ...userInfo, avatar_img: picture, updateType: 'img' });
      }
    } else {
      toast.error('file selected is not of image format');
    }
  };

  const onUnPublish = () => {
    setPublished(!published);
    mutate({
      ...userInfo,
      profile_published: !published,
      updateType: 'publish',
    });
  };

  return (
    <div className="w-100 profile-page">
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <DeleteProfileModal
        {...{
          onUnPublish,
          deleted,
          deleteLoading,
          setDeleted,
          modalShow,
          setDeleteLoading,
          setModalShow,
          userInfo,
        }}
      />
      {(loading || !userInfo || Object.keys(userInfo)?.length == 1) && (
        <div className="loader-container d-flex align-items-center justify-content-center">
          <Loader />
        </div>
      )}
      <div className="profile-header border-grey border-rounded d-flex bg-white align-items-center justify-content-between w-100 px-4 py-3 mb-3">
        <div className="left-side d-flex align-items-center">
          <div className="position-relative mr-2">
            <img
              className="m-0"
              src={userInfo?.avatar_url || defaultAvatar}
              alt="avatar"
            />
            <input
              className="upload-zone image-upload"
              type="file"
              onChange={handleProfileImageUpload}
              accept="image/*"
            />
            <div className="edit d-flex algin-items-center justify-content-center">
              <img src={edit} alt="edit" />
            </div>
          </div>
          <div>
            <span className="avatar-text d-block">{userInfo?.name}</span>
            <span className="avatar-text d-block text-grey">
              Set up your JobTerix presence and hiring needs
            </span>
          </div>
        </div>
        {userInfo.user_type == 'candidate' && (
          <div className="right-side d-flex align-items-center">
            <span className="text-bold">Unpublish</span>
            <label className="switch mb-0 mx-2">
              <input
                type="checkbox"
                checked={published}
                onChange={() => {
                  setPublished(!published);
                  mutate({
                    ...userInfo,
                    profile_published: !published,
                    updateType: 'publish',
                  });
                }}
              />
              <span className="slider round"></span>
            </label>
            <span className="text-bold">Publish</span>
          </div>
        )}
      </div>
      <div className="d-flex w-100">
        <div className="w-320px border-grey p-3 d-flex flex-column bg-white border-rounded">
          {pathname === '/profile/payment' ? (
            <>
              <h4 className="profile-link px-1 mb-2">Payment Summary</h4>
              <p className="px-1 mb-2">
                You are using credit card ending in 3876 to purchase 10 credits for
                $100.
              </p>
              <p className="px-1 mb-0">
                By enabling auto top-up, your card will be automatically charged with
                $100 to purchase 10 credits when your balance runs low.
              </p>
            </>
          ) : (
            <Link
              className={classNames('profile-link px-1 py-2', {
                active: pathname === '/profile',
              })}
              to="/profile"
            >
              Profile
            </Link>
          )}
          {userInfo.user_type == 'candidate' && (
            <Link
              className={classNames('profile-link px-1 py-2', {
                active: pathname === '/profile/details',
              })}
              to="/profile/details"
            >
              Contact details and CV
            </Link>
          )}
        </div>
        <div className="w-75 pb-4" style={{ paddingLeft: '21px' }}>
          <div className="bg-white border-grey border-rounded">
            <Switch>
              <Route
                exact
                path="/profile"
                render={() =>
                  userInfo.user_type == 'candidate' ? (
                    <Info
                      deleteProfile={() => setModalShow(true)}
                      userInfo={userInfo}
                      published={published}
                      setLoading={(data) => setLoading(data)}
                    />
                  ) : (
                    <RecruiterInfo
                      deleteProfile={() => setModalShow(true)}
                      userInfo={userInfo}
                      published={published}
                      setLoading={(data) => setLoading(data)}
                    />
                  )
                }
              />
              {userInfo.user_type == 'candidate' && (
                <Route
                  exact
                  path="/profile/details"
                  render={() => (
                    <Details
                      userInfo={userInfo}
                      published={published}
                      setLoading={(data) => setLoading(data)}
                    />
                  )}
                />
              )}
              {userInfo.user_type == 'recruiter' &&
                process.env.REACT_APP_HEAP_ID === '673468839' && (
                  <Route
                    exact
                    path="/profile/payment"
                    render={() => <PaymentInfo userInfo={userInfo} />}
                  />
                )}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  userInfo: PropTypes.any,
  location: PropTypes.any,
};

export default Profile;
