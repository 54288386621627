import React from 'react';
import PropTypes from 'prop-types';
import Thread from '../../components/mobileThread';
import Loader from '../../components/loader';
import { getJobs } from '../myJobs/service';
import { deleteUser } from '../profile/service';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import NoResultsFound from '../../components/NoResultsComponent';
import { useQuery, useMutation } from 'react-query';
import GaugeMobile from '../dashboard/gaugeMobile';
import Filters from '../../components/MessagesFilter';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMessageThreads } from '../../context/message-threads-context';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { constructEncodedUrl } from '../../utils/helpers';
import {
  getAllThreads,
  markAsRead,
  markAsUnRead,
  markAsArchived,
  markAsUnArchived,
} from '../../api';

const Messages = ({ userInfo }) => {
  const {
    selectedJobFilter: contextSelectedJobFilter,
    showApplied: contextShowApplied,
    setThreadId,
    setJobId,
    mobileThreads,
    setMobileThreads,
    setCandidateData,
    setJobData,
    setMobileLastMessage,
  } = useMessageThreads();
  const history = useHistory();
  const [threads, setThreads] = React.useState([]);
  const [archivedOnly, setArchivedOnly] = React.useState(false);
  const [showApplied, setShowApplied] = React.useState(
    contextShowApplied ? contextShowApplied : false
  );
  const [selectedJobFilter, setSelectedJobFilter] = React.useState(
    contextSelectedJobFilter ? contextSelectedJobFilter : []
  );
  const size = 30;
  const [from, setFrom] = React.useState(
    mobileThreads ? Math.ceil(mobileThreads?.length / size) * 30 : 0
  );
  const [resetThreads, setResetThreads] = React.useState(false);
  const [showUnread, setShowUnread] = React.useState(false);
  const [gqlData, setgqlData] = React.useState(mobileThreads ? mobileThreads : null);
  const [searchFilterValue, setSearchFilterValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [showFilters, setShowFilters] = React.useState(false);
  const [scrollID, setScrollID] = React.useState();
  const [showCandidateTag, setShowCandidateTag] = React.useState(
    history?.location?.state?.candidate_id ? true : null
  );

  const {
    data,
    isLoading: gqlLoading,
    refetch: loadMessages,
  } = useQuery(['allThreads', { archivedOnly, from, size }], getAllThreads, {
    onSuccess: (data) => {
      loading && setLoading(false);
      data?.data?.length < size && setHasMore(false);
      if (gqlData && !resetThreads) {
        setgqlData([...gqlData, ...data?.data]);
      } else {
        setgqlData(data?.data);
        resetThreads && setResetThreads(false);
      }
    },
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { data: myJobs } = useQuery(['get-jobs', { id: userInfo?.id }], getJobs, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: userInfo.user_type == 'recruiter',
    useErrorBoundary: (error) => {
      return error.response?.status === 401;
    },
    retry: (count, error) => {
      if (error.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const onReport = async (thread_id) => {
    setLoading(true);
    let response = await deleteUser({
      subject: `Thread Reported By User`,
      message: `Thread ID: ${thread_id}`,
    });

    if (response && response?.data?.message == 'success') {
      setLoading(false);
      toast.success('Reported Succesfully');
    }
  };

  const { mutate: markThreadUnread } = useMutation(markAsUnRead, {
    onSuccess: async (data, variables) => {
      setgqlData(
        gqlData?.map((thread) => {
          if (thread?.id == variables.id) {
            return { ...thread, unread: true };
          }
          return thread;
        })
      );
      setLoading(false);
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate: markThreadRead } = useMutation(markAsRead, {
    onSuccess: async (data, variables) => {
      setgqlData(
        gqlData?.map((thread) => {
          if (thread?.id == variables.id) {
            return { ...thread, unread: null };
          }
          return thread;
        })
      );
      setLoading(false);
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate: archiveThread } = useMutation(markAsArchived, {
    onSuccess: async (data, variables) => {
      if (archivedOnly) {
        setThreads(
          threads?.map((thread) => {
            if (thread?.id == variables?.id) {
              return {
                ...thread,
                archived: true,
              };
            }
            return thread;
          })
        );
      } else {
        setgqlData(gqlData?.filter((thread) => thread?.id !== variables?.id));
      }
      setLoading(false);
      toast.success('Archived Succesfully');
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { mutate: unarchiveThread } = useMutation(markAsUnArchived, {
    onSuccess: async (data, variables) => {
      setThreads(threads?.filter((thread) => thread?.id !== variables?.id));
      setLoading(false);
      toast.success('Unarchived Succesfully');
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const onMarkUnread = (thread_id, unread) => {
    setLoading(true);
    if (unread) {
      markThreadRead({
        id: thread_id,
      });
    } else {
      markThreadUnread({
        id: thread_id,
      });
    }
  };

  const openMessages = (thread) => {
    setScrollID(thread.id);
    setJobId(thread.job.id);
    setThreadId(thread.id);
    if (userInfo?.user_type == 'candidate') {
      setJobData(thread?.job);
    } else {
      setCandidateData(thread?.candidate);
    }
    setMobileLastMessage(thread?.last_message?.content);
    setMobileThreads(gqlData);
    history.push(
      constructEncodedUrl(
        {
          thread_id: thread.id,
          job_id: thread.job.id,
        },
        'thread'
      )
    );
  };

  const threadProps = (thread) => {
    let props = {
      unread: thread.unread,
      applied: thread.applied,
      updatedAt: thread.updated_at,
      jobTitle: thread.job.title,
    };
    const { job, candidate } = thread;
    if (userInfo?.user_type == 'candidate') {
      props = {
        ...props,
        title: job?.title,
        lastMessage: {
          ...thread?.last_message,
          sender_name:
            thread?.last_message?.sender_id == userInfo?.id
              ? 'You: '
              : thread?.recruiter?.name + ': ',
        },
      };
    } else if (userInfo.user_type == 'recruiter') {
      props = {
        ...props,
        title: candidate.name || candidate.designation,
        status: job.status,
        jobTitle: job.title,
        lastMessage: {
          ...thread?.last_message,
          sender_name:
            thread?.last_message?.sender_id == userInfo?.id
              ? 'You: '
              : 'Candidate: ',
        },
      };
    }

    return props;
  };

  const getFilterCount = () => {
    let count = 0;

    if (showApplied) {
      count = count + 1;
    }
    if (selectedJobFilter.length > 0) {
      count = count + selectedJobFilter.length;
    }
    if (archivedOnly) {
      count = count + 1;
    }
    if (showUnread) {
      count = count + 1;
    }
    return count;
  };

  const filterThreads = (threads) => {
    const selectedJobIds = selectedJobFilter.map((job) => job.id);
    let filteredThreads = threads;
    const lowerCaseSearchValue = searchFilterValue.toLowerCase();
    if (selectedJobIds.length > 0) {
      filteredThreads = filteredThreads.filter((thread) =>
        selectedJobIds.includes(thread?.job?.id)
      );
    }
    if (showApplied) {
      filteredThreads = filteredThreads.filter((thread) => thread?.applied === true);
    }
    if (showUnread) {
      filteredThreads = filteredThreads.filter((thread) => thread?.unread === true);
    }
    if (showCandidateTag) {
      filteredThreads = filteredThreads.filter(
        (thread) => thread?.candidate?.id === history?.location?.state?.candidate_id
      );
    }
    if (searchFilterValue) {
      if (userInfo.user_type == 'candidate') {
        filteredThreads = filteredThreads.filter((thread) =>
          thread?.job?.title?.toLowerCase()?.includes(lowerCaseSearchValue)
        );
      } else if (userInfo.user_type == 'recruiter') {
        filteredThreads = filteredThreads.filter((thread) => {
          if (thread?.applied) {
            if (thread?.candidate?.name) {
              return thread?.candidate?.name
                ?.toLowerCase()
                ?.includes(lowerCaseSearchValue);
            }
          }
          return thread?.candidate?.designation
            ?.toLowerCase()
            ?.includes(lowerCaseSearchValue);
        });
      }
    }

    return filteredThreads;
  };

  React.useEffect(() => {
    if (gqlData) {
      /**
       * OH MY GOD WHAT IS THIS EVEN!
       * All because React cannot handle an array like this
       * [
       *  {name:'something'},
       *  {name:'that wasted'},
       *  {name:'three hours of my time'}
       * ]
       */
      const restructuredThreads = gqlData.map((thread) => {
        if (thread == null) {
          return;
        }
        let { candidate, job } = thread;
        const transform = (items) => (items ? items.map((item) => item.name) : []);
        return {
          ...thread,
          candidate: {
            ...candidate,
            locations: transform(candidate.locations),
            employment_options: transform(candidate.employment_options),
          },
          job: {
            ...job,
            locations: transform(job.locations),
            employment_options: transform(job.employment_options),
          },
        };
      });
      const allThreads = [...restructuredThreads];
      setThreads(allThreads);
    }
  }, [gqlData]);

  React.useEffect(() => {
    threads?.length == 0 &&
      !mobileThreads &&
      loadMessages({
        archivedOnly,
        from,
        size,
      });
  }, []);

  React.useEffect(() => {
    if (resetThreads) {
      scrollID && setScrollID(null);
      setFrom(0);
      setHasMore(true);
      loadMessages({
        from: 0,
        size,
        archivedOnly,
      });
    }
  }, [resetThreads]);

  React.useEffect(() => {
    from &&
      loadMessages({
        from,
        size,
        archivedOnly,
      });
  }, [from]);

  return (
    <>
      <div
        className="messages-page"
        {...(showFilters
          ? { style: { height: '70vh', overflow: 'hidden', minHeight: 'unset' } }
          : {})}
      >
        <Helmet>
          <title>JobTerix - Messages</title>
        </Helmet>
        {showFilters && (
          <Filters
            candidateView={userInfo?.user_type == 'candidate'}
            myJobs={myJobs}
            setShowFilters={setShowFilters}
            showUnread={showUnread}
            setShowUnread={setShowUnread}
            showApplied={showApplied}
            includeArchived={archivedOnly}
            setIncludeArchived={setArchivedOnly}
            setShowApplied={setShowApplied}
            setResetThreads={setResetThreads}
            selectedJobFilter={selectedJobFilter}
            setSelectedJobFilter={setSelectedJobFilter}
          />
        )}
        <GaugeMobile
          itemName="message"
          setShowFilters={setShowFilters}
          filterCount={getFilterCount()}
        />
        <div className="position-relative">
          {showCandidateTag ? (
            <span
              className="d-block checkbox-select__multi-value position-absolute pl-2 py-2 text-truncate"
              style={{ borderRadius: 2, top: 10, left: 6, maxWidth: 250 }}
            >
              {history?.location?.state?.designation}
              <svg
                height="14"
                width="14"
                viewBox="0 0 20 20"
                style={{ fill: '#fff' }}
                className="mx-1 cursor-pointer"
                onClick={() => setShowCandidateTag(false)}
              >
                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
              </svg>
            </span>
          ) : (
            ''
          )}
          <input
            onKeyPress={(e) => {
              let charCode = e.which ? e.which : e.keyCode;
              if (charCode === 13) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              setSearchFilterValue(e.currentTarget.value);
            }}
            className="form-control mb-0 border-3 border-grey text-light-gray f16 w-100"
            type="text"
            placeholder={showCandidateTag ? '' : 'Search Messages'}
          />
        </div>
        <div className="messages-list">
          {loading && !gqlLoading && <Loader className="detail-loader" />}
          {filterThreads(threads).length == 0 && !gqlLoading && (
            <NoResultsFound height="50vh" />
          )}
          <InfiniteScroll
            dataLength={threads?.length}
            next={() => filterThreads(threads).length > 0 && setFrom(from + size)}
            loader={
              selectedJobFilter?.length == 0 &&
              searchFilterValue == '' &&
              !showApplied &&
              !showUnread ? (
                <Loader className="messages-mobile-loader" />
              ) : (
                ''
              )
            }
            hasMore={hasMore}
            endMessage={<p className="text-secondary"></p>}
            scrollableTarget="thread-container"
          >
            {filterThreads(threads)?.length > 0 &&
              filterThreads(threads).map((thread, key) => {
                if (thread == null) {
                  return;
                }
                return (
                  <Thread
                    candidateView={userInfo?.user_type == 'candidate'}
                    key={key}
                    archived={thread.archived}
                    onClick={() => openMessages(thread)}
                    onArchive={async () => {
                      setLoading(true);
                      await archiveThread({
                        id: thread.id,
                      });
                    }}
                    scrollID={
                      scrollID == thread?.thread_id && !resetThreads
                        ? scrollID
                        : null
                    }
                    setScrollID={setScrollID}
                    onUnarchive={async () => {
                      setLoading(true);
                      await unarchiveThread({
                        id: thread.id,
                      });
                    }}
                    onMark={() => onMarkUnread(thread.id, thread.unread)}
                    onReport={() => onReport(thread.thread_id)}
                    {...threadProps(thread)}
                  />
                );
              })}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

Messages.propTypes = {
  userInfo: PropTypes.any,
};

export default Messages;
