import React from 'react';
import Page from '../page';
import { useQuery } from 'react-query';
import { useAuth } from '../../../context/auth-context';
import { fetchUserProfileDetails } from '../../../hoc/services/with-user-state-checks.service';
import { useHistory, Redirect } from 'react-router-dom';
import candidateLog from '../../../images/candidate.svg';
import { useDimensions } from '../../../hooks/useDimensions';
import recruiter from '../../../images/recruiter.svg';
import './userSelection.scss';

function UserSelection() {
  const auth = useAuth();
  const dimensions = useDimensions();
  const { data: onboardingStatus } = useQuery(
    'get-user-onboarding-status',
    fetchUserProfileDetails,
    {
      enabled: auth.isAuthenticated(),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  if (onboardingStatus) {
    return <Redirect to="/" />;
  }

  const history = useHistory();
  return (
    <div
      className={`${
        dimensions.width > 1024 ? 'w-100by485' : 'w-100'
      } sw-padding vh-100 bg-white`}
    >
      <div className="row">
        <div className="col-xs-12 col-md-12 col-xl-12">
          <div className="sw-headings sw-top-padding">
            <h1>How are you planning on using JobTerix?</h1>
            <h3>Pick your role to continue.</h3>
          </div>
          <div className="sw-jobtype">
            <a
              onClick={() => history.push('/onboarding/candidate')}
              className="sw-jt-btn cursor-pointer"
            >
              <div className="sw-description">
                <h4>Candidate</h4>
                <p>
                  Sign up for <span style={{ fontWeight: 700 }}>JobTerix</span> and
                  create or import your profile to help you find that coveted
                  position in the right company.
                </p>
              </div>
              <div className="sw-frame">
                <img src={candidateLog} />
                <span>Candidate</span>
              </div>
            </a>
            <a
              onClick={() => history.push('/onboarding/recruiter')}
              className="sw-jt-btn cursor-pointer"
            >
              <div className="sw-description">
                <h4>Recruiter</h4>
                <p>
                  Sign up for <span style={{ fontWeight: 700 }}>JobTerix</span>,
                  create job posts and get access to thousands of profiles that you
                  can filter by{' '}
                  <span style={{ fontWeight: 700 }}>
                    salary, experience, qualifications, accomplishments
                  </span>{' '}
                  and more.
                </p>
              </div>
              <div className="sw-frame">
                <img src={recruiter} />
                <span>Recruiter</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page(UserSelection);
