import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { updateJob } from './service';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { JobForm } from './form/jobForm';
import { useForm } from 'react-hook-form';
import { useDimensions } from '../../hooks/useDimensions';
import RichTextEditor from 'react-rte';
import arrowLeft from '../../images/Icons/arrow-detail.svg';
import { urlRE } from '../../utils/constants';

export const EditJob = ({
  data,
  onClose,
  setLoading,
  userInfo,
  inDetail = false,
}) => {
  const {
    register,
    formState: { errors },
    control,
    trigger,
    getValues,
  } = useForm({
    defaultValues: {
      title: data?.title || '',
      company_name: data?.company_name || '',
      experience: data?.experience || 0,
      qualifications: data?.qualifications || '',
      min_salary: data?.min_salary || 0,
      max_salary: data?.max_salary || 0,
      show_salary: data?.show_salary || false,
      employment_options: data?.employment_options || [],
      skills: data?.skills || [],
      languages: data?.languages || [],
      locations: data?.locations || [],
    },
  });
  const [description, setDescription] = React.useState(
    RichTextEditor.createValueFromString(data?.description || '', 'markdown')
  );
  const [rteError, setRteError] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const queryClient = useQueryClient();
  const dimensions = useDimensions();

  const { mutate } = useMutation(updateJob, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(inDetail ? 'fetch-job' : 'get-jobs');
      setLoading(false);
      onClose();
      toast.success('Successfully Updated');
    },
    onError: () => {
      setLoading(false);
      toast.error("Couldn't Update");
    },
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    const {
      title,
      experience,
      min_salary,
      max_salary,
      show_salary,
      employment_options,
      skills,
      languages,
      locations,
    } = getValues();

    await trigger([
      'title',
      'experience',
      'min_salary',
      'max_salary',
      'skills',
      'employment_options',
      'languages',
      'locations',
    ]).then((noError) => {
      if (
        noError &&
        description?.toString('markdown')?.replaceAll('**', '')?.trim()?.length >
          2 &&
        !description
          ?.toString('markdown')
          ?.toLocaleLowerCase()
          ?.replaceAll('.js')
          ?.match(urlRE)
      ) {
        setLoading(true);
        mutate({
          id: data?.id,
          title,
          experience: parseInt(experience),
          min_salary: parseInt(min_salary),
          max_salary: parseInt(max_salary),
          description: description
            ?.toString('markdown')
            ?.replaceAll(/(\*+)\s*(.*?)\s*(\*+)/g, '$1$2$3'),
          show_salary,
          employment_options,
          skills,
          languages: languages?.map((i) => ({ id: i.id, name: i.name })),
          locations,
          qualifications: '',
          company_name: userInfo.company_name || '',
          company_website: userInfo.company_website || '',
          about_company: userInfo.about_company || '',
        });
      }
      if (
        !(
          description?.toString('markdown')?.replaceAll('**', '')?.trim()?.length > 2
        ) ||
        description
          ?.toString('markdown')
          ?.toLocaleLowerCase()
          ?.replaceAll('.js')
          ?.match(urlRE)
      ) {
        setRteError(true);
      }
    });
  };

  return (
    <div className="create-job py-3">
      <div className="card py-2 px-3 mb-3">
        <h2 className="mb-1">
          {dimensions.width < 920 && (
            <img
              src={arrowLeft}
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
              alt="icon"
            />
          )}
          Job Details
        </h2>
        <p className="mb-0">
          Please describe your position in great details to attract more candidates
        </p>
      </div>
      <div className="card py-2 px-3">
        <Form id="editJob" onSubmit={onSubmit}>
          <JobForm
            submitted={submitted}
            setSubmitted={setSubmitted}
            control={control}
            editView={true}
            firsFieldLength={description?.toString('markdown')}
            description={description}
            setDescription={setDescription}
            errors={{ ...errors, description: rteError }}
            setRteError={setRteError}
            rteError={rteError}
          />
        </Form>
      </div>
      <div
        className={`d-flex job-form-buttons align-items-center ${
          dimensions.width > 920 ? 'mt-4' : ''
        }`}
      >
        <button
          type="button"
          className="btn btn-outline btn-primary btn-rounded px-4 py-1"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          Cancel
        </button>
        <button
          form="editJob"
          type="submit"
          className="btn btn-primary btn-rounded text-white px-4 py-1 ml-auto"
        >
          Update job
        </button>
      </div>
    </div>
  );
};

EditJob.propTypes = {
  data: PropTypes.any,
  onClose: PropTypes.func,
  inDetail: PropTypes.bool,
  setLoading: PropTypes.func,
  userInfo: PropTypes.any,
};
