import React from 'react';
import Router from './router';
import './App.scss';
import Notification from './components/notification';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div>
      <Helmet>
        <title>JobTerix</title>
        <meta
          name="description"
          content="Anonymous job marketplace for tech professionals"
        />
        <meta name="keywords" content="Jobs, Remote, Developer, Frontend, Backend" />
      </Helmet>
      <Notification />
      <Router />
    </div>
  );
}

export default App;
